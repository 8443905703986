import { ChevronRightIcon, Colors } from '@notinosro/react-styleguide'
import React from 'react'
import useMessages from '../../../../hooks/useMessages'

// assets
import FileIcon from '../../../../assets/icons/FileIcon'

// types
import { SalonDetail } from '../../../../types/types'

// utils
import { PDF_FILE_TYPE } from '../../../../utils/enums'
import { isFilePDF } from '../../../../utils/helper'

// styles
import * as SC from './SalonPricelistsStyles'

type Pricelists = SalonDetail['pricelists']

// helpers
const sortPricelists = (pricelists: Pricelists) => {
	const imagePricelists: Pricelists = []
	const pdfPricelists: Pricelists = []
	pricelists?.forEach((pricelist) => {
		if (isFilePDF(pricelist.original) === PDF_FILE_TYPE) {
			pdfPricelists.push(pricelist)
		} else {
			imagePricelists.push(pricelist)
		}
	})
	const pricelistsSorted = [...imagePricelists, ...pdfPricelists]
	return pricelistsSorted
}

type Props = {
	pricelists: SalonDetail['pricelists']
	hideHeading?: boolean
}

const SalonPricelists = (props: Props) => {
	const { messages } = useMessages()
	const { pricelists, hideHeading } = props

	const pricelistsSorted = sortPricelists(pricelists || [])

	return (
		<div>
			{!hideHeading && <SC.SalonPriceListsTitle>{messages?.['Price list']}</SC.SalonPriceListsTitle>}
			<SC.SalonPriceListsFiles>
				{pricelistsSorted.map((priceList) =>
					isFilePDF(priceList.original) === PDF_FILE_TYPE ? (
						<SC.SalonPricePdfPlaceholder type='button' key={priceList.id} onClick={() => window.open(priceList.original)}>
							<SC.SalonPricePdfTitle>
								<SC.FileIcon>
									<FileIcon />
								</SC.FileIcon>
								<SC.FileName>
									<span>{priceList.fileName}</span>
									<SC.ChevronIcon>
										<ChevronRightIcon color={Colors.neutralDarker} />
									</SC.ChevronIcon>
								</SC.FileName>
								<SC.OpenPdfFile>{messages?.['To open']}</SC.OpenPdfFile>
							</SC.SalonPricePdfTitle>
						</SC.SalonPricePdfPlaceholder>
					) : (
						<SC.SalonPriceListsImage type='button' key={priceList.id} onClick={() => window.open(priceList.original)}>
							<img loading='lazy' alt={priceList.fileName} key={priceList.id} src={priceList.original} />
						</SC.SalonPriceListsImage>
					)
				)}
			</SC.SalonPriceListsFiles>
		</div>
	)
}
export default SalonPricelists
