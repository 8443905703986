import React from 'react'

const CosmeticsIcon = () => {
	return (
		<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10.5705 11.6639H10.1378V9.42704C10.1378 8.83085 9.65265 8.34574 9.05645 8.34574H8.85632V0.882839C8.85632 0.610801 8.73324 0.358527 8.51903 0.190363C8.30159 0.0199103 8.02306 -0.0396362 7.75389 0.0261877C6.39996 0.358531 5.18531 1.00498 4.14395 1.94777C3.91699 2.15323 3.78668 2.45153 3.78668 2.76638V8.34554H3.71953C3.12333 8.34554 2.63823 8.83064 2.63823 9.42684V11.6637H2.20544C1.81754 11.6637 1.50195 11.9793 1.50195 12.3673V21.228C1.50195 21.929 2.07248 22.4993 2.77348 22.4993H10.0021C10.7032 22.4993 11.2737 21.929 11.2737 21.228V12.3678C11.2737 11.9797 10.9581 11.6642 10.5704 11.6642L10.5705 11.6639ZM4.46834 2.76624C4.46834 2.64582 4.51799 2.52864 4.60131 2.4533C5.55804 1.58716 6.67319 0.993253 7.91618 0.687899C7.98029 0.672871 8.04649 0.686187 8.09805 0.726518C8.12658 0.748775 8.17433 0.798999 8.17433 0.882701V8.3456H4.4683L4.46834 2.76624ZM3.31987 9.42704C3.31987 9.20656 3.49908 9.02755 3.71937 9.02755H9.05649C9.27678 9.02755 9.45598 9.20656 9.45598 9.42704V11.6639H3.32018L3.31987 9.42704ZM10.5919 21.2282C10.5919 21.5535 10.3274 21.8177 10.0022 21.8177H2.7735C2.44819 21.8177 2.18378 21.5535 2.18378 21.2282V12.3676L2.20547 12.3457L10.5922 12.3676L10.5919 21.2282Z'
				fill='currentColor'
			/>
			<path
				d='M10.5705 11.6639H10.1378V9.42704C10.1378 8.83085 9.65265 8.34574 9.05645 8.34574H8.85632V0.882839C8.85632 0.610801 8.73324 0.358527 8.51903 0.190363C8.30159 0.0199103 8.02306 -0.0396362 7.75389 0.0261877C6.39996 0.358531 5.18531 1.00498 4.14395 1.94777C3.91699 2.15323 3.78668 2.45153 3.78668 2.76638V8.34554H3.71953C3.12333 8.34554 2.63823 8.83064 2.63823 9.42684V11.6637H2.20544C1.81754 11.6637 1.50195 11.9793 1.50195 12.3673V21.228C1.50195 21.929 2.07248 22.4993 2.77348 22.4993H10.0021C10.7032 22.4993 11.2737 21.929 11.2737 21.228V12.3678C11.2737 11.9797 10.9581 11.6642 10.5704 11.6642L10.5705 11.6639ZM4.46834 2.76624C4.46834 2.64582 4.51799 2.52864 4.60131 2.4533C5.55804 1.58716 6.67319 0.993253 7.91618 0.687899C7.98029 0.672871 8.04649 0.686187 8.09805 0.726518C8.12658 0.748775 8.17433 0.798999 8.17433 0.882701V8.3456H4.4683L4.46834 2.76624ZM3.31987 9.42704C3.31987 9.20656 3.49908 9.02755 3.71937 9.02755H9.05649C9.27678 9.02755 9.45598 9.20656 9.45598 9.42704V11.6639H3.32018L3.31987 9.42704ZM10.5919 21.2282C10.5919 21.5535 10.3274 21.8177 10.0022 21.8177H2.7735C2.44819 21.8177 2.18378 21.5535 2.18378 21.2282V12.3676L2.20547 12.3457L10.5922 12.3676L10.5919 21.2282Z'
				stroke='currentColor'
			/>
			<path
				d='M21.7509 11.6638H12.8665C12.7727 11.6638 12.6831 11.7024 12.6186 11.7707C12.5541 11.839 12.5206 11.9305 12.5259 12.0241L13.0649 21.4565C13.0983 22.0419 13.5836 22.4999 14.1696 22.4999H20.4476C21.0337 22.4999 21.5188 22.0419 21.5523 21.4565L22.0913 12.0241C22.0966 11.9305 22.0631 11.839 21.9986 11.7707C21.9345 11.7024 21.8449 11.6638 21.7511 11.6638H21.7509ZM20.8716 21.4176C20.8589 21.6419 20.6725 21.8177 20.4476 21.8177L14.1696 21.8179C13.9447 21.8179 13.7585 21.6421 13.7456 21.4178L13.227 12.3458H21.3897L20.8716 21.4176Z'
				fill='currentColor'
			/>
			<path
				d='M21.7509 11.6638H12.8665C12.7727 11.6638 12.6831 11.7024 12.6186 11.7707C12.5541 11.839 12.5206 11.9305 12.5259 12.0241L13.0649 21.4565C13.0983 22.0419 13.5836 22.4999 14.1696 22.4999H20.4476C21.0337 22.4999 21.5188 22.0419 21.5523 21.4565L22.0913 12.0241C22.0966 11.9305 22.0631 11.839 21.9986 11.7707C21.9345 11.7024 21.8449 11.6638 21.7511 11.6638H21.7509ZM20.8716 21.4176C20.8589 21.6419 20.6725 21.8177 20.4476 21.8177L14.1696 21.8179C13.9447 21.8179 13.7585 21.6421 13.7456 21.4178L13.227 12.3458H21.3897L20.8716 21.4176Z'
				stroke='currentColor'
			/>
		</svg>
	)
}

export default CosmeticsIcon
