import React from 'react'
import ClockIcon from '../../../../assets/icons/ClockIcon'
import { Price, SalonServicesResponse } from '../../../../types/types'
import { decodePrice, toHoursAndMinutes } from '../../../../utils/helper'
import * as SC from './SalonServicesCardsStyles'

type Props = {
	firstCategoryData?: NonNullable<SalonServicesResponse['groupedServicesByCategory'][0]['category']>['children']
}

const renderPrice = (currencySymbol?: string, from?: Price | null | undefined, to?: Price | null | undefined): React.ReactNode => {
	const decodedPriceFrom = decodePrice(from)
	const decodedPriceTo = decodePrice(to)

	const fromExists = decodedPriceFrom !== null && decodedPriceFrom !== undefined
	const toExists = decodedPriceTo !== null && decodedPriceTo !== undefined

	if (!fromExists && !toExists) {
		return '-'
	}

	let range

	if (decodedPriceFrom !== decodedPriceTo) {
		range = (
			<>
				{fromExists ? decodedPriceFrom : ''} - {toExists ? decodedPriceTo : ''}
			</>
		)
	} else {
		range = decodedPriceFrom || decodedPriceTo
	}

	return (
		<>
			{range} {currencySymbol}
		</>
	)
}

const renderDuration = (from?: number, to?: number): React.ReactNode => {
	const fromExists = from !== null && from !== undefined
	const toExists = to !== null && to !== undefined

	if (!fromExists && !toExists) {
		return '-'
	}

	if (from !== to) {
		return (
			<>
				{fromExists ? toHoursAndMinutes(from) : ''} - {toExists ? toHoursAndMinutes(to) : ''}
			</>
		)
	}

	return toHoursAndMinutes(from || to)
}

const SalonServicesCards = ({ firstCategoryData = [] }: Props) => {
	return firstCategoryData.map((firstChildCatObj) => (
		<div key={firstChildCatObj?.category?.id}>
			<SC.FirstChildCategoryTitle>{firstChildCatObj?.category?.name}</SC.FirstChildCategoryTitle>
			<SC.FirstChildCategoryContent>
				{firstChildCatObj?.category?.children?.map((secondChildCatObj) => {
					const { durationFrom, durationTo, priceFrom, priceTo } = secondChildCatObj.service.rangePriceAndDurationData
					const currencySymbol = priceTo?.currencySymbol

					return (
						<SC.CategoryItem key={secondChildCatObj.category.id}>
							<SC.SecondChildCategoryTitle>{secondChildCatObj.category.name}</SC.SecondChildCategoryTitle>
							<SC.PriceAndDurationContainer>
								<SC.SecondCategoryPrice>{renderPrice(currencySymbol, priceFrom, priceTo)}</SC.SecondCategoryPrice>
								<SC.SecondCategoryDuration $hidden={!secondChildCatObj?.service?.rangePriceAndDurationData?.durationFrom}>
									<SC.DurationIcon>
										<ClockIcon />
									</SC.DurationIcon>
									{renderDuration(durationFrom, durationTo)}
								</SC.SecondCategoryDuration>
							</SC.PriceAndDurationContainer>
						</SC.CategoryItem>
					)
				})}
			</SC.FirstChildCategoryContent>
		</div>
	))
}

export default SalonServicesCards
