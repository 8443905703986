import React from 'react'

const GlobeIcon = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.99996 18.3332C14.6023 18.3332 18.3333 14.6022 18.3333 9.99984C18.3333 5.39746 14.6023 1.6665 9.99996 1.6665C5.39759 1.6665 1.66663 5.39746 1.66663 9.99984C1.66663 14.6022 5.39759 18.3332 9.99996 18.3332Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M1.66663 10H18.3333' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path
				d='M9.99996 1.6665C12.0844 3.94846 13.2689 6.90987 13.3333 9.99984C13.2689 13.0898 12.0844 16.0512 9.99996 18.3332C7.91556 16.0512 6.731 13.0898 6.66663 9.99984C6.731 6.90987 7.91556 3.94846 9.99996 1.6665V1.6665Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default GlobeIcon
