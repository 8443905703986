/* eslint-disable global-require */
import React from 'react'
import { Head } from '@notinosro/react-toolkit/exports/Head'
import { useIntl } from 'react-intl'
import { SalonDetail } from '../../../../types/types'
import { shopsConfig } from '../../../../appDefaults'
import Paths from '../../../../routes/paths'
import useMessages from '../../../../hooks/useMessages'
import { formatUrlSlug, truncateString } from '../../../../utils/helper'

type Props = {
	salonSlug: string
	salon: SalonDetail
	salonServices: string[]
	assetsPath: string
}

const SalonHead = (props: Props) => {
	const { salonSlug, salon, salonServices, assetsPath } = props
	const { locale } = useIntl()
	const { messages } = useMessages()

	const editedSalonSlug = formatUrlSlug(salonSlug)

	const currentShop = shopsConfig.find((shop) => shop.locale === locale)
	const title = `${salon.name} ${salon.address?.city ? `${salon.address?.city} |` : '|'} ${messages?.['Salon detail page SEO title']}`
	const salonImage = salon.images[0]?.resizedImages.small || `${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder-small.png')}`

	// NOTE: defining children as array and using .flat() on it otherwise <Head> doesn't work properly
	// SIDENOTE: each item has to have unique 'key' because <Head/> iterates over the array
	const children = [
		// charset
		<meta key='charset' charSet='utf-8' />,

		// title
		<title key='title'>{title}</title>,

		// description
		<meta key='description' name='description' content={truncateString(salon.aboutUsFirst)} />,

		// keywords
		salonServices?.length > 0 && <meta key='keywords' name='keywords' content={salonServices?.join(', ')} />,

		// canonical and alternate
		currentShop && (
			<link key='canonical' rel='canonical' href={`${currentShop?.url}${Paths[locale as keyof typeof Paths]['/salons/services']}${editedSalonSlug}`} />
		),
		shopsConfig.map((shop, id) => (
			<link
				key={`alternate-${id}`}
				rel='alternate'
				href={`${shop?.url}${Paths[locale as keyof typeof Paths]['/salons/services']}${editedSalonSlug}`}
				hrefLang={shop.lang}
			/>
		)),
		<link
			key='x-default'
			rel='alternate'
			href={`${shopsConfig[0].url}${Paths[locale as keyof typeof Paths]['/salons/services']}${editedSalonSlug}`}
			hrefLang='x-default'
		/>,

		// OG tags (social networks)
		<meta key='og:title' property='og:title' content={title} />,
		<meta key='og:description' property='og:description' content={truncateString(salon.aboutUsFirst)} />,
		<meta key='og:url' property='og:url' content={`${currentShop?.url}${Paths[locale as keyof typeof Paths]['/salons']}${editedSalonSlug}`} />,
		<meta key='og:image' property='og:image' content={salonImage} />
	]
		.flat()
		.filter(Boolean)

	return <Head>{children}</Head>
}

export default SalonHead
