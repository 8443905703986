import { IDropdownOption } from '@notinosro/react-styleguide'
import { useIntl } from 'react-intl'
import { SalonsPageQueryType } from '../types/types'
import { SORTING_OPTION } from '../utils/enums'

const useSortingOptions = (query: SalonsPageQueryType): [IDropdownOption, IDropdownOption[]] => {
	const { messages } = useIntl()

	const sortingOptions: IDropdownOption[] = [
		{ id: SORTING_OPTION.RECOMMENDED, label: messages.Recommended as string },
		{ id: SORTING_OPTION.CLOSEST, label: messages?.['The closest'] as string },
		{ id: SORTING_OPTION.BEST_RATED, label: messages?.['Best rated'] as string },
		{ id: SORTING_OPTION.CHEAPEST, label: messages?.['The cheapest'] as string },
		{ id: SORTING_OPTION.MOST_EXPENSIVE, label: messages?.['The most expensive'] as string }
	]

	const currentSortingOption = sortingOptions.find((opt) => opt.id === query.orderBy) || sortingOptions[0]

	return [currentSortingOption, sortingOptions]
}

export default useSortingOptions
