import styled, { createGlobalStyle, css, keyframes } from 'styled-components'

import { theme } from '@notinosro/react-styleguide'
import { BREAKPOINTS, SALONS_PAGE_MOBILE_BREAKPOINT } from './constants'

const cssReset = css`
	letter-spacing: 0;
	font-family: ${theme.fonts.primary};
	backface-visibility: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
`

export const AppContainer = styled.div`
	${cssReset};
	font-size: ${theme.fontBaseSize}px;
	font-weight: 300;

	* {
		box-sizing: border-box;
	}

	img {
		width: 100%;
	}
`

const dialogMoveIn = keyframes`
	0% {
		opacity: 0;
		transform: translateY(-200px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
`

const dialogFadeIn = keyframes`
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
	`

const GlobalStyles = createGlobalStyle`
	*, *:before, *:after {
		margin: 0;
		padding: 0;
	}

	button.ril-next-button.ril__navButtons.ril__navButtonNext {
		background: rgba(0, 0, 0, 0.2)
		url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE4NzhfMTA2MDciIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIxOCIgaGVpZ2h0PSIyMCI+CjxwYXRoIGQ9Ik04Ljk1ODcxIDAuOTg0OTg1TDcuOTAxMjEgMi4wNDYyNEwxNS44NTUgOS45OTk5OUw3LjkwMTIxIDE3Ljk1MzdMOC45NTg3MSAxOS4wMTVMMTcuOTc3NSA5Ljk5OTk5TDguOTU4NzEgMC45ODQ5ODVaTTEwLjEwMjUgOS45OTk5OUwxLjA4MzcxIDAuOTg0OTg1TDAuMDIyNDYwOSAyLjA0NjI0TDcuOTc5OTYgOS45OTk5OUwwLjAyMjQ2MDkgMTcuOTUzN0wxLjA4MzcxIDE5LjAxNUwxMC4xMDI1IDkuOTk5OTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfMTg3OF8xMDYwNykiPgo8cGF0aCBkPSJNMjEgLTJILTNWMjJIMjFWLTJaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+Cjwvc3ZnPg==')
		no-repeat center;
	}

	button.ril-prev-button.ril__navButtons.ril__navButtonPrev {
		background: rgba(0, 0, 0, 0.2)
		url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE4NzhfMTA1OTkiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjMiIHk9IjIiIHdpZHRoPSIxOCIgaGVpZ2h0PSIyMCI+CjxwYXRoIGQ9Ik0xMy4xMDI1IDQuMDQ2MjRMMTIuMDQxMiAyLjk4NDk5TDMuMDIyNDYgMTJMMTIuMDQxMiAyMS4wMTVMMTMuMTAyNSAxOS45NTM3TDUuMTQ0OTYgMTJMMTMuMTAyNSA0LjA0NjI0Wk0yMC45Nzc1IDQuMDQ2MjRMMTkuOTE2MiAyLjk4NDk5TDEwLjkwMTIgMTJMMTkuOTE2MiAyMS4wMTVMMjAuOTc3NSAxOS45NTM3TDEzLjAyIDEyTDIwLjk3NzUgNC4wNDYyNFoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF8xODc4XzEwNTk5KSI+CjxwYXRoIGQ9Ik0yNCAwSDBWMjRIMjRWMFoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9zdmc+')
		no-repeat center;
	}

	button.ril-close.ril-toolbar__item__child.ril__toolbarItemChild.ril__builtinButton.ril__closeButton {
		background: rgba(0, 0, 0, 0.2)
		url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzE4NzhfMTA2NDYiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+CjxwYXRoIGQ9Ik0xOCAxLjQ2NTI5TDE2LjUzODQgMEw5IDcuNTM0NzFMMS40NjUyOSAwTDAgMS40NjUyOUw3LjUzODM3IDlMMCAxNi41MzQ3TDEuNDY1MjkgMThMOSAxMC40NjUzTDE2LjUzODQgMThMMTggMTYuNTM0N0wxMC40NjUzIDlMMTggMS40NjUyOVoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF8xODc4XzEwNjQ2KSI+CjxwYXRoIGQ9Ik0xOCAxLjQ2NTI5TDE2LjUzODQgMEw5IDcuNTM0NzFMMS40NjUyOSAwTDAgMS40NjUyOUw3LjUzODM3IDlMMCAxNi41MzQ3TDEuNDY1MjkgMThMOSAxMC40NjUzTDE2LjUzODQgMThMMTggMTYuNTM0N0wxMC40NjUzIDlMMTggMS40NjUyOVoiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0yMSAtM0gtM1YyMUgyMVYtM1oiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9zdmc+')
		no-repeat center;
	}

	.ReactModalPortal .ril-toolbar__item::before {
		background-image: none !important; // reseting global notino style
	}

	// Drawer styles
	.rc-drawer,
	.rc-drawer *, .rc-drawer *::before, .rc-drawer *::after,
	.rc-drawer::after,
	.rc-drawer::before {
		${cssReset};
		box-sizing: border-box;
	}

	.rc-drawer {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: fixed;
		z-index: 1050;
		pointer-events: none;
	}
	.rc-drawer-inline {
		position: absolute;
	}
	.rc-drawer-mask {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		z-index: 1050;
		background: rgba(0, 0, 0, 0.5);
		pointer-events: auto;
	}
	.rc-drawer-content-wrapper {
		position: absolute;
		z-index: 1050;
		overflow: hidden;
		transition: transform 0.3s;

		@media (max-width: ${BREAKPOINTS.sm}) {
			width: 100% !important;
		}

	}
	.rc-drawer-content-wrapper-hidden {
		display: none;
	}
	.rc-drawer-left .rc-drawer-content-wrapper {
		top: 0;
		bottom: 0;
		left: 0;
	}
	.rc-drawer-right .rc-drawer-content-wrapper {
		top: 0;
		right: 0;
		bottom: 0;
	}
	.rc-drawer-content {
		width: 100%;
		height: 100%;
		overflow: auto;
		background: #fff;
		pointer-events: auto;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;

		@media (max-width: ${BREAKPOINTS.sm}) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	// dialog styles
	.ReactModalPortal,
	.ReactModalPortal *, .ReactModalPortal *::before, .ReactModalPortal *::after,
	.ReactModalPortal::after,
	.ReactModalPortal::before {
		${cssReset};
		box-sizing: border-box;
	}

	.ReactModalPortal {
		z-index: 9999;

		.noti-dialog-overlay {
			position: fixed;
			inset: 0;
			background: rgba(0,0,0,0.4);
			padding: 80px 24px;
			opacity: 0;

			@media (max-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
				padding: 16px;
			}
		}

		.noti-dialog-content {
			width: 100%;
			max-width: 500px;
			height: auto;
			margin: auto;
			outline: none;
			background: #fff;
			opacity: 0;
			transform: translateY(-200px);

		}
	}

	body.ReactModal__Body--open {
		overflow: hidden;

		.ReactModalPortal {
			position: fixed;
			z-index: 9999;

			.noti-dialog-overlay {
				animation: ${dialogFadeIn} 0.3s ease forwards;
			}

			.noti-dialog-content {
				animation: ${dialogMoveIn} 0.3s ease forwards;
			}
		}
	}
`

export default GlobalStyles
