import React from 'react'

const MapIcon = () => {
	return (
		<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.583252 3.50008V12.8334L4.66659 10.5001L9.33325 12.8334L13.4166 10.5001V1.16675L9.33325 3.50008L4.66659 1.16675L0.583252 3.50008Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M9.33325 3.5V12.8333' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M4.66675 1.16675V10.5001' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	)
}

export default MapIcon
