import { theme } from '@notinosro/react-styleguide'
import styled, { css } from 'styled-components'
import { resetButtonStyles } from '../../../../styles/helpers'

export const ServicesTabsWrapper = styled.div`
	margin-bottom: 60px;
`

export const TabsContainer = styled.div`
	position: relative;
	isolation: 'isolate';
`

export const Tabs = styled.ul`
	display: flex;
	overflow-x: auto;
	scroll-behavior: smooth;
	z-index: 0;

	/* hide scrollbar */
	&::-webkit-scrollbar {
		display: none; // for Chrome, Safari and Opera
	}
	& {
		-ms-overflow-style: none; // for IE and Edge
		scrollbar-width: none; // for for Firefox
	}
`

export const Tab = styled.li`
	list-style: none;
	padding: 0;
	margin: 0;

	&::before {
		display: none; // override Notino main app styles
	}
`

export const TabButton = styled.div<{ $isActive?: boolean }>`
	cursor: pointer;
	padding: 8px 16px;
	white-space: nowrap;
	color: ${theme.palette.neutralDark};
	border-bottom: 2px solid ${theme.palette.neutralLighter};

	&[aria-selected='true'] {
		position: relative;
		color: ${theme.palette.basic};
		border-color: ${theme.palette.basic};
		font-weight: 500;
	}
`

export const TabsContent = styled.div`
	position: relative;
`

export const TabContent = styled.div<{ $isActive?: boolean }>`
	position: relative;
	left: 0;
	top: 0;

	${({ $isActive }) =>
		!$isActive &&
		css`
			width: 0;
			height: 0;
			overflow: hidden;
		`}
`

export const ScrollButton = styled.button`
	${resetButtonStyles};

	border: transparent;
	position: absolute;
	top: 0;
	width: 40px;
	height: 40px;
	padding: 5px 8px 10px 8px;
	background-color: white;

	&::after {
		content: '';
		display: block;
		width: 20px;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
	}
`

export const ScrollLeftButton = styled(ScrollButton)`
	left: 0;

	&::after {
		left: 100%;
		background: linear-gradient(to right, white, transparent);
	}
`

export const ScrollRightButton = styled(ScrollButton)`
	right: 0;

	&::after {
		right: 100%;
		background: linear-gradient(to left, white, transparent);
	}
`
