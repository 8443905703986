import React from 'react'
import { useIntl } from 'react-intl'
import { JsonLd } from 'react-schemaorg'
import { BreadcrumbList, WithContext } from 'schema-dts'
import { shopsConfig } from '../../../../appDefaults'

type Props = {
	breadcrumbItems: {
		link: string
		name: string
	}[]
}

const SalonsSeoSchema = (props: Props) => {
	const { breadcrumbItems } = props
	const { locale } = useIntl()

	const currentShop = shopsConfig.find((shop) => shop.locale === locale)

	// ---------------
	// breadcrumb list
	// ---------------

	const breadcrumbList: WithContext<BreadcrumbList> = {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',

		// example:
		// "itemListElement": [{
		//   "@type": "ListItem",
		//   "position": 1,
		//   "name": "Books",
		//   "item": "https://example.com/books"
		// },{
		//   "@type": "ListItem",
		//   "position": 2,
		//   "name": "Science Fiction",
		//   "item": "https://example.com/books/sciencefiction"
		// },{
		//   "@type": "ListItem",
		//   "position": 3,
		//   "name": "Award Winners"
		// }]

		itemListElement: breadcrumbItems.map((item, index) => ({
			'@type': 'ListItem',
			position: index + 1,
			name: item.name,
			...(item.link && { item: `${currentShop?.url}${item.link}` })
		}))
	}

	return <JsonLd<BreadcrumbList> item={breadcrumbList} />
}
export default SalonsSeoSchema
