import React from 'react'

const EmptyStateIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='90' height='90' fill='none' viewBox='0 0 90 90'>
			<path
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
				d='M22.5 78.75c6.213 0 11.25-5.037 11.25-11.25S28.713 56.25 22.5 56.25 11.25 61.287 11.25 67.5s5.037 11.25 11.25 11.25zM22.5 33.75c6.213 0 11.25-5.037 11.25-11.25S28.713 11.25 22.5 11.25 11.25 16.287 11.25 22.5s5.037 11.25 11.25 11.25z'
			/>
			<path stroke='currentColor' strokeLinecap='square' strokeLinejoin='round' strokeWidth='1.5' d='M75 15L30.45 59.55M54.263 54.3L75 75' />
			<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M30.45 30.45L45 45' />
		</svg>
	)
}

export default EmptyStateIcon
