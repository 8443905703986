import React from 'react'
import { useIntl } from 'react-intl'
import { JsonLd } from 'react-schemaorg'
import { BreadcrumbList, DayOfWeek, LocalBusiness, OpeningHoursSpecification, WithContext } from 'schema-dts'
import { shopsConfig } from '../../../../appDefaults'
import Paths from '../../../../routes/paths'
import { ConfigResponse, SalonDetail } from '../../../../types/types'
import { PAYMENT_METHOD } from '../../../../utils/enums'
import { formatUrlSlug, getPhoneNumber } from '../../../../utils/helper'

type Props = {
	salon: SalonDetail
	breadcrumbItems: {
		link: string
		name: string
	}[]
	countriesData: ConfigResponse['rolloutCountries']
	assetsPath: string
	salonSlug: string
}

const SalonSeoSchema = (props: Props) => {
	const { salon, breadcrumbItems, countriesData, assetsPath, salonSlug } = props
	const { messages, locale } = useIntl()

	const currentShop = shopsConfig.find((shop) => shop.locale === locale)

	// --------------
	// local business
	// --------------

	const localBusiness: WithContext<LocalBusiness> = {
		'@context': 'https://schema.org',
		'@type': 'LocalBusiness'
	}

	// name
	if (salon.name) localBusiness.name = salon.name

	// PostalAddress
	if (salon.address)
		localBusiness.address = {
			'@type': 'PostalAddress',
			streetAddress: salon.address.street,
			addressLocality: salon.address.city, // NOTE: there is "New York" in Google documentation
			addressRegion: '', // NOTE: there is "NY" in Google documentation
			postalCode: salon.address?.zipCode,
			addressCountry: salon.address?.countryCode
		}

	// OpeningHoursSpecification
	if (salon.openingHoursData) {
		const openingHoursSpecification: OpeningHoursSpecification[] = []
		salon.openingHoursData?.openingHours?.forEach((day) => {
			const dayCapitalized = day.day[0] + day.day.substring(1).toLowerCase()
			const baseSpecification = {
				'@type': 'OpeningHoursSpecification' as const,
				dayOfWeek: dayCapitalized as DayOfWeek
			}
			if (day.state === 'CUSTOM_ORDER') {
				const specification = {
					...baseSpecification,
					description: (messages?.['On request'] || 'On request') as string
				}
				openingHoursSpecification.push(specification)
			} else {
				day.timeRanges.forEach((range) => {
					const specification = {
						...baseSpecification,
						opens: range.timeFrom,
						closes: range.timeTo
					}
					openingHoursSpecification.push(specification)
				})
			}
		})
		localBusiness.openingHoursSpecification = openingHoursSpecification
	}

	// AggregateRating
	if (salon.ratingsData?.rating && salon.ratingsData?.ratesCount && salon.ratingsData?.ratesCount > 0)
		localBusiness.aggregateRating = {
			'@type': 'AggregateRating',
			ratingValue: salon.ratingsData.rating,
			reviewCount: salon.ratingsData.ratesCount
		}

	// GeoCoordinates
	if (salon.address?.latitude && salon.address?.longitude)
		localBusiness.geo = {
			'@type': 'GeoCoordinates',
			latitude: salon.address.latitude,
			longitude: salon.address.longitude
		}

	// paymentAccepted
	const paymentMethods: string[] = []
	if (salon.payByCash) paymentMethods.push(PAYMENT_METHOD.CASH)
	if (salon.payByCard) paymentMethods.push(PAYMENT_METHOD.CARD)
	if (salon.otherPaymentMethods) paymentMethods.push(salon.otherPaymentMethods)
	const paymentAccepted = paymentMethods.join(',')
	if (paymentAccepted) localBusiness.paymentAccepted = paymentAccepted

	// logo
	if (salon.logo) localBusiness.logo = salon.logo.original

	// telephone
	if (salon.phones && salon.phones.length > 0) localBusiness.telephone = getPhoneNumber(salon.phones[0], countriesData)

	// url
	if (currentShop?.url) localBusiness.url = `${currentShop?.url}${Paths[locale as keyof typeof Paths]['/salons']}${formatUrlSlug(salonSlug)}`

	// image
	if (salon.images && salon.images.length > 0) {
		localBusiness.image = [
			salon.images[0].resizedImages.large,
			salon.images[0].resizedImages.medium,
			salon.images[0].resizedImages.small,
			salon.images[0].resizedImages.thumbnail
		]
	} else {
		// eslint-disable-next-line global-require
		localBusiness.image = [`${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder.png')}`]
	}

	// ---------------
	// breadcrumb list
	// ---------------

	const breadcrumbList: WithContext<BreadcrumbList> = {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',

		// example:
		// "itemListElement": [{
		//   "@type": "ListItem",
		//   "position": 1,
		//   "name": "Books",
		//   "item": "https://example.com/books"
		// },{
		//   "@type": "ListItem",
		//   "position": 2,
		//   "name": "Science Fiction",
		//   "item": "https://example.com/books/sciencefiction"
		// },{
		//   "@type": "ListItem",
		//   "position": 3,
		//   "name": "Award Winners"
		// }]

		itemListElement: breadcrumbItems.map((item, index) => ({
			'@type': 'ListItem',
			position: index + 1,
			name: item.name,
			...(item.link && { item: `${currentShop?.url}${item.link}` })
		}))
	}

	return (
		<>
			<JsonLd<BreadcrumbList> item={breadcrumbList} />
			<JsonLd<LocalBusiness> item={localBusiness} />
		</>
	)
}
export default SalonSeoSchema
