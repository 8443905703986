import React from 'react'

// styles
import * as SC from './CheckboxGroupFormFieldStyles'

// types
import { CheckboxGroupFormFieldProps, CheckboxGroupValueType, CheckboxItemProps, isCheckboxGroupGroupedOption } from './types'

const CheckboxItem = <V extends CheckboxGroupValueType>(props: CheckboxItemProps<V>) => {
	const { option, handleChangeValue, checked, disabled } = props

	return (
		<SC.Checkbox onChange={(e) => handleChangeValue(e, option.value)} checked={checked} disabled={disabled}>
			<SC.CheckboxLabel>
				{option.icon && <SC.CheckboxIcon>{option.icon}</SC.CheckboxIcon>}
				<SC.CheckboxLabelTitle $checked={checked}>{option.label}</SC.CheckboxLabelTitle>
				{option.description !== undefined ? <SC.CheckboxLabelDescription>{option.description}</SC.CheckboxLabelDescription> : null}
			</SC.CheckboxLabel>
		</SC.Checkbox>
	)
}

// component
const CheckboxGroupFormField = <V extends string | number>(props: CheckboxGroupFormFieldProps<V>) => {
	const {
		options,
		disabled,
		input: { value, onChange },
		onAfterChange
	} = props

	const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>, option: V) => {
		const { checked } = e.target
		let newValues: V[]
		if (checked) {
			newValues = [...value, option]
		} else {
			newValues = value.filter((o) => o !== option)
		}
		onChange(newValues)
		if (onAfterChange) {
			onAfterChange(newValues)
		}
	}

	return (
		<SC.CheckboxListWrapper>
			{options.map((option) => {
				if (isCheckboxGroupGroupedOption(option)) {
					return (
						<React.Fragment key={option.key}>
							<SC.CheckboxGroupHeader>{option.label}</SC.CheckboxGroupHeader>

							{option.children.map((nestedOption) => {
								const optionChecked = value.includes(nestedOption.value)
								const optionDisabled = disabled || nestedOption.disabled

								return (
									<CheckboxItem
										key={nestedOption.value}
										option={nestedOption}
										handleChangeValue={handleChangeValue}
										checked={optionChecked}
										disabled={optionDisabled}
									/>
								)
							})}
						</React.Fragment>
					)
				}

				const optionChecked = value.includes(option.value)
				const optionDisabled = disabled || option.disabled
				return (
					<SC.CheckboxListWrapper key={option.value}>
						<CheckboxItem option={option} handleChangeValue={handleChangeValue} checked={optionChecked} disabled={optionDisabled} />
					</SC.CheckboxListWrapper>
				)
			})}
		</SC.CheckboxListWrapper>
	)
}

export default CheckboxGroupFormField
