import styled from 'styled-components'
import { NotinoBodyLarge, NotinoBodySmall } from '../../styles/helpers'

export const Wrapper = styled.div``

export const Label = styled.span`
	${NotinoBodyLarge};
	display: inline-block;
	margin-bottom: 16px;
`

export const RadioButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export const RadioButtonLabel = styled.label<{ $disabled?: boolean }>`
	position: relative;
	cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`

export const CustomLabel = styled.div`
	background: #f5f5f5;
	min-height: 48px;
	padding: 10px 15px;
	border: 1px solid transparent;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	transition: all 0.3s ease;
	outline: 2px solid transparent;
`

export const LabelTitle = styled.span`
	color: #000;
	${NotinoBodyLarge};
`

export const LabelDescription = styled.span`
	color: #404040;
	${NotinoBodySmall};
`

export const RadioButton = styled.input`
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	opacity: 0;

	&:checked ~ ${CustomLabel} {
		background-color: #fff;
		border-color: #000;
	}

	&:not(:disabled) {
		&:focus-visible ~ ${CustomLabel} {
			outline-color: #000;
		}
	}

	&:disabled {
		&:checked {
			& ~ ${CustomLabel} {
				border-color: #9f9f9f;
			}
		}

		& ~ ${CustomLabel} {
			${LabelTitle},
			${LabelDescription} {
				color: #9f9f9f;
			}
		}
	}
`
