import { WrappedFieldsProps } from '../../types/types'

export type CheckboxGroupValueType = string | number

export type CheckboxGroupGroupedOption<V = CheckboxGroupValueType> = {
	key: string | number
	label: React.ReactNode
	children: CheckboxGroupOption<V>[]
}

export type CheckboxGroupOption<V = CheckboxGroupValueType> = {
	icon?: React.ReactNode
	value: V
	label?: React.ReactNode
	description?: React.ReactNode
	disabled?: boolean
}

export type CheckboxGroupFormFieldProps<V = CheckboxGroupValueType> = {
	options: CheckboxGroupOption<V>[] | CheckboxGroupGroupedOption<V>[]
	label?: string
	disabled?: boolean
	onAfterChange?: (value: V[]) => void
} & WrappedFieldsProps<V[]>

export type CheckboxItemProps<V = CheckboxGroupValueType> = {
	option: CheckboxGroupOption<V>
	handleChangeValue: (e: React.ChangeEvent<HTMLInputElement>, option: V) => void
	checked: boolean
	disabled?: boolean
}

export const isCheckboxGroupOption = <V = CheckboxGroupValueType>(item: any): item is CheckboxGroupOption<V> => {
	return 'value' in item
}

export const isCheckboxGroupGroupedOption = <V = CheckboxGroupValueType>(item: any): item is CheckboxGroupGroupedOption<V> => {
	return 'children' in item
}
