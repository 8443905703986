import React from 'react'
import * as SC from './TeamMobileStyles'
import { SalonDetail } from '../../../../types/types'

type Props = {
	salonData?: SalonDetail
}

const TeamMobile = ({ salonData }: Props) => {
	return (
		<SC.Container>
			{salonData?.employees?.map((employee) => (
				<SC.Employee key={employee?.id}>
					<img loading='lazy' alt={employee?.firstName} src={employee?.image?.resizedImages?.thumbnail} />
					{employee?.firstName}
				</SC.Employee>
			))}
		</SC.Container>
	)
}

export default TeamMobile
