import React, { useState } from 'react'
import Collapse from 'rc-collapse'
import useMessages from '../../../../hooks/useMessages'

// types
import { SalonServicesResponse } from '../../../../types/types'

// assets
import ChevronUpIcon from '../../../../assets/icons/ChevronUpIcon'
import ChevronDownIcon from '../../../../assets/icons/ChevronDownIcon'
import ScissorsIcon from '../../../../assets/icons/ScissorsIcon'

// styles
import * as SC from './SalonServicesCollapseStyles'

// utils
import SalonServicesCards from '../SalonServicesCards/SalonServicesCards'

type Props = {
	services?: SalonServicesResponse
}

const SalonServicesCollapse = (props: Props) => {
	const { services } = props
	const [expandedRowId, setExpandedRowId] = useState<React.Key[]>([])

	const { messages } = useMessages()

	return (
		<SC.ServicesComponentContainer>
			<SC.ServicesTitle>
				<ScissorsIcon />
				{messages?.Services}
			</SC.ServicesTitle>

			<Collapse
				onChange={(key) => {
					setExpandedRowId(key as React.Key[])
				}}
				activeKey={expandedRowId}
				items={services?.groupedServicesByCategory?.map((catObj, id) => ({
					label: catObj?.category?.name,
					key: `${id}`,
					extra: expandedRowId?.includes(`${id}`) ? <ChevronUpIcon /> : <ChevronDownIcon />,
					forceRender: true,
					children: <SalonServicesCards firstCategoryData={catObj?.category?.children} />
				}))}
			/>
		</SC.ServicesComponentContainer>
	)
}

export default SalonServicesCollapse
