/* eslint-disable import/prefer-default-export */

declare global {
	interface Window {
		dataLayer: Array<Object>
	}
}

interface IDataLayerObject<T> {
	[key: string | number]: T
}

export const pushToDataLayer = (object: IDataLayerObject<string | object | boolean | undefined | null | number>) => {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push(object)
}
