import styled from 'styled-components'
import { theme } from '@notinosro/react-styleguide'
import { SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'
import { NotinoBodyMedium, NotinoLabelRegular, NotinoTitleDisplay } from '../../../../styles/helpers'

export const SalonReviewsContainer = styled.div`
	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 60px;
	}
`

export const Heading = styled.div`
	display: none;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoTitleDisplay};
		display: block;
		margin-bottom: 20px;
	}
`

export const AverageRatingContainer = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 8px;

	margin-bottom: 8px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-bottom: 20px;
	}
`

export const AverageRatingValue = styled.span`
	${NotinoTitleDisplay};
`

export const AverageRatingStars = styled.div`
	margin-bottom: 8px; // for alignment with value
`

export const RatingsContainer = styled.div`
	max-width: 500px;
	padding-bottom: 40px;
	border-bottom: 1px solid ${theme.palette.neutralLighter};
`

export const ReviewsContainer = styled.div`
	margin-top: 24px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 10px;
	}
`

export const Review = styled.div`
	&:not(:last-child) {
		margin-bottom: 24px;

		@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
			margin-bottom: 16px;
		}
	}
`

export const ReviewMessage = styled.div`
	${NotinoBodyMedium};
	margin-bottom: 8px;
`

export const ReviewInfo = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

export const ReviewerName = styled.div`
	${NotinoLabelRegular};
`

export const ReviewDate = styled.div`
	${NotinoBodyMedium};
`

export const ReviewsButtonContainer = styled.div`
	display: flex;
	justify-content: center;

	margin-top: 24px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 30px;
	}
`
