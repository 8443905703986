import React from 'react'

const ChevronDownIcon = () => {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<mask id='mask0_14519_13143' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='3' y='7' width='18' height='11'>
				<path d='M4.46529 7L3 8.46529L12 17.4653L21 8.46529L19.5347 7L12 14.5347L4.46529 7Z' fill='white' />
			</mask>
			<g mask='url(#mask0_14519_13143)'>
				<path d='M4.46529 7L3 8.46529L12 17.4653L21 8.46529L19.5347 7L12 14.5347L4.46529 7Z' fill='black' />
			</g>
		</svg>
	)
}

export default ChevronDownIcon
