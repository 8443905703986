import React from 'react'
import { SliderProps } from 'rc-slider'

// styles
import * as SC from './SliderStyles'

type Props = SliderProps & {}

const Slider = (props: Props) => {
	return <SC.Slider {...props} />
}

export default React.memo(Slider)
