import { theme } from '@notinosro/react-styleguide'
import styled from 'styled-components'
import { NotinoBodyLarge, NotinoBodySmall, NotinoLabelRegular400 } from '../../../../styles/helpers'
import { SALONS_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

export const PaymentMethod = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
`

export const PaymentMethodLabel = styled.span`
	${NotinoLabelRegular400};

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoBodyLarge}
	}
`

export const PaymentsInfoContainer = styled.div`
	${NotinoBodySmall};
	color: ${theme.palette.neutralDark};
	margin-top: 4px;
`
