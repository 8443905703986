import React from 'react'
import { useParams } from 'react-router-dom'
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

import SalonsPage from '../pages/SalonsPage/SalonsPage'
import { ContextProps, SalonsPageQueryType } from '../types/types'
import { SORTING_OPTION } from '../utils/enums'
import { getCurrentPage, getValidSalonsPageQuery } from '../utils/helper'

type SalonsRouteParams = {
	categorySlug: string
}

const SalonsRoute: React.FC<React.PropsWithChildren<ContextProps>> = (props) => {
	const { categorySlug } = useParams<SalonsRouteParams>()

	// NOTE: when adding new parameter, also add it to SalonsPageQueryType and SalonsPageSetQueryType in interfaces
	const [query, setQuery] = useQueryParams({
		page: withDefault(NumberParam, 1),
		openingHoursStatus: StringParam,
		latMy: NumberParam,
		lonMy: NumberParam,
		googlePlaceID: StringParam,
		orderBy: withDefault(StringParam, SORTING_OPTION.RECOMMENDED),
		categoryIDs: withDefault(ArrayParam, []),
		exactRating: withDefault(NumericArrayParam, []),
		languageIDs: withDefault(ArrayParam, []),
		cosmeticIDs: withDefault(ArrayParam, []),
		hasAvailableReservationSystem: BooleanParam,
		serviceTotalPriceFrom: NumberParam,
		serviceTotalPriceTo: NumberParam,
		avResTimeSlotDate: StringParam,
		avResTimeSlotDateFrom: StringParam,
		avResTimeSlotDateTo: StringParam,
		isMapView: BooleanParam
	})
	const currentPage = getCurrentPage(query.page)
	const validQuery = getValidSalonsPageQuery(query as SalonsPageQueryType)

	// TODO: check if we can get rid of ts-ignore
	// eslint-disable-next-line
	// @ts-ignore
	return <SalonsPage categorySlug={categorySlug} page={currentPage} query={validQuery} setQuery={setQuery} {...props} />
}

export default SalonsRoute
