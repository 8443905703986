import React, { useEffect, useMemo, useState } from 'react'

// components
import Slider from '../../atoms/Slider/Slider'

// utils
import { formatNumber, parseTextValueToNumberValue } from '../../utils/helper'

// styles
import * as SC from './PriceRangeFormFieldStyles'

// types
import { PriceRangeFormFieldProps, PriceRangeFormFieldValueType } from './types'

// component
const PriceRangeFormField = (props: PriceRangeFormFieldProps) => {
	const {
		input: { value, onChange },
		currencySymbol,
		priceStep = 1,
		onAfterChange
	} = props

	// default value
	const { priceFromDefaultValue, priceToDefaultValue } = props
	const priceSliderDefaultValue = useMemo(
		() => (priceFromDefaultValue !== undefined && priceToDefaultValue !== undefined ? [priceFromDefaultValue, priceToDefaultValue] : undefined),
		[priceFromDefaultValue, priceToDefaultValue]
	)

	// submitted value
	const priceFromValue = value.priceFrom ?? priceFromDefaultValue
	const priceToValue = value.priceTo ?? priceToDefaultValue
	const priceSliderValue = useMemo(
		() => (value.priceFrom !== undefined && value.priceTo !== undefined ? [value.priceFrom, value.priceTo] : priceSliderDefaultValue),
		[value.priceFrom, value.priceTo, priceSliderDefaultValue]
	)

	const [serviceTotalPriceValues, setServiceTotalPriceValues] = useState<{
		priceFrom: number | undefined
		priceTo: number | undefined
		priceSlider: number[] | undefined
	}>({
		priceFrom: priceFromValue,
		priceTo: priceToValue,
		priceSlider: priceSliderValue
	})

	const { priceFrom, priceTo, priceSlider } = serviceTotalPriceValues

	const disabled = priceFromDefaultValue === undefined || priceToDefaultValue === undefined

	useEffect(() => {
		setServiceTotalPriceValues({
			priceFrom: priceFromValue,
			priceTo: priceToValue,
			priceSlider: priceSliderValue
		})
	}, [priceFromValue, priceToValue, priceSliderValue])

	const handleChange = (newValues: PriceRangeFormFieldValueType) => {
		onChange(newValues)
		if (onAfterChange) {
			onAfterChange(newValues)
		}
	}

	const onChangePriceFrom: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const newValue = parseTextValueToNumberValue(e.target.value)
		setServiceTotalPriceValues((prevValues) => ({ ...prevValues, priceFrom: newValue }))
	}

	const onBlurPriceFrom: React.FocusEventHandler<HTMLInputElement> = (e) => {
		if (priceTo === undefined || priceFromDefaultValue === undefined) {
			return
		}

		let newValue = parseTextValueToNumberValue(e.target.value)

		// validate value
		if (newValue >= priceTo || newValue <= priceFromDefaultValue || !newValue) {
			newValue = priceFromDefaultValue
		}

		// update internal state
		setServiceTotalPriceValues((prevValues) => ({
			...prevValues,
			priceFrom: newValue,
			priceTo,
			priceSlider: [newValue, priceTo]
		}))

		// update parent state only when value change
		if (priceFromValue !== newValue) {
			handleChange({ priceFrom: newValue, priceTo })
		}
	}

	const onChangePriceTo: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const newValue = parseTextValueToNumberValue(e.target.value)
		setServiceTotalPriceValues((prevValues) => ({ ...prevValues, priceTo: newValue }))
	}

	const onBlurPriceTo: React.FocusEventHandler<HTMLInputElement> = (e) => {
		if (priceFrom === undefined || priceToDefaultValue === undefined) {
			return
		}

		let newValue = parseTextValueToNumberValue(e.target.value)

		// validate value
		if (priceFrom >= newValue || priceToDefaultValue <= newValue || !newValue) {
			newValue = priceToDefaultValue
		}

		// update internal state
		setServiceTotalPriceValues((prevValues) => ({
			...prevValues,
			priceFrom,
			priceTo: newValue,
			priceSlider: [priceFrom, newValue]
		}))

		// update parent state only when value change
		if (priceToValue !== newValue) {
			handleChange({ priceFrom, priceTo: newValue })
		}
	}

	return (
		<SC.PriceFieldWrapper>
			<SC.PriceInputsWrapper>
				<SC.PriceInput
					inputMode={'numeric'}
					type={'text'}
					suffix={currencySymbol}
					isPristine={priceFrom === priceFromDefaultValue}
					disabled={disabled}
					value={formatNumber(priceFrom)}
					onChange={onChangePriceFrom}
					onBlur={onBlurPriceFrom}
				/>
				<SC.PriceInputDivider>{'-'}</SC.PriceInputDivider>
				<SC.PriceInput
					inputMode={'numeric'}
					type={'text'}
					suffix={currencySymbol}
					isPristine={priceTo === priceToDefaultValue}
					value={formatNumber(priceTo)}
					disabled={disabled}
					onChange={onChangePriceTo}
					onBlur={onBlurPriceTo}
				/>
			</SC.PriceInputsWrapper>
			<Slider
				range
				min={priceFromDefaultValue}
				max={priceToDefaultValue}
				step={priceStep}
				value={priceSlider}
				allowCross={false}
				pushable={1}
				disabled={disabled}
				onChange={(newValue) => {
					// update internal state on every change
					if (Array.isArray(newValue)) {
						setServiceTotalPriceValues({
							priceFrom: newValue[0],
							priceTo: newValue[1],
							priceSlider: newValue
						})
					}
				}}
				onChangeComplete={(newValues) => {
					// update parent state only when user stopped dragging button and value are different from current parent state
					if (Array.isArray(newValues) && (newValues[0] !== priceFromValue || newValues[1] !== priceToValue)) {
						handleChange({ priceFrom: newValues[0], priceTo: newValues[1] })
					}
				}}
			/>
		</SC.PriceFieldWrapper>
	)
}

export default React.memo(PriceRangeFormField)
