/* eslint-disable global-require */
import React from 'react'
import { MapPointsResponse } from '../../../../types/types'

type Props = {
	salon: MapPointsResponse['mapPoints'][number]['salon']
	assetsPath: string
	className?: string
}

const SalonImage = ({ salon, assetsPath, className }: Props) => {
	const imageSmallSrc = salon.images[0]?.resizedImages.small
	const imageSrc = imageSmallSrc || `${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder-small.png')}`

	return <img src={imageSrc} alt={salon.name} loading='lazy' className={className} />
}

export default SalonImage
