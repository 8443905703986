import styled, { css } from 'styled-components'
import { NotinoLabelRegular400 } from '../../styles/helpers'

export const Checker = styled.span`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0px;
	width: 16px;
	height: 16px;
	outline: 1px solid transparent;
	background-color: #fff;
	border: 1px solid #ababab;
	border-radius: 2px;
	transition: all 0.3s ease 0s;
`

export const CheckboxInput = styled.input`
	position: absolute;
	opacity: 0;
	top: 0px;
	left: 0px;

	&:checked {
		& ~ ${Checker} {
			background-color: #000;
			border-color: #000;

			&::after {
				content: '';
				position: absolute;
				display: block;
				left: 5px;
				top: 2px;
				width: 4px;
				height: 8px;
				border: solid #000000;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
				border-color: #fff;
				border-style: solid;
				transition: border 0.3s ease 0s;
			}
		}

		&:disabled {
			& ~ ${Checker} {
				background-color: #e1e1e1;
				border-color: #e1e1e1;
			}
		}
	}

	&:not(:disabled) {
		&:focus-visible ~ ${Checker} {
			outline-color: #000;
		}
	}

	&:disabled {
		&:not(:checked) ~ ${Checker} {
			background-color: #f7f7f7;
			border-color: #e1e1e1;
		}
	}
`

export const CheckboxLabel = styled.label<{ $disabled?: boolean }>`
	padding-left: 28px;
	display: flex;
	align-items: center;
	position: relative;
	color: #000;
	cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
	outline: none;
	user-select: none;
	${NotinoLabelRegular400};

	${({ $disabled }) =>
		!$disabled &&
		css`
			color: #ababab;
			&:hover {
				${Checker} {
					border-color: #000;
				}
			}
		`}
`
