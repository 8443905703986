import React from 'react'

// styles
import * as SC from './RadioButtonsFormFieldStyles'

// types
import { RadioButtonsFormFieldProps } from './types'

// component
const RadioButtonsFormField = <V extends string | number>(props: RadioButtonsFormFieldProps<V>) => {
	const {
		options,
		disabled,
		input: { value, onChange, name },
		onAfterChange
	} = props

	const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>, option: V) => {
		if (e.target.checked) {
			onChange(option)
			if (onAfterChange) {
				onAfterChange(option)
			}
		}
	}

	return (
		<SC.RadioButtonsWrapper>
			{options.map((option) => {
				const checked = option.id === value

				return (
					<SC.RadioButton
						key={option.id}
						checked={checked}
						name={name}
						disabled={!!(disabled || option.disabled)}
						onChange={(e) => handleChangeValue(e, option.id)}
					>
						<SC.RadioButtonLabel>
							<SC.RadioButtonLabelTitle $checked={checked}>{option.label}</SC.RadioButtonLabelTitle>
							{option.description && <SC.RadioButtonLabelDescription>{option.description}</SC.RadioButtonLabelDescription>}
						</SC.RadioButtonLabel>
					</SC.RadioButton>
				)
			})}
		</SC.RadioButtonsWrapper>
	)
}

export default RadioButtonsFormField
