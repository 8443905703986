import { styled, theme } from '@notinosro/react-styleguide'
import { SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

export const HeaderContainerOpeningHours = styled.div`
	display: flex;
	align-items: center;
`

export const HeaderTitleOpeningHours = styled.div`
	${theme.typography.titleSmall}
	margin-left: 10px;
`

export const OpeningStatus = styled.div<{ $marginLeft: number | undefined }>`
	${theme.typography.titleSmall}
	margin-top: 10px;
	margin-left: ${(p) => (p.$marginLeft ? `${p.$marginLeft}px` : '0')};
`

export const OpeningStatusValue = styled.span<{ $isOpen: boolean }>`
	color: ${(p) => (p.$isOpen ? theme.palette.success : theme.palette.danger)};
`

export const OpeningHours = styled.div`
	${theme.typography.bodyLarge}
	margin-left: 34px;
	margin-top: 10px;

	@media (max-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-left: 0;
	}
`

export const OpeningHoursItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const OpeningHoursItemValue = styled.div<{ $isClosed: boolean; $isActualDay: boolean }>`
	color: ${(p) => (p.$isClosed ? theme.palette.neutralDark : 'inherit')};
	font-weight: ${(p) => (p.$isActualDay ? '500' : 'inherit')};
`

export const OpeningHoursContainer = styled.div`
	width: 100%;

	.rc-collapse-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
`
