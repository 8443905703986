import React, { useEffect, useState } from 'react'
import { ButtonModel, Colors, Modal, ModalModel } from '@notinosro/react-styleguide'
import ImageIcon from '../../../../assets/icons/ImageIcon'
import { Image } from '../../../../types/types'
import * as SC from './GalleryDesktopStyles'
import useMessages from '../../../../hooks/useMessages'

// constants
const MAX_IMAGES_IN_GALLERY = 5

type SalonGalleryProps = {
	assetsPath: string
	images: Image[]
}

const GalleryDesktop = (props: SalonGalleryProps) => {
	const { assetsPath, images } = props
	const { messages } = useMessages()
	const [photoIndex, setPhotoIndex] = useState<number>(-1)
	const modalOpen = photoIndex > -1

	// handling arrow keys to navigate between images when modal is open
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft') setPhotoIndex((prev) => (prev > 0 ? prev - 1 : 0))
			if (event.key === 'ArrowRight') setPhotoIndex((prev) => (prev < images.length - 1 ? prev + 1 : prev))
		}
		if (modalOpen) {
			window.addEventListener('keydown', handleKeyDown)
		}
		return () => {
			return window.removeEventListener('keydown', handleKeyDown)
		}
	}, [modalOpen, images.length])

	return (
		<>
			{/* grid */}
			<SC.ImageGridWrapper>
				{/* placeholder image */}
				{images.length === 0 && (
					<SC.ImageGrid $numberOfImages={1}>
						<div>
							<img
								loading='lazy'
								// eslint-disable-next-line global-require
								src={`${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder.png')}`}
								alt={messages?.salon}
							/>
						</div>
					</SC.ImageGrid>
				)}

				{/* salon images */}
				{images.length > 0 && (
					<SC.ImageGrid $numberOfImages={images.length}>
						{images.length > 0 &&
							images.slice(0, MAX_IMAGES_IN_GALLERY).map((image, index) => {
								return (
									// NOTE: the following rules are aimed at improving accessibility, but here they are not needed since we have button to open the lightbox
									// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
									<div key={index} onClick={() => setPhotoIndex(index)}>
										<img loading='lazy' src={index === 0 ? image.resizedImages.large : image.resizedImages.small} alt={messages?.salon} />
									</div>
								)
							})}
					</SC.ImageGrid>
				)}

				{images.length > 1 && (
					<SC.LightboxOpenButton buttonSize={ButtonModel.Sizes.xSmall} buttonStyle={ButtonModel.Styles.primary} onClick={() => setPhotoIndex(0)}>
						<ImageIcon />
						<span>{messages?.['Show all images'] as string}</span>
					</SC.LightboxOpenButton>
				)}
			</SC.ImageGridWrapper>

			<SC.ModalWrapper>
				<Modal
					withFocusTrap={modalOpen}
					show={modalOpen}
					closeIconColor={Colors.primary}
					onClose={() => setPhotoIndex(-1)}
					size={ModalModel.Sizes.s1200}
				>
					<SC.MainImageWrapper>
						<SC.ModalImage src={images?.[photoIndex]?.resizedImages?.large || ''} alt={messages?.salon} />
					</SC.MainImageWrapper>
					<SC.ThumbnailButtonsContainer>
						{images.map((image, index) => {
							return (
								// NOTE: opening image onMouseOver implemeneted to match behaviour on Notino website, onClick used to open image when pressing enter when button focused
								<SC.ThumbnailButton key={index} onMouseOver={() => setPhotoIndex(index)} onClick={() => setPhotoIndex(index)}>
									<SC.ModalImage src={image.resizedImages?.thumbnail || ''} alt={messages?.salon} />
								</SC.ThumbnailButton>
							)
						})}
					</SC.ThumbnailButtonsContainer>
				</Modal>
			</SC.ModalWrapper>
		</>
	)
}

export default GalleryDesktop
