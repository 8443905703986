import React from 'react'

const ChevronDownLeft = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
			<mask id='mask0_1967_2825' style={{ maskType: 'alpha' }} width='10' height='16' x='5' y='2' maskUnits='userSpaceOnUse'>
				<path fill='#fff' d='M14.555 3.721L13.334 2.5l-7.5 7.5 7.5 7.5 1.22-1.221L8.274 10l6.282-6.279z' />
			</mask>
			<g fill='currentColor' mask='url(#mask0_1967_2825)'>
				<path d='M14.555 3.721L13.334 2.5l-7.5 7.5 7.5 7.5 1.22-1.221L8.274 10l6.282-6.279z' />
				<path d='M20 0H0v20h20V0z' />
			</g>
		</svg>
	)
}

export default ChevronDownLeft
