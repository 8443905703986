/* eslint-disable global-require */
import styled from 'styled-components'
import { BREAKPOINTS, NOTINO_MAIN_APP_PADDING } from '../../../../styles/constants'

// utils

export const SalonsPageBannerContainer = styled.div<{ $assetsPath: string; $negativeMargin?: boolean }>`
	padding-bottom: 47%;
	height: 0;
	overflow: hidden;
	position: relative;
	background-size: auto 100%;
	// NOTE: notino main app provides padding, so we need to add it to the width of our banner container in order to have full-width banner without white spacing on the sides
	margin: 0 -${({ $negativeMargin }) => ($negativeMargin ? NOTINO_MAIN_APP_PADDING : 0)}px;
	background-position: center;

	@media (min-width: ${BREAKPOINTS.md}) {
		padding-bottom: 35%;
	}

	@media (min-width: ${BREAKPOINTS.lg}) {
		padding-bottom: 340px;
		background-image: ${({ $assetsPath }) => `url("${`${$assetsPath}/${require(`../../../../assets/images/top-banner-background.jpg`)}`}")`};
	}
`

export const SalonsPageBanner = styled.div<{ $bannerDesktop: string; $bannerMobile: string }>`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	width: 100%;
	max-width: 1035px;
	transform: translateX(-50%);
	background-image: ${({ $bannerMobile }) => `url("${`${$bannerMobile}`}")`};
	background-position: center;
	background-size: auto 100%;
	background-repeat: no-repeat;

	@media (min-width: ${BREAKPOINTS.md}) {
		background-image: ${({ $bannerDesktop }) => `url("${`${$bannerDesktop}`}")`};
	}
`
