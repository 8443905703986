import * as React from 'react'
import { IntlProvider, defineMessages } from 'react-intl'
import { useInitialData } from '@notinosro/react-toolkit/renderer/fragment/useInitialData'

// types
import { ContextProps, IGetInitialDataProps, LocalizationData } from '../types/types'

const messages = defineMessages({})

const LanguageProvider: IGetInitialDataProps<LocalizationData, ContextProps> = ({ children, ...props }) => {
	const { locale } = props // locale here is 'sk-SK', 'cs-CZ', etc.

	const data = useInitialData(LanguageProvider, props) || {
		messages,
		locale: locale.substring(0, 2) // we only take the first part of locale, that is 'sk', 'cs', etc.
	}

	return (
		<IntlProvider locale={data.locale.substring(0, 2)} key={data.locale} messages={data.messages}>
			{React.Children.only(children)}
		</IntlProvider>
	)
}

LanguageProvider.identifier = 'LanguageProvider'

LanguageProvider.initDefaultData = async ({ api }) => {
	return api.getTranslations()
}

export default LanguageProvider
