import React, { useEffect, useState } from 'react'
import { HttpStatus } from '@notinosro/react-toolkit/renderer/fragment/contexts/HttpContext'
import { useInitialData } from '@notinosro/react-toolkit/renderer/fragment/useInitialData'
import { useIntl } from 'react-intl'
import useMessages from '../../hooks/useMessages'
import Paths from '../../routes/paths'
import * as SC from './SalonPageStyles'

// components
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import SalonSeoSchema from './components/SalonSeoSchema/SalonSeoSchema'
import BasicSalon from './components/BasicSalon/BasicSalon'
import ProfiSalon from './components/ProfiSalon/ProfiSalon'
import ReservationModal from './components/ReservationModal/ReservationModal'
import SalonHead from './components/SalonHead/SalonHead'

// types
import {
	ConfigResponse,
	ContextProps,
	IGetInitialDataProps,
	SalonResponse,
	SalonReviewsResponse,
	SalonServicesResponse,
	SalonPageQueryType
} from '../../types/types'

// utils
import { pushToDataLayer } from '../../utils/dataLayer'
import { getPageViewEvent, getViewItemEvent } from '../../utils/dataLayerEvents'
import { GA_EVENTS_DELAY, SALON_TYPE } from '../../utils/enums'
import { getFirstLevelCategoriesNames } from '../../utils/helper'

type ExtendedContextProps = ContextProps & {
	salonSlug: string
	categorySlug: string
	query: SalonPageQueryType
}

const SalonPage: IGetInitialDataProps<
	{
		salonData: SalonResponse | undefined
		servicesData: SalonServicesResponse | undefined
		configData: ConfigResponse | undefined
		reviewsData: SalonReviewsResponse | undefined
		salonSlug: string
	},
	ExtendedContextProps
> = (props) => {
	const data = useInitialData(SalonPage, props)
	const { salonData, servicesData, configData, reviewsData, salonSlug } = data || {}
	const { assetsPath, googleMapsApiKey, googleMapsUrl } = props
	const { locale } = useIntl()
	const { messages } = useMessages()
	const [reservationModalOpen, setReservationModalOpen] = useState(false)

	// handle statuses from BE -> 404
	if (salonData?.status && salonData?.status === 404) {
		return <HttpStatus status={404}>{null}</HttpStatus>
	}
	// necessary for notino toolkit
	if (!data || !salonData || !configData || !servicesData || !salonSlug || !configData) {
		return null
	}

	const { salon } = salonData
	const salonServices = getFirstLevelCategoriesNames(servicesData)
	const isBasic = salonData.salon?.createType === SALON_TYPE.BASIC

	// breadcrumbs
	const breadcrumbItems = [
		{ link: Paths[locale as keyof typeof Paths]['/salons'], name: messages?.Salons as string },
		{ link: '', name: salonData.salon?.name || '' }
	]

	// GA events
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		const pageViewEvent = getPageViewEvent('salon')
		pushToDataLayer(pageViewEvent)

		// timeout so the dataLayer is updated after the page_view event is sent
		setTimeout(() => {
			if (!salonData.salon) return
			const viewItemEvent = getViewItemEvent({ salon: salonData?.salon })
			pushToDataLayer(viewItemEvent)
		}, GA_EVENTS_DELAY)
	}, [salonData.salon])

	return (
		<>
			{/* salon head and seo schema */}
			{salon && (
				<>
					<SalonHead salonSlug={salonSlug} salon={salon} salonServices={salonServices} assetsPath={assetsPath} />
					<SalonSeoSchema
						salon={salon}
						breadcrumbItems={breadcrumbItems}
						countriesData={configData.rolloutCountries || []}
						assetsPath={assetsPath}
						salonSlug={salonSlug}
					/>
				</>
			)}

			<SC.SeoTitle>{salon?.name}</SC.SeoTitle>

			<SC.PageContainer>
				<Breadcrumb breadcrumbItems={breadcrumbItems} />
				{salon &&
					(isBasic ? (
						<BasicSalon
							salonData={salon}
							countriesData={configData.rolloutCountries}
							assetsPath={assetsPath}
							googleMapsApiKey={googleMapsApiKey}
							googleMapsUrl={googleMapsUrl}
						/>
					) : (
						<ProfiSalon
							salonData={salon}
							countriesData={configData.rolloutCountries}
							salonServicesData={servicesData}
							salonReviewsData={reviewsData}
							assetsPath={assetsPath}
							googleMapsApiKey={googleMapsApiKey}
							googleMapsUrl={googleMapsUrl}
							openReservationModal={() => setReservationModalOpen(true)}
						/>
					))}
			</SC.PageContainer>

			<ReservationModal
				isOpen={reservationModalOpen}
				onClose={() => setReservationModalOpen(false)}
				assetsPath={assetsPath}
				availableReservation={!!salon?.availableReservationSystem}
			/>
		</>
	)
}

SalonPage.initDefaultData = async ({ api, salonSlug }) => {
	const data = await Promise.all([api.getSalonData(salonSlug), api.getConfigData()])

	const salonData: SalonResponse = data?.[0]
	const configData: ConfigResponse | undefined = data?.[1]

	let servicesData: SalonServicesResponse | undefined
	let reviewsData: SalonReviewsResponse | undefined

	if (salonData?.salon) {
		;[servicesData, reviewsData] = await Promise.all([
			api.getSalonServicesData(salonData.salon.id),
			api.getSalonReviewsData(salonData.salon.id, { page: 1 })
		])
	}

	// fetch salon data
	return { salonData, servicesData, configData, reviewsData, salonSlug }
}

SalonPage.identifier = 'SalonPage'

export default SalonPage
