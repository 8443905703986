import React from 'react'

const ChevronUpIcon = () => {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<mask id='mask0_14519_10867' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='3' y='6' width='18' height='11'>
				<path d='M4.46529 17L3 15.5347L12 6.53471L21 15.5347L19.5347 17L12 9.46529L4.46529 17Z' fill='white' />
			</mask>
			<g mask='url(#mask0_14519_10867)'>
				<path d='M4.46529 17L3 15.5347L12 6.53471L21 15.5347L19.5347 17L12 9.46529L4.46529 17Z' fill='black' />
			</g>
		</svg>
	)
}

export default ChevronUpIcon
