import React from 'react'
import { Line } from 'rc-progress'
import { Colors, Ratings } from '@notinosro/react-styleguide'
import * as SC from './SalonRatingsStyles'

// types
import { Ratings as RatingsData } from '../../../../types/types'

type SalonRatingsProps = {
	ratingsData: RatingsData
}

const SalonRatings = (props: SalonRatingsProps) => {
	const { ratingsData: ratings } = props

	const ratingsList = [
		{ rating: 5, count: ratings.groupedRatings.FIVE, percentage: (ratings.groupedRatings.FIVE / ratings.ratesCount) * 100 },
		{ rating: 4, count: ratings.groupedRatings.FOUR, percentage: (ratings.groupedRatings.FOUR / ratings.ratesCount) * 100 },
		{ rating: 3, count: ratings.groupedRatings.THREE, percentage: (ratings.groupedRatings.THREE / ratings.ratesCount) * 100 },
		{ rating: 2, count: ratings.groupedRatings.TWO, percentage: (ratings.groupedRatings.TWO / ratings.ratesCount) * 100 },
		{ rating: 1, count: ratings.groupedRatings.ONE, percentage: (ratings.groupedRatings.ONE / ratings.ratesCount) * 100 }
	]

	return (
		<div>
			{ratingsList.map((ratingObj) => {
				return (
					<SC.RatingWrapper key={ratingObj.rating}>
						<SC.ProgressLineWithCount>
							<Line percent={ratingObj.percentage} strokeColor='#000' strokeLinecap='square' />
							<SC.Count>{ratingObj.count}</SC.Count>
						</SC.ProgressLineWithCount>
						<Ratings foreground={Colors.primary} rating={ratingObj.rating} ratingId={'rating-salon-detail'} size={13} />
					</SC.RatingWrapper>
				)
			})}
		</div>
	)
}

export default SalonRatings
