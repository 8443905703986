import { Colors, Spinner } from '@notinosro/react-styleguide'
import React, { PropsWithChildren } from 'react'

// styles
import * as SC from './ButtonStyles'

type Props = {
	fullWidth?: boolean
	loading?: boolean
} & PropsWithChildren &
	React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = (props: Props) => {
	const { children, loading, fullWidth, ...restProps } = props

	return (
		<SC.Button $fullWidth={fullWidth} {...restProps}>
			{loading ? <Spinner size={16} color={Colors.neutral} /> : children}
		</SC.Button>
	)
}

export default Button
