import styled from 'styled-components'

// utils
import { NotinoLabelLarge, truncate } from '../../styles/helpers'

export const SwitchFieldDescription = styled.span`
	display: inline-block;
	color: #000;
	${NotinoLabelLarge};
	${truncate};
`

export const SwitchFieldLabel = styled.label`
	width: 100%;
	height: 100%;
	display: flex;
	gap: 16px;
	cursor: pointer;
	justify-content: space-between;
	align-items: center;
	overflow: visible;
	outline: 2px solid transparent;
	outline-offset: 3px;

	&:hover {
		${SwitchFieldDescription} {
			text-decoration: underline;
		}
	}
`
