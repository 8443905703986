import styled from 'styled-components'
import { CloseIcon as NotinoCloseIcon } from '@notinosro/react-styleguide'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

// styles
import { NotinoLabelLarge } from '../../styles/helpers'

export const DrawerBody = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
`

export const Header = styled.header`
	display: flex;
	align-items: center;
	padding: 12px 20px 0 20px;
	height: 64px;
	box-shadow:
		0px 2px 4px 0px rgba(71, 71, 71, 0.08),
		0px 1px 2px 0px rgba(71, 71, 71, 0.04);
	position: relative;
	z-index: 2;
`

export const CloseButton = styled.button`
	width: 20px;
	height: 20px;
	display: flex;
	border: none;
	background: transparent;
	transition: 0.3s ease;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
`

export const CloseIcon = styled(NotinoCloseIcon)<{ $isSelected?: boolean }>`
	color: #000;
	width: 16px;
	height: 16px;
`

export const HeaderTitle = styled.h1`
	color: #000;
	${NotinoLabelLarge};
	margin: 0 !important; // NOTE: !important overrides Notino main app heading global styles
`

export const HeaderExtra = styled.div`
	margin-left: auto;
`

export const Content = styled(OverlayScrollbarsComponent)`
	flex: 1;
	min-width: 0;
	min-height: 0;
	overflow: auto;
	position: relative;
	z-index: 1;
	transition: height 0.3s ease;
`

export const Footer = styled.footer`
	flex-shrink: 0;
	border-top: 1px solid rgba(5, 5, 5, 0.06);
	box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.05);
	position: relative;
	z-index: 2;
`
