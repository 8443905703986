import { useCallback, useEffect, useState } from 'react'

const SCROLL_DISTANCE = 100
const IS_SCROLLED_BUFFER = 5

const useScrolledElement = (element: HTMLElement | null) => {
	const [isScrolledLeft, setIsScrolledLeft] = useState(false)
	const [isScrolledRight, setIsScrolledRight] = useState(false)

	useEffect(() => {
		if (!element) return undefined

		const handleScroll = () => {
			// left scroll
			if (element.scrollLeft > IS_SCROLLED_BUFFER) {
				setIsScrolledLeft(true)
			} else {
				setIsScrolledLeft(false)
			}
			// right scroll
			if (element.scrollLeft + element.clientWidth > element.scrollWidth - IS_SCROLLED_BUFFER) {
				setIsScrolledRight(true)
			} else {
				setIsScrolledRight(false)
			}
		}

		element.addEventListener('scroll', handleScroll)

		return () => {
			element?.removeEventListener('scroll', handleScroll)
		}
	}, [element])

	const scrollLeft = useCallback(() => element?.scrollBy(-SCROLL_DISTANCE, 0), [element])
	const scrollRight = useCallback(() => element?.scrollBy(SCROLL_DISTANCE, 0), [element])

	return { isScrolledLeft, isScrolledRight, scrollLeft, scrollRight } as const
}

export default useScrolledElement
