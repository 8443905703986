/* eslint-disable import/no-dynamic-require, global-require */
import { ActionButton, ButtonModel, Colors, IBreadcrumb, IDropdownOption, SearchIcon, Pager } from '@notinosro/react-styleguide'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

// utils
import { SALONS_PER_PAGE } from '../../../../api/Salons'

// hooks
import useMessages from '../../../../hooks/useMessages'
import useSortingOptions from '../../../../hooks/useSortingOptions'

// styles
import * as SC from './SalonsViewStyles'

// types
import {
	AvailableLanguagesType,
	Category,
	ContextProps,
	Cosmetic,
	GetSalonsFilterCountResponse,
	Language,
	Salon,
	SalonsResponse,
	LoadSalonsFromFiltersParams,
	LoadSalonsFromTopLevelCategory,
	SalonsPageQueryType,
	SalonsPageSetQueryType,
	SubcategoryChangeEventInteraction,
	ServiceTotalPriceCurrencyCode
} from '../../../../types/types'

// components
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb'
import SalonsSeoSchema from '../SalonsSeoSchema/SalonsSeoSchema'
import SalonDetail from '../SalonDetail/SalonDetail'
import SalonDetailPlaceholder from '../SalonDetailPlaceholder/SalonDetailPlaceholder'
import SalonsMap from '../SalonsMap/SalonsMap'
import SalonsSearch from '../SalonsSearch/SalonsSearch'
import Filters from '../Filters/Filters'

// assets
import ListIcon from '../../../../assets/icons/ListIcon'
import MapIcon from '../../../../assets/icons/MapIcon'

// utils
import Paths from '../../../../routes/paths'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import {
	getListPaginatingEvent,
	getSortingSetEvent,
	getSubcategoryChangeEvent,
	getToggleMapEvent,
	getTopLevelCategoryChangeEvent,
	getViewItemListEvent
} from '../../../../utils/dataLayerEvents'
import { GA_EVENTS_DELAY, SORTING_OPTION } from '../../../../utils/enums'
import { SCROLLBAR_OPTIONS } from '../../../../utils/helper'

const BANNER_INDEX = 3

// props
type SalonsViewProps = ContextProps & {
	salons: Salon[]
	categories: Category[]
	currentCategory?: Category
	pagination: SalonsResponse['pagination']
	query: SalonsPageQueryType
	setQuery: SalonsPageSetQueryType
	salonsLoading: boolean
	buttonLoading: boolean
	loadSalonsFromLoadMoreButton: () => void
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	loadSalonsFromTopLevelCategory: (params: LoadSalonsFromTopLevelCategory) => void
	googlePlaceID?: string
	showTopLevelCategories: boolean
	showSubcategories: boolean
	cosmetics: Cosmetic[]
	languages: Language[]
	filterCounts?: GetSalonsFilterCountResponse
	currencySymbol?: string
	currencyCode?: ServiceTotalPriceCurrencyCode
	initialClientDataLoading?: boolean
	maxAvResTimeSlotDateRange: number | undefined
}

// component
const SalonsView: React.FC<React.PropsWithChildren<SalonsViewProps>> = (props) => {
	const {
		salons,
		loadSalonsFromFilters,
		loadSalonsFromTopLevelCategory,
		categories,
		currentCategory,
		pagination,
		loadSalonsFromLoadMoreButton,
		query,
		setQuery,
		salonsLoading,
		buttonLoading,
		googlePlaceID,
		showTopLevelCategories,
		assetsPath,
		googleMapsApiKey,
		cosmetics,
		languages,
		filterCounts,
		currencySymbol,
		currencyCode,
		initialClientDataLoading,
		maxAvResTimeSlotDateRange
	} = props
	const intl = useIntl()
	const locale = intl.locale as AvailableLanguagesType
	const { messages } = useMessages()
	const { formatMessage } = useIntl()

	// breadcrumbs
	const breadcrumbItems = [{ link: `${Paths[locale as keyof typeof Paths]['/salons']}`, name: messages?.Salons as string }]
	if (currentCategory && currentCategory.name) breadcrumbItems.push({ link: '', name: currentCategory.name })

	const handleNavigation = (item: IBreadcrumb) => {
		if (item.link === breadcrumbItems[0].link) {
			loadSalonsFromTopLevelCategory({ categorySlug: null })
		}
	}

	// sorting
	const [currentSortingOption, sortingOptions] = useSortingOptions(query)

	// categories
	const categoriesForSlider = showTopLevelCategories ? categories : currentCategory?.children || []
	const handleCategoryButtonClick = (categoryID: string, categorySlug?: string) => {
		if (showTopLevelCategories) {
			// button act as top level category button
			loadSalonsFromTopLevelCategory({ categorySlug })

			// push to dataLayer
			const event = getTopLevelCategoryChangeEvent({ categoryID, categorySlug: categorySlug as string })
			pushToDataLayer(event)
		} else {
			// button act as subcategory button
			const queryCategoryIDs = query.categoryIDs
			let newCategoryIDs: string[]
			let eventInteraction: SubcategoryChangeEventInteraction

			if (queryCategoryIDs.includes(categoryID)) {
				newCategoryIDs = queryCategoryIDs.filter((cID) => cID !== categoryID)
				eventInteraction = 'click_remove'
			} else {
				newCategoryIDs = [...queryCategoryIDs, categoryID]
				eventInteraction = 'click_add'
			}

			// push to dataLayer
			const event = getSubcategoryChangeEvent({
				eventInteraction,
				clickedCategoryID: categoryID,
				clickedCategorySlug: categorySlug as string
			})
			pushToDataLayer(event)

			loadSalonsFromFilters({ categoryIDs: newCategoryIDs })
		}
	}

	// map view
	const { isMapView } = query
	const toggleMapView = () => {
		setQuery({ isMapView: isMapView ? undefined : true })

		// push to dataLayer
		const event = getToggleMapEvent(isMapView)
		pushToDataLayer(event)
	}

	// filters
	const handleOptionSelect = (option: IDropdownOption) => {
		loadSalonsFromFilters({ orderBy: option.id as SORTING_OPTION })

		// push to dataLayer
		const event = getSortingSetEvent({ option })
		pushToDataLayer(event)
	}

	// ga event
	useEffect(() => {
		// timeout so the dataLayer is updated after the page_view event is sent (in AppLayout)
		setTimeout(() => {
			const event = getViewItemListEvent({ currentPage: query.page, currentSortingOptionName: `${currentSortingOption.id}`, salons })
			pushToDataLayer(event)
		}, GA_EVENTS_DELAY)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// pagination
	const handlePageChange = (page: number) => {
		setQuery({ ...query, page })
		// push to dataLayer
		const event = getListPaginatingEvent({ page })
		pushToDataLayer(event)
	}

	const remainingSalonsCount = pagination.totalCount - pagination.page * pagination.limit
	const showMoreButtonText = formatMessage(
		{ id: 'Show more salons ({remainingSalonsCount})', defaultMessage: 'Show more salons ({remainingSalonsCount})' },
		{ remainingSalonsCount }
	)

	// salons
	const salonsToRender = salons?.map((salon, index) => {
		// NOTE: list position is index of salon in the list of ALL available salons
		const pageOffset = (query.page - 1) * SALONS_PER_PAGE
		const listPosition = index + 1 + pageOffset
		return (
			<SalonDetail
				salon={salon}
				key={salon.id}
				{...props}
				isLoading={salonsLoading}
				currentCategorySlug={currentCategory?.nameSlug}
				listPosition={listPosition}
			/>
		)
	})

	// banner src
	let bannerSrc: string
	let bannerLink: string

	switch (locale) {
		case 'ro':
			bannerSrc = `${assetsPath}/${require(`../../../../assets/images/banner-ro.jpg`)}`
			bannerLink =
				'https://notino.page.link/?link=https://www.notino.com?appType%3Dpartner&isi=1261812151&ibi=com.notino.notino&utm_campaign=web-salon-katalog-banner&utm_medium=button&utm_source=web-banner&ofl=https://www.notino.ro/mobile-application'
			break
		case 'bg':
			bannerSrc = `${assetsPath}/${require(`../../../../assets/images/banner-bg.jpg`)}`
			bannerLink =
				'https://notino.page.link/?link=https://www.notino.com?appType%3Dpartner&isi=1261812151&ibi=com.notino.notino&utm_campaign=web-salon-katalog-banner&utm_medium=button&utm_source=web-banner&ofl=https://www.notino.bg/mobile-application'
			break
		case 'hu':
			bannerSrc = `${assetsPath}/${require(`../../../../assets/images/banner-hu.jpg`)}`
			bannerLink =
				'https://notino.page.link/?link=https://www.notino.com?appType%3Dpartner&isi=1261812151&ibi=com.notino.notino&utm_campaign=web-salon-katalog-banner&utm_medium=button&utm_source=web-banner&ofl=https://www.notino.hu/mobile-application'
			break
		case 'sk':
			bannerSrc = `${assetsPath}/${require(`../../../../assets/images/banner-sk.jpg`)}`
			bannerLink =
				'https://notino.page.link/?link=https://www.notino.com?appType%3Dpartner&isi=1261812151&ibi=com.notino.notino&utm_campaign=web-salon-katalog-banner&utm_medium=button&utm_source=web-banner&ofl=https://www.notino.sk/mobile-application'
			break
		case 'cs':
		default:
			bannerSrc = `${assetsPath}/${require(`../../../../assets/images/banner-cs.jpg`)}`
			bannerLink =
				'https://notino.page.link/?link=https://www.notino.com?appType%3Dpartner&isi=1261812151&ibi=com.notino.notino&utm_campaign=web-salon-katalog-banner&utm_medium=button&utm_source=web-banner&ofl=https://www.notino.cz/mobile-application'

			break
	}

	// add banners to salons
	const bannersIndexes: number[] = [] // banner is always on 4th places of "page", so [3, 15, 27, 39, ...]
	for (let index = 0; index < (query.page || 1); index += 1) {
		const itemsPerPage = SALONS_PER_PAGE + 1 // +1 to include banner
		bannersIndexes.push(itemsPerPage * index + BANNER_INDEX)
	}

	bannersIndexes.forEach((index) => {
		// prepare banner or placeholder
		const banner = salonsLoading ? (
			<SalonDetailPlaceholder key={`banner-placeholder-${index}`} />
		) : (
			<a key={`banner-${index}`} href={bannerLink} target='_blank' rel='nofollow noreferrer'>
				<img src={bannerSrc} alt={'banner'} />
			</a>
		)

		// add banner to salons
		if (salonsToRender.length > index) {
			salonsToRender.splice(index, 0, banner)
		}
	})

	return (
		<>
			<SalonsSeoSchema breadcrumbItems={breadcrumbItems} />

			<SC.Grid>
				{/* breadcrumb (only showing when inside category) */}
				{Boolean(currentCategory?.name) && <Breadcrumb breadcrumbItems={breadcrumbItems} handleNavigation={handleNavigation} />}

				{/* main title */}
				<SC.MainTitle>{currentCategory?.name || messages?.Salons}</SC.MainTitle>

				{/* location input */}
				<SC.SalonsSearchWrapper>
					<SalonsSearch {...props} defaultValue={googlePlaceID || ''} onChange={(city) => loadSalonsFromFilters({ city })} />
				</SC.SalonsSearchWrapper>

				{/* categories slider */}
				{showTopLevelCategories ? (
					<SC.TopLevelCategoriesSlider options={SCROLLBAR_OPTIONS}>
						<SC.TopLevelCategoriesSliderContent>
							{categories.map((category) => (
								<SC.TopLevelCategoryButton
									type='button'
									onClick={() => handleCategoryButtonClick(category.id, category?.nameSlug)}
									key={category.id}
								>
									<SC.TopLevelCategoryImgWrapper>
										<SC.TopLevelCategoryImg src={category.image?.resizedImages.thumbnail} alt={category.name || ''} />
									</SC.TopLevelCategoryImgWrapper>
									<SC.TopLevelCategoryTitle>{category.name}</SC.TopLevelCategoryTitle>
								</SC.TopLevelCategoryButton>
							))}
						</SC.TopLevelCategoriesSliderContent>
					</SC.TopLevelCategoriesSlider>
				) : (
					<SC.SubcategoriesSlider options={SCROLLBAR_OPTIONS}>
						<SC.SubcategoriesSliderContent>
							{categoriesForSlider.map((category) => (
								<SC.SubcategoryButton
									type='button'
									onClick={() => handleCategoryButtonClick(category.id, category?.nameSlug)}
									buttonStyle={query.categoryIDs.includes(category.id) ? ButtonModel.Styles.secondary : ButtonModel.Styles.inverse}
									key={category.id}
								>
									{/* eslint-disable-next-line  */}
									{/* @ts-ignore: 2339 */}
									{category.icon && <SC.CategoryButtonIcon loading='lazy' src={category.icon.original} alt='' />}
									<span>{category.name}</span>
								</SC.SubcategoryButton>
							))}
						</SC.SubcategoriesSliderContent>
					</SC.SubcategoriesSlider>
				)}

				{/* filters */}
				<Filters
					query={query}
					filterCounts={filterCounts}
					currencySymbol={currencySymbol}
					currencyCode={currencyCode}
					cosmetics={cosmetics}
					languages={languages}
					loadSalonsFromFilters={loadSalonsFromFilters}
					initialClientDataLoading={initialClientDataLoading}
					topLevelCategoryID={currentCategory?.id}
					assetsPath={assetsPath}
					maxAvResTimeSlotDateRange={maxAvResTimeSlotDateRange}
				/>

				{/* sorting and pagination desktop */}
				{!isMapView && (
					<SC.SortingAndPaginationDesktop>
						<SC.ToggleMapButton onClick={toggleMapView}>
							<MapIcon />
							<span>{messages['Show on map']}</span>
						</SC.ToggleMapButton>
						<SC.SortingSelect
							$assetsPath={assetsPath}
							currentOption={currentSortingOption}
							handleOptionSelect={handleOptionSelect}
							options={sortingOptions}
							borderless
						/>
					</SC.SortingAndPaginationDesktop>
				)}

				{/* sorting mobile */}
				{!isMapView && (
					<SC.SortingMobile>
						<SC.SortingSelect
							$assetsPath={assetsPath}
							currentOption={currentSortingOption}
							handleOptionSelect={handleOptionSelect}
							options={sortingOptions}
							borderless
						/>
					</SC.SortingMobile>
				)}

				{/* salons grid and map toggle button */}
				{!isMapView && salons?.length > 0 && (
					<>
						<SC.SalonsGridView>{salonsToRender}</SC.SalonsGridView>
						<SC.ToggleMapContainerMobile>
							<SC.ToggleMapButtonMobile onClick={toggleMapView}>
								<MapIcon />
								{messages['Show on map']}
							</SC.ToggleMapButtonMobile>
						</SC.ToggleMapContainerMobile>
					</>
				)}

				{/* no salons message */}
				{!isMapView && salons?.length === 0 && (
					<SC.NoSalonsMessageWrapper>
						<SearchIcon color={Colors.neutral} height='50' width='50' />
						<SC.NoSalonsMessage>{messages?.['No results found']}</SC.NoSalonsMessage>
					</SC.NoSalonsMessageWrapper>
				)}

				{/* load more salons button */}
				{!isMapView && (
					<SC.BottomNavigation>
						{salons?.length > 0 && query.page !== pagination.totalPages && query.page !== pagination.totalPages && (
							<ActionButton loading={buttonLoading} buttonStyle={ButtonModel.Styles.secondary} onClick={loadSalonsFromLoadMoreButton}>
								{showMoreButtonText}
							</ActionButton>
						)}
						{salons?.length > 0 && pagination.totalPages > 1 && (
							<SC.PaginationWrapper>
								<Pager currentPage={query.page} lastPage={pagination.totalPages} onPageChange={handlePageChange} />
							</SC.PaginationWrapper>
						)}
					</SC.BottomNavigation>
				)}

				{/* salons map */}
				{isMapView && (
					<SC.SalonsMapView>
						<SC.ToggleListContainerDesktop>
							<SC.ToggleMapButton onClick={toggleMapView}>
								<ListIcon />
								<span>{messages['Show as list']}</span>
							</SC.ToggleMapButton>
						</SC.ToggleListContainerDesktop>
						<SalonsMap
							{...props}
							assetsPath={assetsPath}
							googleMapsApiKey={googleMapsApiKey}
							query={query}
							topLevelCategoryID={currentCategory?.id}
							toggleMapView={toggleMapView}
							currencyCode={currencyCode}
						/>
					</SC.SalonsMapView>
				)}
			</SC.Grid>
		</>
	)
}

export default SalonsView
