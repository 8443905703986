import { AvailableLanguagesType } from '../types/types'

type PathsType = {
	[key in AvailableLanguagesType]: {
		'/salons': string
		'/salons/services': string
		'/salons/services/:categorySlug': string
		'/salons/:salonSlug': string
	}
}

const Paths: PathsType = {
	cs: {
		'/salons': '/salony/',
		'/salons/services': '/salony/sluzby/',
		'/salons/services/:categorySlug': '/salony/sluzby/:categorySlug/',
		'/salons/:salonSlug': '/salony/:salonSlug/'
	},
	sk: {
		'/salons': '/salony/',
		'/salons/services': '/salony/sluzby/',
		'/salons/services/:categorySlug': '/salony/sluzby/:categorySlug/',
		'/salons/:salonSlug': '/salony/:salonSlug/'
	},
	bg: {
		'/salons': '/saloni/',
		'/salons/services': '/saloni/uslugi/',
		'/salons/services/:categorySlug': '/saloni/uslugi/:categorySlug/',
		'/salons/:salonSlug': '/saloni/:salonSlug/'
	},
	hu: {
		'/salons': '/szalonok/',
		'/salons/services': '/szalonok/szolgaltatasok/',
		'/salons/services/:categorySlug': '/szalonok/szolgaltatasok/:categorySlug/',
		'/salons/:salonSlug': '/szalonok/:salonSlug/'
	},
	ro: {
		'/salons': '/saloane/',
		'/salons/services': '/saloane/servicii/',
		'/salons/services/:categorySlug': '/saloane/servicii/:categorySlug/',
		'/salons/:salonSlug': '/saloane/:salonSlug/'
	}
}

export default Paths
