import styled from 'styled-components'
import { NotinoBodyMedium } from '../../../../styles/helpers'

export const Container = styled.div`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	gap: 28px;
`

export const Employee = styled.div`
	${NotinoBodyMedium};

	width: 80px;
	text-align: center;
	flex-shrink: 0;
	padding-bottom: 4px; /* gap to (potential) horizontal scrollbar */
	display: grid;
	place-items: center;

	& > img {
		aspect-ratio: 1;
		object-fit: contain;
		border-radius: 50%;
	}
`
