import React from 'react'
import * as SC from './SalonDetailPlaceholderStyles'

const SalonDetailPlaceholder = () => {
	return (
		<div>
			<SC.PlaceholderImage />
			<SC.PlaceholderText />
			<SC.PlaceholderText />
			<SC.PlaceholderText />
		</div>
	)
}

export default SalonDetailPlaceholder
