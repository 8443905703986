import { CollapseProps } from 'rc-collapse'
import React from 'react'
import collapseMotion from './collapseMotion'
import * as SC from './FilterCollapseStyles'

type Props = {} & CollapseProps

const FilterCollapse = (props: Props) => {
	return (
		<SC.FilterCollapse
			openMotion={collapseMotion}
			className={`notino-collapse ${props.className || ''}`.trim()}
			expandIcon={() => {
				return <SC.CollapseExpandIcon />
			}}
			{...props}
		/>
	)
}

export default React.memo(FilterCollapse)
