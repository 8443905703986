import React from 'react'
import useMessages from '../../../../hooks/useMessages'

// types
import { ConfigResponse, SalonDetail } from '../../../../types/types'

// components
import OpeningHours from '../OpeningHours/OpeningHours'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import PaymentDetails from '../PaymentDetails/PaymentDetails'

// assets
import MapPinIcon from '../../../../assets/icons/MapPinIcon'
import MailIcon from '../../../../assets/icons/MailIcon'
import PhoneIcon from '../../../../assets/icons/PhoneIcon'
import ParkingIcon from '../../../../assets/icons/ParkingIcon'
import CreditCardIcon from '../../../../assets/icons/CreditCardIcon'
import MapIcon from '../../../../assets/icons/MapIcon'

// utils
import { getGoogleMapsLink, getPhoneNumber } from '../../../../utils/helper'
import { SALON_TYPE } from '../../../../utils/enums'

// styles
import * as SC from './SalonContactsStyles'

type Props = {
	salonData?: SalonDetail
	countriesData?: ConfigResponse['rolloutCountries']
	googleMapsUrl: string
}

const SalonContacts = (props: Props) => {
	const { salonData, countriesData, googleMapsUrl } = props
	const isBasic = salonData?.createType === SALON_TYPE.BASIC

	const { messages } = useMessages()

	// google map link
	const googleMapLink = getGoogleMapsLink({ googleMapsUrl, lat: salonData?.address?.latitude, lon: salonData?.address?.longitude })

	return (
		<SC.SalonContactsContainer>
			{/* opening hours */}
			{!isBasic && salonData?.openingHoursData && (
				<SC.InfoContainer>
					{/* TODO: find out nicer way to set margin on status */}
					<OpeningHours openingHours={salonData?.openingHoursData} openingStatusMarginLeft={34} />
				</SC.InfoContainer>
			)}

			{/* address */}
			{salonData?.formattedAddress && (
				<SC.InfoContainer>
					<SC.TitleContainer>
						<MapPinIcon />
						<SC.Title>{messages?.['Where to find us']}</SC.Title>
					</SC.TitleContainer>
					<SC.AddressContainer>
						<SC.DataContainer>
							<span>{salonData?.formattedAddress}</span>
							{!isBasic ? (
								<>
									<SC.MapLinkDesktop href='#google-map'>{messages?.['Show on map']}</SC.MapLinkDesktop>
									<SC.LinkButtonMobile href='#google-map'>
										<MapIcon />
										<span>{messages?.Map}</span>
									</SC.LinkButtonMobile>
								</>
							) : (
								googleMapLink && (
									<SC.BasicSalonLinkWrapper>
										<SC.BasicSalonLink href={googleMapLink} target='_blank' rel='noreferrer'>
											{messages?.['Show on map']}
										</SC.BasicSalonLink>
									</SC.BasicSalonLinkWrapper>
								)
							)}
						</SC.DataContainer>
					</SC.AddressContainer>
				</SC.InfoContainer>
			)}

			{/* contact us - phone and email */}
			{(salonData?.email || salonData?.phones) && (
				<SC.InfoContainer>
					<SC.TitleContainer>
						<PhoneIcon />
						<SC.Title>{messages?.['Contact us']}</SC.Title>
					</SC.TitleContainer>
					<SC.DataContainer>
						{salonData?.email && (
							<SC.TitleContainer>
								<Tooltip text={salonData?.email} icon={<MailIcon />}>
									<SC.ContactInfoEmail>{salonData?.email}</SC.ContactInfoEmail>
								</Tooltip>
							</SC.TitleContainer>
						)}
						{salonData?.phones && salonData?.phones.length > 0 && (
							<div>
								{salonData.phones.map((phone, index) => (
									<div key={phone.id}>
										<span>{getPhoneNumber(phone, countriesData)}</span>
										{index === salonData.phones.length - 1 && (
											<SC.LinkButtonMobile href={`tel:${getPhoneNumber(phone, countriesData)}`}>
												<PhoneIcon />
												<span>{messages?.Call}</span>
											</SC.LinkButtonMobile>
										)}
									</div>
								))}
							</div>
						)}
					</SC.DataContainer>
				</SC.InfoContainer>
			)}

			{/* parking */}
			{!isBasic && salonData?.parkingNote && (
				<SC.InfoContainer>
					<SC.TitleContainer>
						<ParkingIcon />
						<SC.Title>{messages?.Parking}</SC.Title>
					</SC.TitleContainer>
					<SC.DataContainer>{salonData?.parkingNote}</SC.DataContainer>
				</SC.InfoContainer>
			)}

			{/* payment */}
			{!isBasic && (salonData?.payByCard || salonData?.payByCash || salonData?.otherPaymentMethods) && (
				<SC.InfoContainer>
					<SC.TitleContainer>
						<CreditCardIcon />
						<SC.Title>{messages?.['Payment information']}</SC.Title>
					</SC.TitleContainer>
					<SC.DataContainer>
						<PaymentDetails salonData={salonData} />
					</SC.DataContainer>
				</SC.InfoContainer>
			)}
		</SC.SalonContactsContainer>
	)
}

export default SalonContacts
