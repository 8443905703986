/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import DefaultContainer from '../../components/DefaultContainer/DefaultContainer'
import { MAX_WIDTHS, PAGE_TOP_PADDING, SALON_PAGE_MOBILE_BREAKPOINT } from '../../styles/constants'
import { screenReaderOnly } from '../../styles/helpers'

export const SeoTitle = styled.h1`
	${screenReaderOnly};
`

const Container = styled(DefaultContainer)`
	max-width: ${MAX_WIDTHS.salonPage};
	overflow: hidden;
`

export const PageContainer = styled(Container)`
	position: relative;
	padding-top: ${PAGE_TOP_PADDING}px;

	margin-bottom: 106px;

	@media (max-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-bottom: 53px;
	}
`
