import styled, { css } from 'styled-components'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

export const SidebarFooterWrapper = styled.div<{ $isVisible?: boolean }>`
	max-height: ${({ $isVisible }) => ($isVisible ? 'auto' : 0)};
	overflow: hidden;
`

export const FooterCtaButtonWrapper = styled.div`
	padding: 20px;
`

const footerBorders = css`
	position: relative;

	// border-top
	&::before {
		content: '';
		position: absolute;
		height: 1px;
		left: 0;
		right: 0;
		top: 0;
		background-color: #f5f5f5;
	}

	// border-bottom
	&::after {
		content: '';
		position: absolute;
		height: 1px;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #f5f5f5;
	}
`

export const FooterSlider = styled(OverlayScrollbarsComponent)`
	overflow-x: auto;
	${footerBorders};
`

export const FooterSliderContent = styled.div`
	display: flex;
	gap: 0.5rem;
	padding: 12px 20px;

	& > *:last-child {
		margin-right: 20px;
	}
`

export const FooterExtraContent = styled.div`
	padding: 12px 20px;
	${footerBorders};
`
