import React from 'react'
import { Colors, PinterestIcon, TiktokIcon, YoutubeIcon } from '@notinosro/react-styleguide'
import { SalonDetail } from '../../../../types/types'
import GlobeIcon from '../../../../assets/icons/GlobeIcon'
import FacebookIcon from '../../../../assets/icons/FacebookIcon'
import InstagramIcon from '../../../../assets/icons/InstagramIcon'
import { SOCIAL_LINK_VARIANT } from './SocialIconsStyles'
import * as SC from './SocialIconsStyles'

type Props = {
	salonData: SalonDetail
	variant?: SOCIAL_LINK_VARIANT
}

const SocialIcons = ({ salonData, variant = SOCIAL_LINK_VARIANT.LIGHT }: Props) => {
	return (
		<SC.Container>
			{salonData?.socialLinkWebPage && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData?.socialLinkWebPage} rel='noreferrer'>
					<GlobeIcon />
				</SC.SocialLink>
			)}
			{salonData?.socialLinkFB && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData?.socialLinkFB} rel='noreferrer'>
					<FacebookIcon />
				</SC.SocialLink>
			)}
			{salonData?.socialLinkInstagram && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData?.socialLinkInstagram} rel='noreferrer'>
					<InstagramIcon />
				</SC.SocialLink>
			)}
			{salonData?.socialLinkPinterest && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData?.socialLinkPinterest} rel='noreferrer'>
					<PinterestIcon color={Colors.basicInverse} />
				</SC.SocialLink>
			)}
			{salonData?.socialLinkYoutube && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData?.socialLinkYoutube} rel='noreferrer'>
					<YoutubeIcon color={Colors.basicInverse} />
				</SC.SocialLink>
			)}
			{salonData?.socialLinkTikTok && (
				<SC.SocialLink $variant={variant} target='_blank' href={salonData?.socialLinkTikTok} rel='noreferrer'>
					<TiktokIcon color={Colors.basicInverse} />
				</SC.SocialLink>
			)}
		</SC.Container>
	)
}

export default SocialIcons
