import React, { ReactElement, ReactNode } from 'react'
import RcTooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import * as SC from './TooltipStyles'

type Props = {
	text: string
	icon: ReactNode
	children: ReactElement
}

const Tooltip = ({ text, icon, children }: Props) => {
	return (
		<RcTooltip
			placement='bottom'
			trigger={['click', 'hover', 'focus']}
			overlay={
				<SC.TooltipContent>
					{icon}
					<span>{text}</span>
				</SC.TooltipContent>
			}
			showArrow={false}
			overlayInnerStyle={{ background: 'white' }}
		>
			{children}
		</RcTooltip>
	)
}

export default Tooltip
