import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { HttpStatus, Redirect } from '@notinosro/react-toolkit/renderer/fragment/contexts/HttpContext'
import { AvailableLanguagesType, ContextProps } from '../types/types'
import Paths from './paths'

// components
import SalonRoute from './SalonRoute'
import SalonsRoute from './SalonsRoute'
import { AVAILABLE_LANGUAGES } from '../appDefaults'

const AppRoutes: React.FC<React.PropsWithChildren<ContextProps>> = (props) => {
	const { locale } = useIntl() as { locale: AvailableLanguagesType }

	// if header included unsupported language, return 404
	if (!AVAILABLE_LANGUAGES.includes(locale)) return <HttpStatus status={404}>{null}</HttpStatus>

	return (
		<Routes>
			{/* redirect */}
			<Route path='/' element={<Redirect to={Paths[locale]['/salons']} />} />

			{/* salons list */}
			<Route path={Paths[locale]['/salons']} element={<SalonsRoute {...props} />} />
			<Route path={Paths[locale]['/salons/services/:categorySlug']} element={<SalonsRoute {...props} />} />

			{/* salon detail */}
			<Route path={Paths[locale]['/salons/:salonSlug']} element={<SalonRoute {...props} />} />
		</Routes>
	)
}

export default AppRoutes
