import React from 'react'

const ScissorsIcon = () => {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6 21C7.65685 21 9 19.6569 9 18C9 16.3431 7.65685 15 6 15C4.34315 15 3 16.3431 3 18C3 19.6569 4.34315 21 6 21Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M20.0001 4L8.12012 15.88' stroke='currentColor' strokeLinecap='square' strokeLinejoin='round' />
			<path d='M14.4697 14.48L19.9997 20' stroke='currentColor' strokeLinecap='square' strokeLinejoin='round' />
			<path d='M8.12012 8.12012L12.0001 12.0001' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	)
}

export default ScissorsIcon
