import React from 'react'

// components
import Input from '../../atoms/Input/Input'

// types
import { InputFormFieldProps } from './types'

// component
const InputFormField = (props: InputFormFieldProps) => {
	const {
		input: { value, onChange, onBlur, onFocus },
		...restProps
	} = props

	return <Input value={value} onChange={(e) => onChange(e.target.value)} onBlur={onBlur} onFocus={onFocus} {...restProps} />
}

export default React.memo(InputFormField)
