import React from 'react'
import { Colors, Spinner } from '@notinosro/react-styleguide'

// hooks
import useMessages from '../../../../hooks/useMessages'

// utils
import { SCROLLBAR_OPTIONS } from '../../../../utils/helper'

// styles
import * as SC from './FilterSidebarFooterStyles'
import Button from '../../../../atoms/Button/Button'
import { FORM } from '../../../../utils/enums'

// types
type SidebarFooterProps = {
	isVisible?: boolean
	extraContent?: React.ReactNode
	isSlider?: boolean
	label?: React.ReactNode
	loading?: boolean
	formId: FORM
}

// component
const SidebarFooter = (props: SidebarFooterProps) => {
	const { isVisible, extraContent, isSlider = true, label, loading, formId } = props
	const { messages } = useMessages()

	return (
		<SC.SidebarFooterWrapper $isVisible={isVisible}>
			{extraContent && isSlider && (
				<SC.FooterSlider options={SCROLLBAR_OPTIONS}>
					<SC.FooterSliderContent>{extraContent}</SC.FooterSliderContent>
				</SC.FooterSlider>
			)}
			{extraContent && !isSlider && <SC.FooterExtraContent>{extraContent}</SC.FooterExtraContent>}
			<SC.FooterCtaButtonWrapper>
				<Button form={formId} fullWidth disabled={loading || !isVisible} type={'submit'}>
					{loading ? <Spinner size={16} color={Colors.neutral} /> : label || messages?.Apply}
				</Button>
			</SC.FooterCtaButtonWrapper>
		</SC.SidebarFooterWrapper>
	)
}

export default SidebarFooter
