import React from 'react'

const FilterIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
			<path
				d='M13.0391 3.54785C13.1057 3.77619 13.141 4.01251 13.1441 4.25035C13.1413 4.49067 13.1059 4.72951 13.0391 4.96035H15.7466V3.54785H13.0391Z'
				fill='currentColor'
			/>
			<path
				d='M8.99993 1.59494C8.41954 1.59666 7.85571 1.78852 7.39472 2.14114C6.93374 2.49377 6.601 2.98773 6.44743 3.54744H0.0549316V4.96494H6.45243C6.58528 5.44226 6.84891 5.87294 7.21358 6.20836C7.57824 6.54377 8.02942 6.77056 8.51616 6.86312C9.0029 6.95568 9.50582 6.91033 9.96815 6.73218C10.4305 6.55404 10.8338 6.25019 11.1326 5.85495C11.4314 5.45971 11.6137 4.98881 11.659 4.49542C11.7043 4.00204 11.6108 3.50581 11.389 3.06276C11.1672 2.61972 10.8259 2.2475 10.4038 1.98813C9.98164 1.72876 9.49539 1.59257 8.99993 1.59494ZM8.99993 5.49994C8.7527 5.49994 8.51103 5.42663 8.30547 5.28928C8.09991 5.15192 7.93969 4.9567 7.84508 4.72829C7.75047 4.49989 7.72572 4.24855 7.77395 4.00608C7.82218 3.7636 7.94123 3.54087 8.11605 3.36606C8.29086 3.19124 8.51359 3.07219 8.75607 3.02396C8.99854 2.97573 9.24988 3.00048 9.47829 3.09509C9.70669 3.1897 9.90192 3.34992 10.0393 3.55548C10.1766 3.76104 10.2499 4.00271 10.2499 4.24994C10.2499 4.41409 10.2176 4.57664 10.1548 4.72829C10.092 4.87995 9.99989 5.01775 9.88381 5.13382C9.76774 5.2499 9.62994 5.34197 9.47829 5.40479C9.32663 5.46761 9.16408 5.49994 8.99993 5.49994Z'
				fill='currentColor'
			/>
			<path d='M8.11719 11.0674C8.25716 11.5295 8.25716 12.0227 8.11719 12.4849H15.7522V11.0674H8.11719Z' fill='currentColor' />
			<path
				d='M4.07743 9.11506C3.49725 9.11761 2.93382 9.30978 2.473 9.66228C2.01218 10.0148 1.67924 10.5083 1.52493 11.0676H0.0549316V12.4851H1.52493C1.65772 12.9622 1.92118 13.3927 2.28561 13.7281C2.65004 14.0634 3.10093 14.2903 3.58742 14.383C4.07391 14.4758 4.57664 14.4307 5.03889 14.253C5.50115 14.0753 5.90454 13.7719 6.20357 13.3771C6.50259 12.9823 6.68534 12.5118 6.73121 12.0187C6.77708 11.5256 6.68424 11.0294 6.46316 10.5863C6.24208 10.1431 5.90157 9.77052 5.48002 9.51056C5.05848 9.25061 4.57268 9.11363 4.07743 9.11506ZM4.07743 13.0201C3.8302 13.0201 3.58853 12.9468 3.38297 12.8094C3.17741 12.672 3.01719 12.4768 2.92258 12.2484C2.82797 12.02 2.80322 11.7687 2.85145 11.5262C2.89968 11.2837 3.01873 11.061 3.19355 10.8862C3.36836 10.7114 3.59109 10.5923 3.83357 10.5441C4.07605 10.4959 4.32738 10.5206 4.55579 10.6152C4.78419 10.7098 4.97942 10.87 5.11677 11.0756C5.25412 11.2812 5.32743 11.5228 5.32743 11.7701C5.32743 12.1016 5.19574 12.4195 4.96132 12.6539C4.72689 12.8884 4.40895 13.0201 4.07743 13.0201Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export default FilterIcon
