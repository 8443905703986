export const DEFAULT_SHOP_ID = 'notino.cz'
export const DEFAULT_LANG_TAG = 'cs-CZ'
export const DEFAULT_LOCALE = 'cs'

// NOTE: when adding new language, add it to AVAILABLE_LANGUAGES and shopsConfig
export const AVAILABLE_LANGUAGES = ['cs', 'sk', 'bg', 'hu', 'ro'] as const

export const shopsConfig = [
	{ url: `https://www.notino.cz`, locale: DEFAULT_LOCALE, lang: DEFAULT_LANG_TAG },
	{ url: `https://www.notino.sk`, locale: 'sk', lang: 'sk-SK' },
	{ url: `https://www.notino.bg`, locale: 'bg', lang: 'bg-BG' },
	{ url: `https://www.notino.hu`, locale: 'hu', lang: 'hu-HU' },
	{ url: `https://www.notino.ro`, locale: 'ro', lang: 'ro-RO' }
]
