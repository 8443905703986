/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components'
import { NotinoLabelRegular500 } from '../../styles/helpers'

export const Button = styled.button<{ $fullWidth?: boolean }>`
	outline: 2px solid transparent;
	border: none;
	border-radius: 2px;
	${NotinoLabelRegular500};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 12px 24px;
	transition: all 0.3s ease;
	min-height: 48px;

	&:not(:disabled) {
		cursor: pointer;
		background: #000;
		color: #fff;

		&:hover {
			background: #404040;
		}

		&:focus-visible {
			outline-color: #000;
		}
	}

	&:disabled {
		cursor: not-allowed;
		background: #f7f7f7;
		color: #ababab;
	}

	${({ $fullWidth }) =>
		$fullWidth &&
		css`
			width: 100%;
			display: flex;
		`}
`
