import React from 'react'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'

// components
import SalonPage from '../pages/SalonPage/SalonPage'

// types
import { ContextProps } from '../types/types'

type SalonRouteParams = {
	salonSlug: string
}

const SalonRoute: React.FC<React.PropsWithChildren<ContextProps>> = (props) => {
	const { salonSlug } = useParams<SalonRouteParams>()
	const [query] = useQueryParams({ categoryID: StringParam, categoryName: StringParam })

	// TODO: check if we can get rid of ts-ignore
	// eslint-disable-next-line
	// @ts-ignore
	return <SalonPage {...props} salonSlug={salonSlug} query={query} />
}

export default SalonRoute
