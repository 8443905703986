import React from 'react'

// types
import { SwitchChangeEventHandler } from 'rc-switch'
import { SwitchProps } from './types'

// styles
import * as SC from './SwitchStyles'

const Switch = (props: SwitchProps) => {
	const { onChange, onAfterChange, ...restProps } = props
	const handleChange: SwitchChangeEventHandler = (checked: boolean, event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
		if (onChange) {
			onChange(checked, event)
		}
		if (onAfterChange) {
			onAfterChange(checked, event)
		}
	}

	return <SC.Switch {...restProps} onChange={handleChange} />
}

export default Switch
