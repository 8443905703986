/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components'
import RcInput from 'rc-input'

// styles
import { NotinoLabelRegular400 } from '../../styles/helpers'

const commonInputStyles = css<{ $isPristine?: boolean }>`
	padding: 0.75rem 1rem;
	width: 100%;
	overflow: hidden;
	border: 1px solid #ababab;
	border-radius: 2px;
	display: inline-flex;
	gap: 0.625rem;
	outline: 2px solid transparent;
	outline-offset: -2px;
	transition:
		border 0.3s ease,
		outline 0.3s ease;
	${NotinoLabelRegular400};
	color: ${({ $isPristine }) => ($isPristine ? '#6B6B6B' : '#000')};
`

const hoverCommonInputStyles = css`
	&:hover {
		border-color: #000;
	}

	&:focus-within {
		border-color: #000;
		outline-color: #000;
	}
`

const disabledCommonInputStyles = css`
	background-color: #f5f5f5;
	border-color: #e6e6e6;
	color: #ababab;
`

export const Input = styled(RcInput)<{ $isPristine?: boolean; $isSearch?: boolean }>`
	// normal input styles
	&.rc-input {
		${commonInputStyles}

		&:not(:disabled) {
			${hoverCommonInputStyles};
		}

		&:disabled {
			${disabledCommonInputStyles};
		}
	}

	// affix input styles
	&.rc-input-affix-wrapper {
		${commonInputStyles}

		&:not(.rc-input-affix-wrapper-disabled) {
			${hoverCommonInputStyles};
		}

		input {
			${NotinoLabelRegular400};
			display: block;
			width: 100%;
			padding: 0;
			border: none;
			outline: none;
			color: ${({ $isPristine }) => ($isPristine ? '#6B6B6B' : '#000')};
		}

		.rc-input-suffix {
			flex: 0 0 auto;
			color: #6b6b6b;
			${NotinoLabelRegular400};

			svg {
				color: #6b6b6b;
				display: inline-block;
				width: 1rem;
				height: auto;
			}

			.rc-input-clear-icon {
				cursor: pointer;
			}
		}

		${({ $isSearch }) =>
			$isSearch &&
			css`
				background-color: #f5f5f5;
				border: none;

				input {
					background-color: #f5f5f5;
				}
			`}

		&.rc-input-affix-wrapper-disabled {
			${disabledCommonInputStyles}

			input {
				color: #ababab;
			}

			.rc-input-suffix {
				color: #ababab;

				svg {
					color: #ababab;
				}
			}
		}
	}
`
