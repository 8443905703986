/* eslint-disable global-require */
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { GoogleMap as GoogleMapsApi, GoogleMapProps, Marker, useJsApiLoader, MarkerProps } from '@react-google-maps/api'

// utils
import { Colors, Spinner } from '@notinosro/react-styleguide'
import { MAP } from '../../utils/enums'

// styled
import * as SC from './GoogleMapsStyles'

// types
export type MapMarker = MarkerProps & {
	id: string
	extra: object
}

export type GoogleMapPosition = google.maps.LatLng | google.maps.LatLngLiteral

type Props = GoogleMapProps & {
	assetsPath: string
	googleMapsApiKey: string
	showOneMarker?: boolean
	center: GoogleMapPosition // center is present in GoogleMapProps as possibly undefined, we want it as required
	markers?: MapMarker[]
	onMarkerClick?: (marker: MapMarker) => void
	disabled?: boolean
	markersLoading?: boolean
}

type Handle = {
	resetCurrentMarker: () => void
}

// component
const GoogleMaps = forwardRef<Handle, Props>((props, ref) => {
	const { markersLoading, children, showOneMarker, center, markers, onMarkerClick, assetsPath, googleMapsApiKey, onBoundsChanged, onLoad, onClick } = props
	const { isLoaded: isMapLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey })
	// NOTE: when one marker is used (showOneMarker) there is no fetching of marker positions from backend -> loading state is determined only by map loading (isMapLoaded);
	// on the other hand when multiple markers are used, they are fetched from backend and loading state is determined by markersLoading as well
	const isLoading = showOneMarker ? !isMapLoaded : !isMapLoaded || markersLoading

	const [currentMarker, setCurrentMarker] = useState<MapMarker | null>(null)

	const handleClick = (e: google.maps.MapMouseEvent) => {
		setCurrentMarker(null)
		if (onClick) onClick(e)
	}

	useImperativeHandle(ref, () => ({
		resetCurrentMarker() {
			setCurrentMarker(null)
		}
	}))

	return (
		<SC.MapContainer>
			{isMapLoaded && (
				<GoogleMapsApi
					center={center}
					mapContainerStyle={{ position: 'absolute', width: '100%', height: '100%' }}
					zoom={MAP.defaultZoom}
					options={{
						maxZoom: MAP.maxZoom,
						minZoom: MAP.minZoom,
						streetViewControl: false,
						zoomControl: false,
						fullscreenControl: false,
						mapTypeControl: false
					}}
					onBoundsChanged={onBoundsChanged}
					onLoad={onLoad}
					clickableIcons={false} // don't allow click on google maps default pins (e.g. pins for restaruants, places, ...)
					onClick={handleClick}
				>
					{/* one marker  */}
					{showOneMarker && (
						// eslint-disable-next-line global-require
						<Marker position={center} icon={`${assetsPath}/${require('../../assets/icons/location-pin-icon.svg')}`} />
					)}

					{/* multiple markers */}
					{!showOneMarker &&
						markers &&
						markers.map((marker, index) => {
							const icon =
								marker.id === currentMarker?.id
									? `${assetsPath}/${require('../../assets/icons/location-pin-icon-pink.png')}`
									: `${assetsPath}/${require('../../assets/icons/location-pin-icon-black.png')}`
							return (
								<Marker
									key={index}
									position={marker.position}
									icon={icon}
									onClick={() => {
										if (onMarkerClick) onMarkerClick(marker)
										setCurrentMarker(marker)
									}}
								/>
							)
						})}

					{/* additional map elements */}
					{children}
				</GoogleMapsApi>
			)}
			{isLoading && (
				<SC.SpinnerContainer>
					<Spinner color={Colors.primary} size={24} />
				</SC.SpinnerContainer>
			)}
		</SC.MapContainer>
	)
})

export default GoogleMaps
