import React from 'react'

const CreditCardIcon = () => {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_14268_122226)'>
				<path
					d='M18 5H6C4.34315 5 3 6.34315 3 8V16C3 17.6569 4.34315 19 6 19H18C19.6569 19 21 17.6569 21 16V8C21 6.34315 19.6569 5 18 5Z'
					stroke='black'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path d='M3 10H21' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
				<path d='M7.00049 15H7.01174' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
				<path d='M10.9995 15H12.9995' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
			</g>
			<defs>
				<clipPath id='clip0_14268_122226'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default CreditCardIcon
