import styled from 'styled-components'
import RcCollapse from 'rc-collapse'
import ExpandIcon from '../../../../assets/icons/ExpandIcon'
import { truncate } from '../../../../styles/helpers'

export const FilterCollapse = styled(RcCollapse)`
	.rc-collapse-motion {
		transition:
			height 0.3s,
			opacity 0.3s;
	}
	.rc-collapse-content-hidden {
		display: none;
	}

	background-color: #fff;

	& > .rc-collapse-item {
		border-bottom: 1px solid #e1e1e1;

		.rc-collapse-header {
			display: flex;
			align-items: center;
			font-size: 16px;
			line-height: 20px;
			color: #000;
			cursor: pointer;
			gap: 8px;
			height: 48px;
			margin: 10px 20px;
			background: #fff;
			outline-offset: 3px;
		}

		&:not(.collapse-item-custom-header) {
			.rc-collapse-header {
				.rc-collapse-header-text {
					order: 0;
					margin-right: auto;
					font-weight: 500;
					${truncate};
				}

				.rc-collapse-extra {
					order: 1;
				}

				.rc-collapse-expand-icon {
					color: #6b6b6b;
					order: 2;
					transform-origin: center;
				}
			}

			&:not(.rc-collapse-item-disabled) {
				.rc-collapse-header {
					&:hover {
						.rc-collapse-header-text {
							text-decoration: underline;
						}
					}
				}
			}

			&.rc-collapse-item-active {
				&:not(.collapse-item-not-collapsible) {
					.rc-collapse-header {
						.rc-collapse-expand-icon {
							transform: rotate(180deg);
						}
					}
				}

				/* &:last-child {
					border-bottom-color: transparent;
				} */
			}

			&.collapse-item-not-collapsible {
				.rc-collapse-header {
					.rc-collapse-expand-icon {
						transform: rotate(270deg);
					}
				}
			}
		}

		&.rc-collapse-item-disabled {
			.rc-collapse-header {
				cursor: default;
			}
		}

		&.collapse-item-custom-header {
			.rc-collapse-header {
				.rc-collapse-header-text {
					display: block;
					flex: 1;
					height: 100%;
					width: 100%;
				}

				.rc-collapse-expand-icon,
				.rc-collapse-extra {
					display: none;
				}
			}
		}
	}

	.rc-collapse-content {
		overflow: hidden;
		padding: 0 20px;
	}
`

export const CollapseExpandIcon = styled(ExpandIcon)`
	color: #6b6b6b;
`
