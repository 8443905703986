import React from 'react'
import { CheckIcon, CloseIcon, Colors } from '@notinosro/react-styleguide'
import { SalonDetail } from '../../../../types/types'
import useMessages from '../../../../hooks/useMessages'
import * as SC from './PaymentDetailsStyles'

const Icon = ({ paymentAvailable }: { paymentAvailable: boolean | undefined }) => {
	return paymentAvailable ? <CheckIcon color={Colors.basic} /> : <CloseIcon color={Colors.neutral} width='20' height='20' style={{ marginRight: 2 }} />
}

type Props = {
	salonData: SalonDetail
}

const PaymentDetails = (props: Props) => {
	const { messages } = useMessages()
	const { salonData } = props

	return (
		<div>
			<SC.PaymentMethod>
				<SC.PaymentMethodLabel>{messages?.['Cash payment']}</SC.PaymentMethodLabel>
				<Icon paymentAvailable={salonData?.payByCash} />
			</SC.PaymentMethod>
			<SC.PaymentMethod>
				<SC.PaymentMethodLabel>{messages?.['Card payment']}</SC.PaymentMethodLabel>
				<Icon paymentAvailable={salonData?.payByCard} />
			</SC.PaymentMethod>
			{salonData?.otherPaymentMethods && (
				<>
					<SC.PaymentMethodLabel>{messages?.['Accepted coupons']}</SC.PaymentMethodLabel>
					<SC.PaymentsInfoContainer>{salonData?.otherPaymentMethods}</SC.PaymentsInfoContainer>
				</>
			)}
		</div>
	)
}

export default PaymentDetails
