/* eslint-disable import/prefer-default-export */

import { IDropdownOption } from '@notinosro/react-styleguide'
import { SALONS_PER_PAGE } from '../api/Salons'
import { Salon, SalonDetail, AppStoreType } from '../types/types'
import { SORTING_OPTION } from './enums'

export const getPageViewEvent = (type: 'salon' | 'salons') => ({
	event: 'page_view',
	page: {
		path: window.location.pathname,
		title: document.title,
		type,
		unified_name: 'salons'
	},
	_clear: true
})

export const getToggleMapEvent = (isMapView: boolean) => ({
	event: 'element_click',
	element: {
		name: isMapView ? 'show_in_list' : 'show_on_map',
		action: 'click_on_element',
		interaction: 'click',
		type: 'salons'
	},
	_clear: true
})

export const getToggleOpeningHoursEvent = (checked: boolean) => ({
	event: 'filter_set',
	filter: {
		name: 'opening_hours',
		action: 'filtration_used',
		interaction: checked ? 'click_add' : 'click_remove',
		type: 'opening_hours'
	},
	_clear: true
})

export const getSubcategoryChangeEvent = (params: {
	clickedCategorySlug: string
	clickedCategoryID: string
	eventInteraction: 'click_add' | 'click_remove'
}) => {
	const { clickedCategorySlug, clickedCategoryID, eventInteraction } = params
	return {
		event: 'filter_set',
		filter: {
			name: clickedCategorySlug,
			id: clickedCategoryID,
			action: 'filtration_used',
			interaction: eventInteraction,
			type: 'salon_category'
		},
		_clear: true
	}
}

export const getTopLevelCategoryChangeEvent = (params: { categorySlug: string; categoryID: string }) => {
	const { categorySlug, categoryID } = params
	return {
		event: 'filter_set',
		filter: {
			name: categorySlug,
			id: categoryID,
			action: 'filtration_used',
			interaction: 'click',
			type: 'salon_fields'
		},
		_clear: true
	}
}

export const getRatingsChangeEvent = (params: { checked: boolean; rating: number }) => {
	const { checked, rating } = params
	return {
		event: 'filter_set',
		filter: {
			name: `rating_${rating}`,
			action: 'filtration_used',
			interaction: checked ? 'click_add' : 'click_remove',
			type: 'rating'
		},
		_clear: true
	}
}

export const getBreadcrumbClickEvent = (params: { isMobile: boolean; name: string; pathQuery: string; levelIndex: number; text: string[] }) => {
	const { isMobile, name, pathQuery, levelIndex, text } = params
	return {
		event: 'breadcrumbs_click',
		breadcrumbs: {
			variant: isMobile ? 'mobile' : 'desktop',
			name,
			path_query: pathQuery,
			type: `level_${levelIndex}`,
			interaction: 'click',
			action: 'breadcrumbs_used',
			text
		},
		_clear: true
	}
}

export const getSearchFocusedEvent = (params: { isMobile: boolean }) => {
	const { isMobile } = params
	return {
		event: 'search_open',
		search: {
			interaction: 'click',
			action: 'click_to_search_field',
			variant: isMobile ? 'mobile' : 'desktop',
			type: 'salons'
		},
		_clear: true
	}
}

export const getPlaceSelectedEvent = (params: { inputValue: string }) => {
	const { inputValue } = params
	return {
		event: 'search',
		search: {
			term: inputValue,
			action: 'search_form_used',
			interaction: 'click',
			type: 'salons'
		},
		_clear: true
	}
}

export const getSalonSelectedFromSearchEvent = (params: { inputValue: string }) => {
	const { inputValue } = params
	return {
		event: 'search',
		search: {
			term: inputValue,
			action: 'search_form_used',
			interaction: 'click_through',
			type: 'salons'
		},
		_clear: true
	}
}

export const getViewItemListEvent = (params: { currentSortingOptionName: string; currentPage: number; salons: Salon[] }) => {
	const { currentSortingOptionName, currentPage, salons } = params

	const products = salons.map((salon, index) => {
		return {
			product_code: salon.id,
			product_name: salon.name,
			rating: salon.rating || '',
			availability: salon.openingHoursData?.isOpen ? 'true' : 'false',
			kind: 'salons',
			list_position: index + 1 + SALONS_PER_PAGE * (currentPage - 1),
			list_name: 'salons_list'
		}
	})

	return {
		event: 'view_item_list',
		product_lists: {
			list_name: 'salons_list',
			sorting_type: currentSortingOptionName,
			products,
			type: 'salons'
		},
		_clear: true
	}
}

export const getMapViewItemListEvent = (salons: Salon[]) => {
	const products = salons.map((salon) => {
		return {
			product_code: salon.id,
			product_name: salon.name,
			rating: salon.rating || '',
			availability: salon.openingHoursData?.isOpen ? 'true' : 'false',
			kind: 'salons',
			list_name: 'salons_list'
		}
	})

	return {
		event: 'view_item_list',
		product_lists: {
			list_name: 'salons_map',
			products,
			type: 'salons'
		},
		_clear: true
	}
}

export const getSelectItemEvent = (params: { salon: Salon; listPosition?: number }) => {
	const { salon, listPosition } = params
	return {
		event: 'select_item',
		product: [
			{
				product_code: salon.id,
				product_name: salon.name,
				rating: salon.rating || '',
				availability: salon.openingHoursData?.isOpen ? 'true' : 'false',
				kind: 'salons',
				list_position: listPosition || '', // when clicking on item in map, list position does not make sense
				list_name: 'salons_list'
			}
		],
		_clear: true
	}
}

export const getSortingSetEvent = (params: { option: IDropdownOption }) => {
	const { option } = params
	return {
		event: 'sorting_set',
		sorting: {
			name: (option.id as SORTING_OPTION).toLowerCase(),
			type: 'salons'
		},
		_clear: true
	}
}

export const getViewItemEvent = (params: { salon: SalonDetail }) => {
	const { salon } = params
	return {
		event: 'view_item',
		products: [
			{
				product_code: salon?.id,
				product_name: salon?.name,
				rating: salon?.ratingsData?.rating || '',
				availability: salon?.openingHoursData?.isOpen ? 'true' : 'false',
				kind: 'salons'
			}
		],
		_clear: true
	}
}

export const getListPaginatingEvent = (params: { page: number }) => {
	const { page } = params
	return {
		event: 'element_click',
		element: {
			name: 'list_pagination',
			action: 'click_on_element',
			interaction: `paging_${page}`,
			type: 'salons'
		},
		_clear: true
	}
}

export const getMobileAppClickEvent = (params: { storeType: AppStoreType }) => {
	const { storeType } = params
	return {
		event: 'mobile_app_click',
		app: {
			name: 'salon',
			type: storeType,
			position: 'reservation_modal'
		},
		_clear: true
	}
}

export const getAddToCartEvent = (salon: SalonDetail) => {
	return {
		event: 'add_to_cart',
		product: {
			product_code: salon.id,
			product_name: salon.name,
			rating: salon.ratingsData.rating || '',
			availability: salon.openingHoursData?.isOpen ? 'true' : 'false',
			kind: 'salons',
			quantity: 1,
			cart_type: 'salon'
		},
		_clear: true
	}
}
