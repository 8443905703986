import { theme } from '@notinosro/react-styleguide'
import 'rc-tooltip/assets/bootstrap.css'
import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const TooltipContent = styled.div`
	${theme.typography.bodyLarge};
	color: black;
	display: flex;
	align-items: center;
	gap: 8px;
	padding-top: 4px;
	padding-bottom: 4px;
`
