import React from 'react'

const CheckCircleIcon = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.6685 7.72549V8.3389C14.6676 9.77669 14.2021 11.1757 13.3412 12.3273C12.4803 13.4788 11.2702 14.3213 9.89145 14.7289C8.51266 15.1366 7.03903 15.0877 5.69034 14.5894C4.34165 14.0911 3.19016 13.1702 2.4076 11.9641C1.62504 10.7579 1.25335 9.33107 1.34795 7.89639C1.44255 6.46171 1.99838 5.09606 2.93254 4.00309C3.86671 2.91012 5.12915 2.1484 6.53159 1.83154C7.93403 1.51467 9.40133 1.65964 10.7146 2.24482'
				stroke='white'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M14.6685 3.00488L8.00098 9.67903L6.00073 7.67879' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	)
}

export default CheckCircleIcon
