import { theme } from '@notinosro/react-styleguide'
import styled from 'styled-components'
import { BREAKPOINTS } from '../../../../styles/constants'

const ICON_SIZE = 24
const TITLE_LEFT_MARGIN = 12

export const SalonDetailCardContainer = styled.div`
	display: flex;
	box-shadow: 0px 15px 60px 0px rgba(0, 0, 0, 0.1);

	@media (max-width: ${BREAKPOINTS.lg}) {
		flex-direction: column;
	}
`

export const SocialIconsContainer = styled.div`
	margin-top: 20px;
`

export const SalonCardContactInfo = styled.div`
	width: 50%;
	background: ${theme.palette.basic};
	padding: 60px;
	color: ${theme.palette.basicInverse};

	@media (max-width: ${BREAKPOINTS.lg}) {
		width: 100%;
		padding: 20px;
	}
`

export const ParkingNote = styled.div`
	${theme.typography.bodySmall}
`

export const SalonCardTitle = styled.div`
	${theme.typography.titleDisplay}
	margin-bottom: 34px;

	@media (max-width: ${BREAKPOINTS.md}) {
		margin-bottom: 16px;
	}
`

export const InfoCardBox = styled.div`
	display: flex;
	align-items: flex-start;
	${theme.typography.bodyLarge}
	padding-top: 10px;
	padding-bottom: 10px;
	flex-wrap: wrap;
`

export const IconBox = styled.div`
	margin-top: 2px;
	width: 24px;
	height: 24px;

	& > svg {
		width: 100%;
		height: 100%;

		& path {
			stroke: ${theme.palette.basicInverse};
		}
	}
`

export const InfoCardBoxTitle = styled.div`
	margin-left: ${TITLE_LEFT_MARGIN}px;
	word-break: break-all;
	font-weight: 500;
`

export const ServicesContainer = styled.div`
	margin-left: ${ICON_SIZE + TITLE_LEFT_MARGIN}px;
`

export const Service = styled.div`
	margin-bottom: 8px;
`

export const GoogleMapsContainer = styled.div`
	/* <GoogleMap/> component needs to be absolute because of the bug in safari, so here we wrap it in relative position
        div. Ideally we would wrap google map to relative div INSIDE <GoogleMap/> but then we would need to stretch this
        relative div to full height of flex parent. This is standard behavior but it doesn't work in Safari when
        there is no <DOCTYPE html> so for now we avoid extra div.
    */
	position: relative;
	width: 50%;

	@media (max-width: ${BREAKPOINTS.lg}) {
		.google-map {
			width: 100%;
			aspect-ratio: 1;
		}
	}
`
