import { useEffect, useRef, useState } from 'react'

export default function useCheckClamping() {
	const clampedElementRef = useRef<HTMLDivElement | null>(null)
	const [isElementClamped, setIsElementClamped] = useState(false)

	function checkClamping() {
		if (!clampedElementRef.current) return

		const element = clampedElementRef.current
		if (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) {
			setIsElementClamped(true)
		} else {
			setIsElementClamped(false)
		}
	}

	useEffect(() => {
		checkClamping()

		window.addEventListener('resize', checkClamping)

		return () => {
			window.removeEventListener('resize', checkClamping)
		}
	}, [clampedElementRef])

	return { isElementClamped, clampedElementRef }
}
