import React from 'react'
import { useIntl } from 'react-intl'
import { AVAILABLE_LANGUAGES } from '../../../../appDefaults'
import useMessages from '../../../../hooks/useMessages'
import { AvailableLanguagesType, AppStoreType } from '../../../../types/types'
import * as SC from './StoreDownloadButtonsStyles'
import { getMobileAppClickEvent } from '../../../../utils/dataLayerEvents'
import { pushToDataLayer } from '../../../../utils/dataLayer'

type StoreDownloadButtonProps = {
	assetsPath: string
}

const StoreDownloadButtons = (props: StoreDownloadButtonProps) => {
	const { assetsPath } = props
	const { locale } = useIntl() as { locale: AvailableLanguagesType }
	const { messages } = useMessages()

	// images
	let appStoreImagePath
	let googlePlayImagePath
	if (AVAILABLE_LANGUAGES.includes(locale)) {
		// eslint-disable-next-line import/no-dynamic-require, global-require
		appStoreImagePath = `${assetsPath}/${require(`../../../../assets/images/badge-app-store-${locale}.png`)}`
		// eslint-disable-next-line import/no-dynamic-require, global-require
		googlePlayImagePath = `${assetsPath}/${require(`../../../../assets/images/badge-google-play-${locale}.png`)}`
	} else {
		// NOTE: using Czech version as default
		// TODO: change to English version as default
		// eslint-disable-next-line import/no-dynamic-require, global-require
		appStoreImagePath = `${assetsPath}/${require(`../../../../assets/images/badge-app-store-cs.png`)}`
		// eslint-disable-next-line import/no-dynamic-require, global-require
		googlePlayImagePath = `${assetsPath}/${require(`../../../../assets/images/badge-google-play-cs.png`)}`
	}

	// links
	const appStoreLink = messages?.['link app store']
	const googlePlayLink = messages?.['link google play']

	const handleDownloadClick = (storeType: AppStoreType) => {
		const event = getMobileAppClickEvent({ storeType })
		pushToDataLayer(event)
	}

	return (
		<SC.ButtonsWrapper>
			{/* app store */}
			<a href={appStoreLink as string} target='_blank' rel='noopener noreferrer' onClick={() => handleDownloadClick('app_store')}>
				<SC.StoreBadgeImage src={appStoreImagePath} alt='app store logo' />
			</a>

			{/* google play store */}
			<a href={googlePlayLink as string} target='_blank' rel='noopener noreferrer' onClick={() => handleDownloadClick('google_play')}>
				<SC.StoreBadgeImage src={googlePlayImagePath} alt='google play store logo' />
			</a>
		</SC.ButtonsWrapper>
	)
}
export default StoreDownloadButtons
