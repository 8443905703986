import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { useInitialData } from '@notinosro/react-toolkit/renderer/fragment/useInitialData'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { HttpStatus } from '@notinosro/react-toolkit/renderer/fragment/contexts/HttpContext'

// components
import SalonsHead from './components/SalonsHead/SalonsHead'
import SalonsView from './components/SalonsView/SalonsView'

// types
import {
	Currency,
	GetSalonsDataParams,
	CategoriesResponse,
	CategoryResponse,
	ConfigResponse,
	ContextProps,
	Cosmetic,
	IGetInitialDataProps,
	GetSalonsFilterCountResponse,
	Language,
	Salon,
	SalonsPagination,
	SalonsResponse,
	LoadSalonsFromFiltersParams,
	LoadSalonsFromTopLevelCategory,
	SalonsPageQueryType,
	SalonsPageSetQueryType
} from '../../types/types'

// styles
import * as SC from './SalonsPageStyles'

// utils
import Paths from '../../routes/paths'
import { ApiContext } from '../../utils/apiProvider'
import { formatUrlSlug, getCategoryIDs, getValidPriceRangeQueryParams, isNil } from '../../utils/helper'
import { pushToDataLayer } from '../../utils/dataLayer'
import { getPageViewEvent, getViewItemListEvent } from '../../utils/dataLayerEvents'
import { shopsConfig } from '../../appDefaults'
import SalonsPageBanner from './components/SalonsPageBanner/SalonsPageBanner'

// hooks
import useSortingOptions from '../../hooks/useSortingOptions'

// types
type ExtendedContextProps = {
	page: number
	query: SalonsPageQueryType
	setQuery: SalonsPageSetQueryType
	categorySlug: string
} & ContextProps
type ICategoryNotFound = boolean
type InitialData = [
	SalonsResponse | undefined,
	CategoriesResponse | undefined,
	CategoryResponse | undefined,
	ConfigResponse | undefined,
	Currency | undefined,
	GetSalonsFilterCountResponse | undefined,
	ICategoryNotFound
]
// page
const SalonsPage: IGetInitialDataProps<InitialData, ExtendedContextProps> = (props) => {
	const data = useInitialData(SalonsPage, props)

	const location = useLocation()
	const navigate = useNavigate()
	const { locale } = useIntl()

	const { query: rawQuery, setQuery, categorySlug, isDevMode } = props

	const salonsData = data?.[0]
	const topLevelCategoriesData = data?.[1]
	const categoryData = data?.[2]
	const configData = data?.[3]
	const currency = data?.[4]
	const filterCountsData = data?.[5]
	const categoryNotFound = data?.[6]

	const [filterCounts, setFilterCounts] = useState<GetSalonsFilterCountResponse | undefined>(filterCountsData)

	const query: SalonsPageQueryType = useMemo(
		() => ({ ...rawQuery, ...getValidPriceRangeQueryParams(rawQuery.serviceTotalPriceFrom, rawQuery.serviceTotalPriceTo, filterCounts?.priceRange) }),
		[rawQuery, filterCounts?.priceRange]
	)

	const [currentSalons, setCurrentSalons] = useState<Salon[] | undefined>(salonsData?.salons)
	const [currentPagination, setCurrentPagination] = useState<SalonsPagination | undefined>(salonsData?.pagination)
	const [areSalonsLoading, setAreSalonsLoading] = useState<boolean>(false)
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)
	const [currentSortingOption] = useSortingOptions(query)
	const [initialClientDataLoading, setInitialClientDataLoading] = useState(true)
	const [cosmetics, setCosmetics] = useState<Cosmetic[]>([])
	const [languages, setLanguages] = useState<Language[]>([])
	const mergeWithPreviousSalons = useRef(false)

	const currentCategory = topLevelCategoriesData?.categories.find((category) => category?.nameSlug === categorySlug)
	const showSubcategories = currentCategory?.children && currentCategory.children.length > 0

	// get api client for requests outside of server
	const { apiBrowser } = useContext(ApiContext)

	const refetchSalonsFromLoadMoreBtn = () => {
		setIsButtonLoading(true)

		// set mergeWithPreviousSalons flag to true so the currently showed salons can be merged with newly loaded salons
		mergeWithPreviousSalons.current = true

		setQuery({
			...query,
			page: query.page + 1
		})
	}

	const updatePathnameFromTopLevelCategory = async (params: LoadSalonsFromTopLevelCategory) => {
		const { categorySlug: newCategorySlug } = params // renaming to avoid tslint error (categorySlug is in upper scope)

		// NOTE:
		// 1. when changing top level category, reset (remove them from query) page and subcategories (categoryIDs)
		// 2. we access query parameters using "location" instead of "query" (from props),
		// because apparently "query" is not updated immediately after "setQuery" is called
		const searchParams = new URLSearchParams(location.search)
		searchParams.delete('page')
		searchParams.delete('categoryIDs')
		const search = searchParams.toString()

		// update pathname
		const pathWithCategory = `${Paths[locale as keyof typeof Paths]['/salons/services']}${formatUrlSlug(newCategorySlug)}`
		const basePath = Paths[locale as keyof typeof Paths]['/salons']
		navigate({
			pathname: newCategorySlug ? pathWithCategory : basePath,
			search
		})
	}

	const updateQueryFromFilters = async (params: LoadSalonsFromFiltersParams) => {
		const {
			openingHoursStatus: openingHoursStatusParam,
			city,
			categoryIDs: categoryIDsParam,
			orderBy: orderByParam,
			exactRating: exactRatingParam,
			languageIDs: languageIDsParam,
			cosmeticIDs: cosmeticIDsParam,
			serviceTotalPriceFrom: serviceTotalPriceFromParam,
			serviceTotalPriceTo: serviceTotalPriceToParam,
			hasAvailableReservationSystem: hasAvailableReservationSystemParam,
			avResTimeSlotDate: avResTimeSlotDateParam,
			avResTimeSlotDateFrom: avResTimeSlotDateFromParam,
			avResTimeSlotDateTo: avResTimeSlotDateToParam
		} = params

		const openingHoursStatus = openingHoursStatusParam || query.openingHoursStatus
		const categoryIDs = categoryIDsParam || query.categoryIDs
		const orderBy = orderByParam || query.orderBy
		const exactRating = exactRatingParam || query.exactRating
		const languageIDs = languageIDsParam || query.languageIDs
		const cosmeticIDs = cosmeticIDsParam || query.cosmeticIDs
		const avResTimeSlotDate = avResTimeSlotDateParam || query.avResTimeSlotDate

		// using null to purposely reset value in following params
		const latMy = city === null ? undefined : city?.latitude || query.latMy
		const lonMy = city === null ? undefined : city?.longitude || query.lonMy
		const googlePlaceID = city === null ? undefined : city?.placeID || query.googlePlaceID
		const serviceTotalPriceFrom = serviceTotalPriceFromParam === null ? undefined : (serviceTotalPriceFromParam ?? query.serviceTotalPriceFrom)
		const serviceTotalPriceTo = serviceTotalPriceToParam === null ? undefined : (serviceTotalPriceToParam ?? query.serviceTotalPriceTo)
		const avResTimeSlotDateFrom = avResTimeSlotDateFromParam === null ? undefined : avResTimeSlotDateFromParam || query.avResTimeSlotDateFrom
		const avResTimeSlotDateTo = avResTimeSlotDateToParam === null ? undefined : avResTimeSlotDateToParam || query.avResTimeSlotDateTo
		const hasAvailableReservationSystem =
			hasAvailableReservationSystemParam !== undefined ? hasAvailableReservationSystemParam : query.hasAvailableReservationSystem

		// update query
		setQuery({
			openingHoursStatus,
			page: 1,
			latMy,
			lonMy,
			categoryIDs,
			googlePlaceID,
			orderBy,
			exactRating,
			languageIDs,
			cosmeticIDs,
			serviceTotalPriceFrom,
			serviceTotalPriceTo,
			hasAvailableReservationSystem,
			avResTimeSlotDate,
			avResTimeSlotDateFrom,
			avResTimeSlotDateTo
		})
	}

	const didMountRef = useRef(false)

	useEffect(() => {
		const fetchSalonsAndFilterCounts = async () => {
			// start loading
			setAreSalonsLoading(true)

			const topLevelCategoryId = topLevelCategoriesData?.categories.find((category) => category?.nameSlug === categorySlug)?.id
			const categoryIDs = getCategoryIDs(query.categoryIDs, topLevelCategoryId)

			let commonParams: GetSalonsDataParams = {
				openingHoursStatus: query.openingHoursStatus,
				latMy: query.latMy,
				lonMy: query.lonMy,
				categoryIDs,
				exactRating: query.exactRating,
				hasAvailableReservationSystem: query.hasAvailableReservationSystem,
				languageIDs: query.languageIDs,
				cosmeticIDs: query.cosmeticIDs,
				orderBy: query.orderBy,
				avResTimeSlotDate: query.avResTimeSlotDate,
				avResTimeSlotDateFrom: query.avResTimeSlotDateFrom,
				avResTimeSlotDateTo: query.avResTimeSlotDateTo
			}

			if ((!isNil(query.serviceTotalPriceFrom) || !isNil(query.serviceTotalPriceTo)) && currency?.code) {
				commonParams = {
					...commonParams,
					serviceTotalPriceFrom: query.serviceTotalPriceFrom,
					serviceTotalPriceTo: query.serviceTotalPriceTo,
					serviceTotalPriceCurrencyCode: currency.code
				}
			}

			// get new data
			const newSalonsData = await apiBrowser.getSalonsData({ ...commonParams, page: query.page })
			// get salons filter counts
			if (currency?.code) {
				const newSalonsFilterCounts = await apiBrowser.getSalonsFilterCounts({ ...commonParams, serviceTotalPriceCurrencyCode: currency.code })
				setFilterCounts(newSalonsFilterCounts)
			} else {
				setFilterCounts(undefined)
			}

			// update state
			if (mergeWithPreviousSalons.current) {
				setCurrentSalons([...(currentSalons || []), ...(newSalonsData?.salons || [])])
			} else {
				setCurrentSalons(newSalonsData?.salons)
			}
			setCurrentPagination(newSalonsData?.pagination)

			// stop loading
			setAreSalonsLoading(false)
			setIsButtonLoading(false)
			// reset mergeWithPreviousSalons flag
			mergeWithPreviousSalons.current = false

			// push to dataLayer
			const event = getViewItemListEvent({
				currentPage: query.page || 1,
				currentSortingOptionName: `${currentSortingOption.id}`,
				salons: newSalonsData?.salons || []
			})
			pushToDataLayer(event)
		}

		// prevent fetch same data which is loaded on server
		if (didMountRef.current) {
			fetchSalonsAndFilterCounts()
		}

		didMountRef.current = true
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		query.page,
		query.openingHoursStatus,
		query.orderBy,
		query.exactRating,
		query.categoryIDs,
		query.latMy,
		query.lonMy,
		query.languageIDs,
		query.cosmeticIDs,
		query.serviceTotalPriceFrom,
		query.serviceTotalPriceTo,
		query.hasAvailableReservationSystem,
		query.avResTimeSlotDate,
		query.avResTimeSlotDateFrom,
		query.avResTimeSlotDateTo,
		categorySlug
	])

	useEffect(() => {
		;(async () => {
			try {
				const languagesData = await apiBrowser.getLanguagesData()
				const cosmeticsData = await apiBrowser.getCosmeticsData({})

				setLanguages(languagesData?.languages || [])
				setCosmetics(cosmeticsData?.cosmetics || [])
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			} finally {
				setInitialClientDataLoading(false)
			}
		})()
	}, [apiBrowser])

	// GA page view event
	useEffect(() => {
		const pageViewEvent = getPageViewEvent('salons')
		pushToDataLayer(pageViewEvent)
	}, [])

	// 404 when category in url was entered but was not found
	if (categoryNotFound) return <HttpStatus status={404}>{null}</HttpStatus>

	// NOTE: needed for react-toolkit SSR
	if (!data || !currentSalons || !currentPagination || !topLevelCategoriesData) return null

	return (
		<>
			<SalonsHead
				pagination={currentPagination}
				categoryLocalizations={categoryData?.category.nameLocalizations}
				showSubcategories={Boolean(showSubcategories)}
				categoryName={currentCategory?.name}
			/>
			<SalonsPageBanner assetsPath={props.assetsPath} show={!showSubcategories} isDevMode={isDevMode} />
			<SC.Container>
				<SC.SalonsPageWrapper>
					<SalonsView
						salons={currentSalons}
						pagination={currentPagination}
						loadSalonsFromLoadMoreButton={refetchSalonsFromLoadMoreBtn}
						loadSalonsFromFilters={updateQueryFromFilters}
						loadSalonsFromTopLevelCategory={updatePathnameFromTopLevelCategory}
						salonsLoading={areSalonsLoading}
						buttonLoading={isButtonLoading}
						categories={topLevelCategoriesData.categories}
						currentCategory={currentCategory}
						googlePlaceID={query.googlePlaceID}
						showTopLevelCategories={!showSubcategories}
						showSubcategories={!!showSubcategories}
						cosmetics={cosmetics}
						languages={languages}
						filterCounts={filterCounts}
						currencyCode={currency?.code}
						currencySymbol={currency?.symbol}
						initialClientDataLoading={initialClientDataLoading}
						maxAvResTimeSlotDateRange={configData?.maxAvResTimeSlotDateRange}
						{...props}
						query={query}
					/>
				</SC.SalonsPageWrapper>
			</SC.Container>
		</>
	)
}

// ssr setup
SalonsPage.initDefaultData = async ({ api, page, query, categorySlug, locale }) => {
	const [categoriesData, configData] = await Promise.all([api.getCategoriesData(), api.getConfigData()])

	const localeCode = shopsConfig.find((config) => config.lang === locale)?.locale
	const currencyCode = configData?.rolloutCountries.find((country) => country.languageCode === localeCode)?.currencyCode
	const currency = configData?.systemCurrencies.find((c) => c.code === currencyCode)

	const foundCategory = categoriesData?.categories.find((category) => category?.nameSlug === categorySlug)
	const categoryIDs = getCategoryIDs(query.categoryIDs, foundCategory?.id)
	const categoryNotFound = Boolean(categorySlug && !foundCategory)

	let categoryData: CategoryResponse | undefined
	if (foundCategory) {
		categoryData = await api.getCategoryData(foundCategory.id)
	}

	let commonParams: GetSalonsDataParams = {
		openingHoursStatus: query.openingHoursStatus,
		latMy: query.latMy,
		lonMy: query.lonMy,
		categoryIDs,
		exactRating: query.exactRating,
		languageIDs: query.languageIDs,
		cosmeticIDs: query.categoryIDs,
		hasAvailableReservationSystem: query.hasAvailableReservationSystem,
		orderBy: query.orderBy,
		avResTimeSlotDate: query.avResTimeSlotDate,
		avResTimeSlotDateFrom: query.avResTimeSlotDateFrom,
		avResTimeSlotDateTo: query.avResTimeSlotDateTo
	}

	if ((!isNil(query.serviceTotalPriceFrom) || !isNil(query.serviceTotalPriceTo)) && currency?.code) {
		commonParams = {
			...commonParams,
			serviceTotalPriceFrom: query.serviceTotalPriceFrom,
			serviceTotalPriceTo: query.serviceTotalPriceTo,
			serviceTotalPriceCurrencyCode: currency.code
		}
	}

	let salonsCounts: GetSalonsFilterCountResponse | undefined

	if (currency?.code) {
		salonsCounts = await api.getSalonsFilterCounts({ ...commonParams, serviceTotalPriceCurrencyCode: currency.code })
	}

	const queryWithValidPriceRange = {
		...commonParams,
		...getValidPriceRangeQueryParams(query.serviceTotalPriceFrom, query.serviceTotalPriceTo, salonsCounts?.priceRange)
	}

	const salonsData = await api.getSalonsData({
		...queryWithValidPriceRange,
		page
	})

	return [salonsData, categoriesData, categoryData, configData, currency, salonsCounts, categoryNotFound]
}

SalonsPage.identifier = 'SalonsPage'

export default SalonsPage
