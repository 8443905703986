import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

// utils
import { useForm } from 'react-hook-form'
import debounce from 'lodash.debounce'
import { ARRAY_QUERY_PARAM_MAX_LENGTH, COSMETICS_SEARCH_DEBOUNCE_TIME_MS, FORM, SALONS_FILTER_TYPE } from '../../../../utils/enums'
import { areValuesInArrayEqual, SALONS_FILTER_ITEMS_CONFIG } from '../../../../utils/helper'

// hooks
import useMessages from '../../../../hooks/useMessages'

// types
import { FilterCosmeticsValuesType, FilterValuesType, Cosmetic, GetSalonsFilterCountResponse, LoadSalonsFromFiltersParams } from '../../../../types/types'

// assets
import ChevronDownLeft from '../../../../assets/icons/ChevronLeftIcon'

// components
import Drawer from '../../../../components/Drawer/Drawer'
import SidebarFooter from '../FilterSidebarFooter/FilterSidebarFooter'
import Tag from '../../../../atoms/Tag/Tag'

// styles
import * as SC from './FilterSidebarStyles'
import HookFormField from '../../../../formFields/HookFormField'
import CheckboxGroupFormField from '../../../../formFields/CheckboxGroupFormField/CheckboxGroupFormField'
import { ApiContext } from '../../../../utils/apiProvider'
import InputFormField from '../../../../formFields/InputFormField/InputFormField'
import { CheckboxGroupGroupedOption, CheckboxGroupOption } from '../../../../formFields/CheckboxGroupFormField/types'

// types
type FilterSidebarCosmeticsProps = {
	filterSidebarDetailOpen: boolean
	setFilterSidebarDetailOpen: (open: boolean) => void
	filterDetailOpenedDirectly: boolean
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	submittedFilterValues: FilterValuesType
	setFilterValues: (newFilterValues: FilterCosmeticsValuesType) => void
	initialCosmetics: Cosmetic[]
	filterItemsConfig: ReturnType<typeof SALONS_FILTER_ITEMS_CONFIG>
	cosmeticCounts: GetSalonsFilterCountResponse['cosmetics'] | undefined
	initialClientDataLoading?: boolean
}

type FilterCosmeticsValuesTypeWithSearch = {
	search?: string
} & FilterCosmeticsValuesType

// component
const FilterSidebarCosmetics = (props: FilterSidebarCosmeticsProps) => {
	const {
		filterSidebarDetailOpen,
		setFilterSidebarDetailOpen,
		filterDetailOpenedDirectly,
		loadSalonsFromFilters,
		submittedFilterValues,
		setFilterValues,
		initialCosmetics,
		filterItemsConfig,
		cosmeticCounts,
		initialClientDataLoading
	} = props
	const { messages } = useMessages()
	const { apiBrowser } = useContext(ApiContext)

	const [cosmetics, setCosmetics] = useState<Cosmetic[]>([])

	const cosmeticsDefaultValue = filterItemsConfig[SALONS_FILTER_TYPE.COSMETICS].defaultValue

	// submitted filter values
	const submittedCosmeticIDs = useMemo(
		() => submittedFilterValues.cosmeticIDs || cosmeticsDefaultValue,
		[submittedFilterValues.cosmeticIDs, cosmeticsDefaultValue]
	)

	// internal filter values - no submitted - just for components state
	const { control, handleSubmit, reset, watch, setValue } = useForm<FilterCosmeticsValuesTypeWithSearch>({
		defaultValues: {
			cosmeticIDs: submittedCosmeticIDs,
			search: ''
		}
	})

	const cosmeticIDs = watch('cosmeticIDs')
	const search = watch('search')

	// check if current internal filter values are equal to default values
	// we will show "reset" buttons based on the check
	const isCosmeticsFilterEqualToDefault = areValuesInArrayEqual(cosmeticIDs, cosmeticsDefaultValue)

	// check if current internal filter values are equal to already submitted values
	// we will "show" footer with submit button based on those checks
	const isCosmeticsFilterPristine = areValuesInArrayEqual(cosmeticIDs, submittedCosmeticIDs)

	const disabled = cosmeticIDs.length > ARRAY_QUERY_PARAM_MAX_LENGTH

	useEffect(() => {
		// reinitialize internal filter values when submitted values changes to keep them in sync
		// be careful with dependencies, non primitive values must be memoized, otherwise internal filter values would reset even when the submitted values haven't changed
		reset({ cosmeticIDs: submittedCosmeticIDs })
	}, [submittedCosmeticIDs, reset])

	useEffect(() => {
		setCosmetics(initialCosmetics)
	}, [initialCosmetics])

	const handleSubmitFilterDetail = (formSubmitValues: FilterCosmeticsValuesType) => {
		if (filterDetailOpenedDirectly) {
			loadSalonsFromFilters({ cosmeticIDs: formSubmitValues.cosmeticIDs })
		} else {
			setFilterValues({ cosmeticIDs: formSubmitValues.cosmeticIDs })
		}

		setFilterSidebarDetailOpen(false)
	}

	const resetCosmeticsFilterToDefault = () => {
		setValue('cosmeticIDs', cosmeticsDefaultValue, { shouldDirty: true })
		setValue('search', '')
	}

	const closeSidebarDetail = () => {
		setFilterSidebarDetailOpen(false)
		if (search) {
			setCosmetics(initialCosmetics)
		}
		reset()
	}

	const getFooterSliderItems = () => {
		const cosmeticsFilterTags = cosmeticIDs
			.map((selectedCosmeticID) => {
				const cosmeticName = initialCosmetics.find((cosmetic) => cosmetic.id === selectedCosmeticID)?.name
				return {
					name: cosmeticName || '',
					id: selectedCosmeticID
				}
			})
			.sort((a, b) => {
				if (a.name < b.name) {
					return -1
				}
				if (a.name > b.name) {
					return 1
				}
				return 0
			})
			.map((selectedCosmetic) => (
				<Tag
					key={selectedCosmetic.id}
					label={selectedCosmetic.name}
					onCloseBtnClick={() =>
						setValue(
							'cosmeticIDs',
							cosmeticIDs.filter((currentCosmeticID) => currentCosmeticID !== selectedCosmetic.id),
							{ shouldDirty: true }
						)
					}
				/>
			))
		return cosmeticsFilterTags.length ? cosmeticsFilterTags : null
	}

	const searchCosmeticsDebounced = useCallback(
		debounce(async (value?: string) => {
			if ((value?.length || 0) > 1) {
				try {
					const newCosmeticsData = await apiBrowser.getCosmeticsData({ search: value })
					if (newCosmeticsData) {
						setCosmetics(newCosmeticsData.cosmetics)
					}
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error(e)
					setCosmetics([])
				}
			} else if (initialCosmetics.length !== cosmetics.length) {
				setCosmetics(initialCosmetics)
			}
		}, COSMETICS_SEARCH_DEBOUNCE_TIME_MS),
		[apiBrowser]
	)

	useEffect(() => {
		searchCosmeticsDebounced(search)
	}, [search, searchCosmeticsDebounced])

	const getOptions = (): CheckboxGroupGroupedOption[] => {
		const selectedCosmeticsMergedWithCurrentCosmetics = initialCosmetics.filter((cosmetic) => {
			return (cosmeticIDs.includes(cosmetic.id) && !search) || cosmetics.find((currentCosmetic) => currentCosmetic.id === cosmetic.id)
		})

		const sortedCosmetics = [...selectedCosmeticsMergedWithCurrentCosmetics].sort((a, b) => a.name.localeCompare(b.name))

		const sortedLetters: string[] = []

		const groupedCosmeticsWithCount = sortedCosmetics.reduce(
			(acc, cosmetic) => {
				// hide cosmetics with 0 count (except selected)
				// if cosmeticCounts is undefined => show all cosmetics, but without counts
				if (cosmeticCounts && !cosmeticCounts[cosmetic.id] && !cosmeticIDs.includes(cosmetic.id)) {
					return acc
				}

				// Group cosmetics starting with a number or special character under "#"
				let firstLetter = cosmetic.name[0].toUpperCase()

				if (!firstLetter.match(/[A-Za-z]/)) {
					firstLetter = '#'
				}

				if (!acc[firstLetter]) {
					acc[firstLetter] = []
					sortedLetters.push(firstLetter)
				}
				acc[firstLetter].push({
					value: cosmetic.id,
					label: cosmetic.name,
					description: cosmeticCounts ? cosmeticCounts[cosmetic.id] || 0 : undefined
				})
				return acc

				// Move '#' to the beginning of the sortedLetters array if it exists
				const hashIndex = sortedLetters.indexOf('#')
				if (hashIndex !== -1) {
					sortedLetters.splice(hashIndex, 1)
					sortedLetters.unshift('#')
				}
			},
			{} as Record<string, CheckboxGroupOption[]>
		)

		return sortedLetters.map((letter) => {
			return {
				key: letter,
				label: letter,
				children: groupedCosmeticsWithCount[letter]
			}
		})
	}

	const options = getOptions()

	return (
		<Drawer
			open={filterSidebarDetailOpen}
			mask
			maskClosable
			// if the detail is open from the main filter sidebar, it already has a mask, so we need to set opacity of detail sidebar mask to zero, so they don't overlap visually
			maskStyle={!filterDetailOpenedDirectly ? { opacity: 0 } : undefined}
			closeIcon={!filterDetailOpenedDirectly ? <ChevronDownLeft /> : undefined}
			title={filterItemsConfig[SALONS_FILTER_TYPE.COSMETICS].title}
			onClose={closeSidebarDetail}
			headerExtra={
				!isCosmeticsFilterEqualToDefault ? (
					<SC.HeaderResetButton onClick={resetCosmeticsFilterToDefault}>{messages?.Reset}</SC.HeaderResetButton>
				) : undefined
			}
			footer={
				<SidebarFooter
					formId={FORM.FILTER_SIDEBAR_COSMETICS}
					isVisible={!(isCosmeticsFilterPristine || !cosmetics.length)}
					extraContent={getFooterSliderItems()}
				/>
			}
			destroyOnClose
		>
			<SC.SidebarDetailContent>
				<SC.SidebarForm id={FORM.FILTER_SIDEBAR_COSMETICS} onSubmit={handleSubmit(handleSubmitFilterDetail)}>
					<SC.FilterSearchWrapper>
						<HookFormField
							control={control}
							name={'search'}
							inputMode={'search'}
							placeholder={messages?.Search}
							disabled={disabled}
							component={InputFormField}
						/>
					</SC.FilterSearchWrapper>
					<SC.CosmeticsFetchResults
						initialLoading={initialClientDataLoading}
						empty={!options.length}
						emptyLabel={messages?.['Unfortunately, no cosmetics were found']}
					>
						<HookFormField control={control} name={'cosmeticIDs'} component={CheckboxGroupFormField} options={options} />
					</SC.CosmeticsFetchResults>
				</SC.SidebarForm>
			</SC.SidebarDetailContent>
		</Drawer>
	)
}

export default FilterSidebarCosmetics
