/* eslint-disable global-require */
import React from 'react'
import { useIntl } from 'react-intl'
import { AvailableLanguagesType } from '../../../../types/types'

// styles
import * as SC from './SalonsPageBannerStyles'

type SalonsPageBannerProps = {
	assetsPath: string
	show: boolean
	isDevMode?: boolean
}

// component
const SalonsPageBanner = (props: SalonsPageBannerProps) => {
	const { assetsPath, show, isDevMode } = props
	const intl = useIntl()
	const locale = intl.locale as AvailableLanguagesType

	let bannerDesktop = ''
	let bannerMobile = ''

	switch (locale) {
		case 'ro':
			bannerDesktop = `${assetsPath}/${require(`../../../../assets/images/top-banner-ro-desktop.jpg`)}`
			bannerMobile = `${assetsPath}/${require(`../../../../assets/images/top-banner-ro-mobile.jpg`)}`
			break
		case 'bg':
			bannerDesktop = `${assetsPath}/${require(`../../../../assets/images/top-banner-bg-desktop.jpg`)}`
			bannerMobile = `${assetsPath}/${require(`../../../../assets/images/top-banner-bg-mobile.jpg`)}`
			break
		case 'hu':
			bannerDesktop = `${assetsPath}/${require(`../../../../assets/images/top-banner-hu-desktop.jpg`)}`
			bannerMobile = `${assetsPath}/${require(`../../../../assets/images/top-banner-hu-mobile.jpg`)}`
			break
		case 'sk':
			bannerDesktop = `${assetsPath}/${require(`../../../../assets/images/top-banner-sk-desktop.jpg`)}`
			bannerMobile = `${assetsPath}/${require(`../../../../assets/images/top-banner-sk-mobile.jpg`)}`
			break
		case 'cs':
		default:
			bannerDesktop = `${assetsPath}/${require(`../../../../assets/images/top-banner-cs-desktop.jpg`)}`
			bannerMobile = `${assetsPath}/${require(`../../../../assets/images/top-banner-cs-mobile.jpg`)}`
			break
	}

	// the banner should be full width
	// but notino main app provides padding, so we need to add negative margin to the width of our banner container in order to have full-width banner without white spacing on the sides
	// but we don't want to add negative margin in development mode, because it would add horizontal scrollbar to the body
	return show ? (
		<SC.SalonsPageBannerContainer $assetsPath={assetsPath} $negativeMargin={!isDevMode}>
			<SC.SalonsPageBanner $bannerDesktop={bannerDesktop} $bannerMobile={bannerMobile} />
		</SC.SalonsPageBannerContainer>
	) : null
}

export default SalonsPageBanner
