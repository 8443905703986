import { theme } from '@notinosro/react-styleguide'
import styled from 'styled-components'
import { NotinoLabelLarge, NotinoTitleMedium, NotinoTitleSmall } from '../../../../styles/helpers'
import { BREAKPOINTS, SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

export const FirstChildCategoryTitle = styled.div`
	${NotinoTitleSmall};
	margin-top: 16px;
	margin-bottom: 10px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoTitleMedium};
		margin-top: 32px;
		margin-bottom: 12px;
	}
`

export const FirstChildCategoryContent = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 2fr);
	gap: 10px;
	padding: 10px 0;

	@media (max-width: ${BREAKPOINTS.md}) {
		grid-template-columns: 1fr;
		gap: 12px;
	}
`

export const CategoryItem = styled.div`
	position: relative;
	min-height: 65px;
	background: ${theme.palette.neutralLighter};
	padding: 16px 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&:hover > h3 {
		text-decoration: underline;
	}
`

export const SecondChildCategoryTitle = styled.div`
	${NotinoLabelLarge};
`

export const PriceAndDurationContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

export const SecondCategoryPrice = styled.div`
	display: flex;
	align-items: center;
	${theme.typography.bodySmall}
`

export const SecondCategoryDuration = styled.div<{ $hidden: boolean }>`
	display: ${(p) => (p.$hidden ? 'none' : 'flex')};

	align-items: center;
	${theme.typography.bodySmall}
`

export const DurationIcon = styled.div`
	margin-right: 5px;

	& > svg {
		width: 12px;
		height: 12px;
	}
`
