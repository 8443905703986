import React from 'react'

const ExpandIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'>
			<g>
				<mask id='mask0_1863_3630' style={{ maskType: 'alpha' }} width='12' height='8' x='0' y='0' maskUnits='userSpaceOnUse'>
					<g>
						<path fill='#fff' d='M.977.667L0 1.644l6 6 6-6-.977-.977L6 5.69.977.667z' />
					</g>
				</mask>
				<g mask='url(#mask0_1863_3630)'>
					<path fill='currentColor' d='M.977.667L0 1.644l6 6 6-6-.977-.977L6 5.69.977.667z' />
					<g>
						<g>
							<path fill='currentColor' d='M14-4H-2v16h16V-4z' />
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default ExpandIcon
