import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick'

// hooks
import { useIntl } from 'react-intl'
import useMessages from '../../hooks/useMessages'

// types
import { DateCellData, DateCellsData, DatePickerProps, GetDateCellsDataArgs, SliderMonth, TimeSlotValueType } from './types'

// utils
import { DEFAULT_DATE_FORMAT, FULL_MONTH_NAME } from '../../utils/enums'

// components
import CustomRadioButtons from '../CustomRadioButtons/CustomRadioButtons'
import Dialog from '../Dialog/Dialog'

// styles
import * as SC from './DatePickerStyles'

const getWeekDays = () => {
	const firstDayOfWeek = dayjs().localeData().firstDayOfWeek()
	const weekdays = dayjs().localeData().weekdaysMin()
	return [...weekdays.slice(firstDayOfWeek), ...weekdays.slice(0, firstDayOfWeek)]
}

const SLIDER_MONTHS_COUNT_BEFORE_CURRENT = 6 // half a year
const SLIDER_MONTHS_COUNT_AFTER_CURRENT = 24 // 2 years

const getSliderMonths = (selectedYear: number, selectedMonth: number): SliderMonth[] => {
	const selectedDate = dayjs(new Date(selectedYear, selectedMonth, 1))
	const today = dayjs()
	const startDate = today.subtract(SLIDER_MONTHS_COUNT_BEFORE_CURRENT, 'month')
	const slidesCount = SLIDER_MONTHS_COUNT_BEFORE_CURRENT + SLIDER_MONTHS_COUNT_AFTER_CURRENT

	const slides: SliderMonth[] = []

	for (let i = 0; i < slidesCount; i += 1) {
		const date = startDate.add(i, 'month')
		const slide: SliderMonth = {
			label: date.format(FULL_MONTH_NAME),
			date: date.format(DEFAULT_DATE_FORMAT),
			isActive: date.isSame(selectedDate, 'month'),
			disabled: date.isBefore(today, 'month')
		}
		slides.push(slide)
	}

	return slides
}

const getDateCellsData = (data: GetDateCellsDataArgs): DateCellsData => {
	const { selectedMonth, selectedYear, startDate, endDate, responseData } = data

	const startOfTheMonth = dayjs(new Date(selectedYear, selectedMonth, 1))
	const endOfMonth = startOfTheMonth.endOf('month')

	const endDayDayOfTheMonth = endOfMonth.date()

	const startDayOfTheWeek = startOfTheMonth.weekday()

	const dateCellsData: DateCellData[] = [...Array(endDayDayOfTheMonth).keys()].map((index) => {
		const day = index + 1
		const date = dayjs(new Date(selectedYear, selectedMonth, day)).startOf('day')
		const isAvailable = !date.isBefore(dayjs(), 'date') && !!responseData?.find((d) => dayjs(d.date).isSame(date, 'date'))?.available

		const isRange = startDate && endDate
		const startOfTheRange = !!startDate?.isSame(date, 'date')
		const endOfTheRange = !!endDate?.isSame(date, 'date')

		return {
			isAvailable,
			isSelected: startOfTheRange || endOfTheRange,
			isStartOfTheRange: !!(isRange && startOfTheRange),
			isEndOfTheRange: !!(isRange && endOfTheRange),
			isBetweenRanges: !!(startDate && endDate && date.isAfter(startDate, 'date') && date.isBefore(endDate, 'date')),
			isToday: date.isSame(dayjs(), 'date'),
			date,
			day
		}
	})

	return {
		// weekDays are zero based, but grid axis starts with 1
		gridShift: startDayOfTheWeek + 1,
		data: dateCellsData
	}
}
// TODO: find better solution for swiper
// react-slick is not maintained anymore and causes a lot of troubles and workarounds
const DatePicker = <TimeSlotValue extends string = TimeSlotValueType>(props: DatePickerProps<TimeSlotValue>) => {
	const { assetsPath, value, onChange, availableDates, disabled, onChangeView, onChangeDayPeriod, maxRange, timeSlotOptions } = props
	const { dateFrom, dateTo, timeSlot } = value

	const { messages } = useMessages()
	const { formatMessage } = useIntl()

	const [popup, setPopup] = useState<{ open: boolean; handler?: () => void }>({ open: false })

	const now = dayjs()

	let startDate: Dayjs | undefined
	let endDate: Dayjs | undefined

	if (dateFrom && dateTo) {
		if (dateFrom.isBefore(dateTo, 'date')) {
			startDate = dateFrom
			endDate = dateTo
		} else if (dateFrom.isSame(dateTo, 'date')) {
			startDate = dateFrom
		} else {
			startDate = dateTo
			endDate = dateFrom
		}
	} else {
		startDate = dateFrom || dateTo
	}

	const [selectedYear, setSelectedYear] = useState(startDate?.year() || now.year())
	const [selectedMonth, setSelectedMonth] = useState(startDate?.month() || now.month())
	const selectedDate = dayjs(new Date(selectedYear, selectedMonth, 1))

	const sliderRef = useRef<Slider | null>(null)

	const startDateString = startDate ? dayjs(startDate).format(DEFAULT_DATE_FORMAT) : undefined

	useEffect(() => {
		const newStartDate = dayjs(startDateString)
		setSelectedYear(newStartDate.year())
		setSelectedMonth(newStartDate.month())
	}, [startDateString])

	const sliderMonths = getSliderMonths(selectedYear, selectedMonth)
	const weekDays = getWeekDays()
	const dayCellsData = getDateCellsData({ selectedMonth, selectedYear, startDate, endDate, responseData: availableDates })

	const activeSlideIndex = sliderMonths.findIndex((slide) => slide.isActive)
	const todaySlideIndex = sliderMonths.findIndex((slide) => dayjs(slide.date).isSame(dayjs(), 'month'))
	const isLastSlide = sliderMonths.length - 1 === activeSlideIndex

	const handleChangeSlide = (newIndex: number) => {
		const newSlide = sliderMonths[newIndex]

		const newDate = dayjs(newSlide.date)
		const newYear = newDate.year()
		const newMonth = newDate.month()

		setSelectedYear(newYear)
		setSelectedMonth(newMonth)

		if (onChangeView) {
			onChangeView(newYear, newMonth)
		}
	}

	const handleChangeDate = (newDate: Dayjs, isAvailable: boolean) => {
		if (!isAvailable) {
			return
		}

		let newStartDate: Dayjs | undefined
		let newEndDate: Dayjs | undefined

		if (startDate && endDate) {
			newStartDate = newDate
			newEndDate = undefined
		} else if (startDate) {
			if (newDate.isBefore(startDate, 'date')) {
				newStartDate = newDate
				newEndDate = startDate
			} else if (!newDate.isSame(startDate, 'date')) {
				newStartDate = startDate
				newEndDate = newDate
			}
		} else {
			newStartDate = newDate
		}

		if (newStartDate && newEndDate && maxRange !== undefined && !(newEndDate.diff(newStartDate, 'day') <= maxRange)) {
			newEndDate = newStartDate.add(maxRange, 'day')
			setPopup({
				open: true,
				handler: () => {
					onChange({
						timeSlot,
						dateFrom: newStartDate,
						dateTo: newEndDate
					})
				}
			})
		} else {
			onChange({
				timeSlot,
				dateFrom: newStartDate,
				dateTo: newEndDate
			})
		}
	}

	const handleChangeDayPeriod = (newPeriod: TimeSlotValue) => {
		onChange({
			timeSlot: newPeriod,
			dateFrom,
			dateTo
		})
		if (onChangeDayPeriod) {
			onChangeDayPeriod(newPeriod, selectedYear, selectedMonth)
		}
	}

	useEffect(() => {
		const slickTrack = document.querySelector('.slick-track')
		const slides: NodeListOf<HTMLDivElement> | undefined = slickTrack?.querySelectorAll('[data-index]')
		slides?.forEach((slide) => {
			const dataIndexValue = slide.dataset.index

			if (Number(dataIndexValue) < todaySlideIndex) {
				// eslint-disable-next-line no-param-reassign
				slide.style.pointerEvents = 'none'
			} else {
				// eslint-disable-next-line no-param-reassign
				slide.style.pointerEvents = 'all'
			}
		})
	}, [selectedMonth, selectedYear, todaySlideIndex])

	const handleChangeNextSlide = () => {
		sliderRef.current?.slickGoTo(activeSlideIndex + 1)
	}

	const handleChangePrevSlide = () => {
		sliderRef.current?.slickGoTo(activeSlideIndex - 1)
	}

	const handleSubmitPopup = () => {
		if (popup.handler) {
			popup.handler()
		}
		setPopup({ open: false })
	}

	const modalTitle = formatMessage(
		{ id: 'The selected range exceeds { daysCount }', defaultMessage: 'The selected range exceeds { daysCount }' },
		{ daysCount: maxRange }
	)
	const modalContent = formatMessage(
		{
			id: 'Selected time range must be shorter than { daysCount } days.',
			defaultMessage: 'Selected time range must be shorter than { daysCount } days.'
		},
		{ daysCount: maxRange }
	)

	return (
		<>
			<Dialog centerMode isOpen={popup.open} title={modalTitle} onRequestClose={handleSubmitPopup} onConfirm={handleSubmitPopup}>
				<SC.DialogMessage>{modalContent}</SC.DialogMessage>
			</Dialog>
			<SC.DatePickerWrapper>
				<SC.Year>{selectedYear}</SC.Year>
				<SC.SliderWrapper>
					<SC.SliderButton
						type={'button'}
						$assetsPath={assetsPath}
						onClick={handleChangePrevSlide}
						disabled={selectedDate.isSame(dayjs(), 'month')}
					/>
					<Slider
						ref={sliderRef}
						infinite={false}
						centerMode
						focusOnSelect={!disabled}
						accessibility={!disabled}
						speed={500}
						variableWidth
						slidesToScroll={1}
						autoplay={false}
						arrows
						draggable={false}
						afterChange={handleChangeSlide}
						initialSlide={activeSlideIndex}
					>
						{sliderMonths.map(({ label, isActive, disabled: monthDisabled }, index) => {
							return (
								<SC.SliderItem key={index} $isActive={isActive} $isDisabled={monthDisabled || disabled}>
									{label}
								</SC.SliderItem>
							)
						})}
					</Slider>
					<SC.SliderButton type={'button'} $assetsPath={assetsPath} $isNext onClick={handleChangeNextSlide} disabled={isLastSlide} />
				</SC.SliderWrapper>
				<SC.CalendarWrapper>
					<SC.CalendarGrid>
						{weekDays.map((day, index) => (
							<SC.HeaderCell key={index}>{day}</SC.HeaderCell>
						))}
						{dayCellsData.data.map(({ day, date, isAvailable, isBetweenRanges, isEndOfTheRange, isStartOfTheRange, isSelected, isToday }) => {
							return (
								<SC.DayCell
									key={day}
									$gridShift={day === 1 ? dayCellsData.gridShift : undefined}
									$isStartOfTheRange={isStartOfTheRange}
									$isEndOfTheRange={isEndOfTheRange}
									$isBetweenRanges={isBetweenRanges}
								>
									<SC.DayCellButton
										$isAvailable={isAvailable}
										$isSelected={isSelected}
										$isToday={isToday}
										onClick={() => handleChangeDate(date, isAvailable)}
									>
										{day}
									</SC.DayCellButton>
								</SC.DayCell>
							)
						})}
					</SC.CalendarGrid>
				</SC.CalendarWrapper>
			</SC.DatePickerWrapper>
			<CustomRadioButtons<TimeSlotValue>
				label={messages?.['Select time']}
				value={timeSlot}
				onChange={(newPeriod) => handleChangeDayPeriod(newPeriod)}
				name={'time-slots'}
				options={timeSlotOptions}
				disabled={disabled}
			/>
		</>
	)
}

export default DatePicker
