import styled from 'styled-components'
import { CloseIcon as NotinoCloseIcon, theme } from '@notinosro/react-styleguide'

export const Tag = styled.div`
	display: inline-flex;
	line-height: 1.5rem;
	white-space: nowrap;
	background-color: #f5f5f5;
	align-items: center;
	padding: 0.25rem 0.25rem 0.25rem 0.5rem;
	color: #000;
`

export const Label = styled.span`
	display: inline-block;
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1.5rem;
`

export const CloseButton = styled.button`
	display: inline-block;
	border: none;
	background: transparent;
	width: 1.5rem;
	height: 1.5rem;
	transition: all 0.3s ease;
	overflow: hidden;
	flex-shrink: 0;

	&:not(:disabled) {
		cursor: pointer;
		color: ${theme.palette.basic};
		outline: 2px solid transparent;

		&:focus-visible {
			outline-color: ${theme.palette.basic};
		}
	}

	&:disabled {
		color: #ababab;
	}
`

export const CloseIcon = styled(NotinoCloseIcon)<{ $isSelected?: boolean }>`
	color: #6b6b6b;
	width: 0.75rem;
	height: 0.75rem;
	display: inline-block;
`
