import styled from 'styled-components'

// utils
import { NotinoLabelSmall400 } from '../../styles/helpers'

// components
import Input from '../../atoms/Input/Input'

// Price filter
export const PriceFieldWrapper = styled.div``

export const PriceInputsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	padding-bottom: 12px;
`

export const PriceInput = styled(Input)`
	flex: 1 1 50%;
`

export const PriceInputDivider = styled.span`
	${NotinoLabelSmall400};
	color: #666;
`
