import React from 'react'
import RcDrawer from 'rc-drawer'

// utils
import motionProps from './motion'
import { DrawerProps } from './types'

// style
import * as SC from './DrawerStyles'

// component
const Drawer = (props: DrawerProps) => {
	const { title, closeIcon = <SC.CloseIcon />, footer, children, onClose, headerExtra, ...restProps } = props

	return (
		<RcDrawer onClose={onClose} width={420} {...restProps} {...motionProps}>
			<SC.DrawerBody>
				<SC.Header>
					<SC.CloseButton onClick={onClose}>{closeIcon}</SC.CloseButton>
					<SC.HeaderTitle>{title}</SC.HeaderTitle>
					{headerExtra && <SC.HeaderExtra>{headerExtra}</SC.HeaderExtra>}
				</SC.Header>
				<SC.Content>{children}</SC.Content>
				{footer && <SC.Footer>{footer}</SC.Footer>}
			</SC.DrawerBody>
		</RcDrawer>
	)
}

export default Drawer
