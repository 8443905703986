import { useContext, useState } from 'react'
import { SalonReviewsResponse } from '../types/types'
import { ApiContext } from '../utils/apiProvider'

const useSalonReviews = (salonId: string, reviewsData: SalonReviewsResponse | undefined) => {
	const { apiBrowser } = useContext(ApiContext)

	const initialReviews = reviewsData?.reviews && reviewsData?.reviews.length > 0 ? reviewsData?.reviews : null
	const [reviews, setReviews] = useState(initialReviews)
	const [reviewsPage, setReviewsPage] = useState(1)

	const fetchMoreReviews = async () => {
		const newReviewsData = await apiBrowser.getSalonReviewsData(salonId, { page: reviewsPage + 1 })
		if (newReviewsData) {
			setReviews((prev) => {
				if (prev) return [...prev, ...newReviewsData.reviews]
				return newReviewsData.reviews
			})
			setReviewsPage(reviewsPage + 1)
		}
	}

	return { reviews, reviewsPage, fetchMoreReviews }
}

export default useSalonReviews
