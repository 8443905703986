import React from 'react'
import useMessages from '../../../../hooks/useMessages'

// components
import OpeningHours from '../OpeningHours/OpeningHours'
import SocialIcons from '../SocialIcons/SocialIcons'
import GoogleMaps from '../../../../components/GoogleMaps/GoogleMaps'

// types
import { ConfigResponse, SalonDetail } from '../../../../types/types'

// styles
import * as SC from './SalonDetailCardStyles'

// assets
import MapPinIcon from '../../../../assets/icons/MapPinIcon'
import MailIcon from '../../../../assets/icons/MailIcon'
import PhoneIcon from '../../../../assets/icons/PhoneIcon'
import ParkingIcon from '../../../../assets/icons/ParkingIcon'
import FolderIcon from '../../../../assets/icons/FolderIcon'

// utils
import { getPhoneNumber } from '../../../../utils/helper'

type Props = {
	assetsPath: string
	googleMapsApiKey: string
	countries: ConfigResponse['rolloutCountries']
	salonServices: string[]
	/* NOTE: SalonDetailCard used to be used for basic salon as well - now it's used only for
    profi salon, so this prop (and the content rendered when it's true) is not used anymore.
    Leaving it here only for potential future use. */
	isBasicSalon?: boolean
	salonData?: SalonDetail
}

const SalonDetailCard = (props: Props) => {
	const { salonData, assetsPath, googleMapsApiKey, isBasicSalon, countries, salonServices } = props
	const socialLinksExist =
		salonData?.socialLinkWebPage ||
		salonData?.socialLinkFB ||
		salonData?.socialLinkInstagram ||
		salonData?.socialLinkPinterest ||
		salonData?.socialLinkYoutube ||
		salonData?.socialLinkTikTok

	const { messages } = useMessages()

	return (
		<SC.SalonDetailCardContainer>
			<SC.SalonCardContactInfo>
				{/* name */}
				{salonData?.name && <SC.SalonCardTitle>{salonData.name}</SC.SalonCardTitle>}

				{/* email, phone, address */}
				{salonData?.email && (
					<SC.InfoCardBox>
						<SC.IconBox>
							<MailIcon />
						</SC.IconBox>
						<SC.InfoCardBoxTitle>{salonData.email}</SC.InfoCardBoxTitle>
					</SC.InfoCardBox>
				)}
				{salonData?.phones && salonData?.phones.length > 0 && (
					<SC.InfoCardBox>
						<SC.IconBox>
							<PhoneIcon />
						</SC.IconBox>
						<SC.InfoCardBoxTitle>
							{salonData?.phones.map((phone) => <div key={phone.id}>{getPhoneNumber(phone, countries)}</div>)}
						</SC.InfoCardBoxTitle>
					</SC.InfoCardBox>
				)}
				{salonData?.formattedAddress && (
					<SC.InfoCardBox>
						<SC.IconBox>
							<MapPinIcon />
						</SC.IconBox>
						<SC.InfoCardBoxTitle>
							<div>{`${salonData.address?.street} ${salonData.address?.streetNumber}`}</div>
							<div>{`${salonData.address?.city}, ${salonData.address?.zipCode}`}</div>
						</SC.InfoCardBoxTitle>
					</SC.InfoCardBox>
				)}

				{/* services */}
				{isBasicSalon && salonServices && salonServices.length > 0 && (
					<>
						<SC.InfoCardBox>
							<SC.IconBox>
								<FolderIcon />
							</SC.IconBox>
							<SC.InfoCardBoxTitle>{messages?.Services}</SC.InfoCardBoxTitle>
						</SC.InfoCardBox>
						<SC.ServicesContainer>
							{salonServices.map((service, index) => (
								<SC.Service key={index}>{service}</SC.Service>
							))}
						</SC.ServicesContainer>
					</>
				)}

				{/* opening hours */}
				{isBasicSalon && salonData?.openingHoursData && (
					<SC.InfoCardBox>
						<OpeningHours openingHours={salonData?.openingHoursData} darkBackground />
					</SC.InfoCardBox>
				)}

				{/* parking */}
				{!isBasicSalon && salonData?.parkingNote && (
					<>
						<SC.InfoCardBox>
							<SC.IconBox>
								<ParkingIcon />
							</SC.IconBox>
							<SC.InfoCardBoxTitle>{messages?.Parking}</SC.InfoCardBoxTitle>
						</SC.InfoCardBox>
						<SC.ParkingNote>{salonData?.parkingNote}</SC.ParkingNote>
					</>
				)}

				{/* social links */}
				{!isBasicSalon && socialLinksExist && (
					<SC.SocialIconsContainer>
						<SocialIcons salonData={salonData} />
					</SC.SocialIconsContainer>
				)}
			</SC.SalonCardContactInfo>
			<SC.GoogleMapsContainer id={'google-map'}>
				<GoogleMaps
					assetsPath={assetsPath}
					googleMapsApiKey={googleMapsApiKey}
					disabled
					center={{ lat: salonData?.address?.latitude || 0, lng: salonData?.address?.longitude || 0 }}
					showOneMarker // marker position is equal to the center in this case
				/>
			</SC.GoogleMapsContainer>
		</SC.SalonDetailCardContainer>
	)
}

export default SalonDetailCard
