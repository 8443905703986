import React from 'react'

// types
import { CustomRadioButtonProps } from './types'

// styles
import * as SC from './CustomRadioButtonsStyles'

const CustomRadioButtons = <V extends string = string>(props: CustomRadioButtonProps<V>) => {
	const { options, value, onChange, name, label, disabled } = props

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: V) => {
		if (e.target.checked) {
			onChange(v)
		}
	}

	return (
		<SC.Wrapper>
			{label && <SC.Label>{label}</SC.Label>}
			<SC.RadioButtonsWrapper>
				{options.map((option) => {
					const checked = option.id === value
					const optionDisabled = disabled || option.disabled
					return (
						<SC.RadioButtonLabel key={option.id} htmlFor={option.id} $disabled={optionDisabled}>
							<SC.RadioButton
								type={'radio'}
								name={name}
								id={option.id}
								checked={checked}
								disabled={optionDisabled}
								onChange={(e) => handleChange(e, option.id)}
							/>
							<SC.CustomLabel>
								<SC.LabelTitle>{option.label}</SC.LabelTitle>
								<SC.LabelDescription>{option.description}</SC.LabelDescription>
							</SC.CustomLabel>
						</SC.RadioButtonLabel>
					)
				})}
			</SC.RadioButtonsWrapper>
		</SC.Wrapper>
	)
}

export default CustomRadioButtons
