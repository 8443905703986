import React from 'react'

const CalendarIcon2 = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
			<rect width='12' height='11' x='2' y='3' stroke='currentColor' rx='2' />
			<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' d='M5 3V2M11 3V2' />
			<path stroke='currentColor' strokeLinejoin='round' d='M2 6h12' />
			<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' d='M4 8.5h1M4 11h1M7.5 8.5h1M11 8.5h1M7.5 11h1' />
		</svg>
	)
}

export default CalendarIcon2
