import React from 'react'

const ParkingIcon = () => {
	return (
		<svg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.57334 19H7.4377C7.23138 19 7.06396 18.8337 7.06396 18.6288V5.37121C7.06396 5.16628 7.23138 5 7.4377 5H12.7771C15.1911 5 17.1552 6.95072 17.1552 9.34847C17.1552 11.7462 15.1911 13.6969 12.7771 13.6969H9.94731V18.6288C9.94731 18.8337 9.77966 19 9.57334 19ZM7.81143 18.2576H9.19962V13.3258C9.19962 13.1208 9.36704 12.9546 9.57335 12.9546H12.7768C14.7788 12.9546 16.4074 11.337 16.4074 9.34853C16.4074 7.36009 14.7788 5.7425 12.7768 5.7425H7.81139L7.81143 18.2576Z'
				fill='black'
			/>
			<path
				d='M12.8037 11.9999H9.47086C9.25621 11.9999 9.08203 11.8133 9.08203 11.5833V7.41666C9.08203 7.18664 9.25621 7 9.47086 7H12.8037C14.0902 7 15.1367 8.12135 15.1367 9.5C15.1367 10.8787 14.0903 12 12.8037 12V11.9999ZM9.85968 11.1666H12.8037C13.6613 11.1666 14.3591 10.419 14.3591 9.49997C14.3591 8.58096 13.6613 7.83332 12.8037 7.83332H9.85968V11.1666Z'
				fill='black'
			/>
			<path
				d='M11.6049 23C8.50498 23 5.59067 21.8039 3.39897 19.6317C1.20728 17.4596 0 14.5719 0 11.5C0 8.42813 1.20705 5.54015 3.39897 3.36826C5.5909 1.19632 8.50498 0 11.6049 0C14.7047 0 17.6191 1.19614 19.8107 3.36826C22.0024 5.54038 23.2097 8.42813 23.2097 11.5C23.2097 14.5719 22.0027 17.4598 19.8107 19.6317C17.6188 21.8036 14.7047 23 11.6049 23ZM11.6049 0.709049C5.60048 0.709049 0.715561 5.54983 0.715561 11.5C0.715561 17.4501 5.60048 22.2909 11.6049 22.2909C17.6092 22.2909 22.4942 17.4501 22.4942 11.5C22.4942 5.54983 17.6092 0.709049 11.6049 0.709049Z'
				fill='black'
			/>
		</svg>
	)
}

export default ParkingIcon
