import { theme } from '@notinosro/react-styleguide'
import styled from 'styled-components'
import { BREAKPOINTS, SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

export const SalonPriceListsTitle = styled.div`
	${theme.typography.titleDisplay}
`

export const SalonPriceListsFiles = styled.div`
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(6, 1fr);

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		padding-top: 20px;
	}

	@media (max-width: ${BREAKPOINTS.md}) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media (max-width: ${BREAKPOINTS.sm}) {
		grid-template-columns: repeat(2, 1fr);
	}
`

export const SalonPricePdfPlaceholder = styled.button`
	border: none;
	aspect-ratio: 3/4;
	cursor: pointer;
	background: ${theme.palette.neutralLighter};
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${BREAKPOINTS.sm}) {
		aspect-ratio: unset;
		grid-column: 1/3;
		padding: 16px;
	}
`

export const SalonPricePdfTitle = styled.div`
	width: 100%;
	text-align: center;
	padding: 8px;
`

export const FileIcon = styled.div`
	@media (max-width: ${BREAKPOINTS.sm}) {
		display: none;
	}
`

export const FileName = styled.div`
	${theme.typography.bodySmall}
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: ${BREAKPOINTS.sm}) {
		justify-content: space-between;
	}
`

export const ChevronIcon = styled.span`
	@media (min-width: ${BREAKPOINTS.sm}) {
		display: none;
	}
`

export const OpenPdfFile = styled.div`
	${theme.typography.labelSmall}
	border: 1px solid black;
	padding: 4px;
	background-color: white;
	margin-top: 8px;
	display: inline-block;

	@media (max-width: ${BREAKPOINTS.sm}) {
		display: none;
	}
`

export const SalonPriceListsImage = styled.button`
	border: none;
	aspect-ratio: 3/4;
	cursor: pointer;
	object-fit: cover;

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`
