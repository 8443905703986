import styled from 'styled-components'
import { theme } from '@notinosro/react-styleguide'
import { BREAKPOINTS, SALON_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'
import { NotinoTitleDisplay, NotinoTitleLarge } from '../../../../styles/helpers'

export const Heading = styled.h2`
	/* resetting global notino styles on heading */
	margin-top: 0px;
	text-align: left !important;

	${NotinoTitleLarge};
	margin-bottom: 16px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		${NotinoTitleDisplay};
		margin-bottom: 24px;
	}
`

export const MapContainer = styled.div`
	position: relative;
	min-height: 250px;

	@media (min-width: ${BREAKPOINTS.sm}) {
		aspect-ratio: 16/9; // aspect ratio must be turned off on smaller screens because in combination with min-height it causes map to overflow
	}

	margin-bottom: 20px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-bottom: 40px;
	}
`

export const Columns = styled.div`
	display: flex;
	column-gap: 40px;
`

export const MainColumn = styled.div`
	flex: 1 1 0;
`

export const SideColumn = styled.div``

export const NotinoPartnerLinkContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	margin-top: 24px;

	@media (min-width: ${SALON_PAGE_MOBILE_BREAKPOINT}) {
		margin-top: 16px;
	}
`
export const NotinoPartnerLink = styled.a`
	color: ${theme.palette.neutralDarker};
	font-weight: 400;
`
