import React from 'react'

const ListIcon = () => {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M5.33398 12.9995H14.0017' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M2.00049 12.9995H2.00716' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M5.33398 8.99902H14.0017' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M2.00049 8.99902H2.00716' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M5.33398 4.99854H14.0017' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M2.00049 4.99854H2.00716' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	)
}

export default ListIcon
