import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'

// types
import {
	FilterValuesType,
	Cosmetic,
	GetSalonsFilterCountResponse,
	Language,
	LoadSalonsFromFiltersParams,
	SalonsPageQueryType,
	ServiceTotalPriceCurrencyCode
} from '../../../../types/types'

// components
import SalonsChips from '../SalonsChips/SalonsChips'
import FilterSidebar from '../FilterSidebar/FilterSidebar'

// utils
import { SALONS_FILTER_ITEMS_CONFIG } from '../../../../utils/helper'
import { OPENING_HOURS_STATUS, SALONS_FILTER_TYPE } from '../../../../utils/enums'

// hooks
import useSortingOptions from '../../../../hooks/useSortingOptions'

// types
type FiltersProps = {
	query: SalonsPageQueryType
	filterCounts: GetSalonsFilterCountResponse | undefined
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	languages: Language[]
	cosmetics: Cosmetic[]
	currencySymbol: string | undefined
	currencyCode: ServiceTotalPriceCurrencyCode | undefined
	initialClientDataLoading?: boolean
	topLevelCategoryID: string | undefined
	assetsPath: string
	maxAvResTimeSlotDateRange: number | undefined
}

// component
const Filters = (props: FiltersProps) => {
	const {
		languages,
		cosmetics,
		currencySymbol,
		currencyCode,
		loadSalonsFromFilters,
		query,
		filterCounts,
		initialClientDataLoading,
		topLevelCategoryID,
		assetsPath,
		maxAvResTimeSlotDateRange
	} = props

	const { formatMessage } = useIntl()

	const [, sortingOptions] = useSortingOptions(query)

	const [filterSidebarOpen, setFilterSidebarOpen] = useState(false)
	const [filterDetailOpenedDirectly, setFilterDetailOpenedDirectly] = useState(false)
	const [filterSidebarDetailOpen, setFilterSidebarDetailOpen] = useState(false)
	const [filterSidebarDetailType, setFilterSidebarDetailType] = useState<SALONS_FILTER_TYPE | null>(null)

	const filterItemsConfig = useMemo(
		() => SALONS_FILTER_ITEMS_CONFIG(formatMessage, filterCounts?.priceRange.minValue, filterCounts?.priceRange?.maxValue),
		[formatMessage, filterCounts?.priceRange.minValue, filterCounts?.priceRange?.maxValue]
	)

	const defaultFilterValues: FilterValuesType = useMemo(
		() => ({
			serviceTotalPriceRange: {
				priceFrom: filterItemsConfig[SALONS_FILTER_TYPE.PRICE].defaultValue[0],
				priceTo: filterItemsConfig[SALONS_FILTER_TYPE.PRICE].defaultValue[1]
			},
			hasAvailableReservationSystem: filterItemsConfig[SALONS_FILTER_TYPE.AVAILABLE_RS].defaultValue,
			openingHours: filterItemsConfig[SALONS_FILTER_TYPE.OPENING_HOURS].defaultValue,
			exactRating: filterItemsConfig[SALONS_FILTER_TYPE.RATING].defaultValue,
			cosmeticIDs: filterItemsConfig[SALONS_FILTER_TYPE.COSMETICS].defaultValue,
			languageIDs: filterItemsConfig[SALONS_FILTER_TYPE.LANGUAGES].defaultValue,
			orderBy: filterItemsConfig[SALONS_FILTER_TYPE.SORTING].defaultValue,
			availableReservations: {
				timeSlot: filterItemsConfig[SALONS_FILTER_TYPE.DATE].defaultValue.avResTimeSlotDate,
				dateFrom: filterItemsConfig[SALONS_FILTER_TYPE.DATE].defaultValue.avResTimeSlotDateFrom,
				dateTo: filterItemsConfig[SALONS_FILTER_TYPE.DATE].defaultValue.avResTimeSlotDateTo
			}
		}),
		[filterItemsConfig]
	)

	// needs to be memoized to prevent unexpected reset of internal filter values
	const submittedFilterValues: FilterValuesType = useMemo(
		() => ({
			serviceTotalPriceRange: {
				priceFrom: query.serviceTotalPriceFrom ?? defaultFilterValues.serviceTotalPriceRange.priceFrom,
				priceTo: query.serviceTotalPriceTo ?? defaultFilterValues.serviceTotalPriceRange.priceTo
			},
			hasAvailableReservationSystem: query.hasAvailableReservationSystem ?? defaultFilterValues.hasAvailableReservationSystem,
			openingHours: query.openingHoursStatus === OPENING_HOURS_STATUS.OPEN || defaultFilterValues.openingHours,
			exactRating: query.exactRating || defaultFilterValues.exactRating,
			cosmeticIDs: query.cosmeticIDs || defaultFilterValues.cosmeticIDs,
			languageIDs: query.languageIDs || defaultFilterValues.languageIDs,
			orderBy: query.orderBy || defaultFilterValues.orderBy,
			availableReservations: {
				timeSlot: query.avResTimeSlotDate || defaultFilterValues.availableReservations.timeSlot,
				dateFrom:
					query.avResTimeSlotDateFrom || defaultFilterValues.availableReservations.dateFrom
						? dayjs(query.avResTimeSlotDateFrom || defaultFilterValues.availableReservations.dateFrom)
						: undefined,
				dateTo:
					query.avResTimeSlotDateTo || defaultFilterValues.availableReservations.dateTo
						? dayjs(query.avResTimeSlotDateTo || defaultFilterValues.availableReservations.dateTo)
						: undefined
			}
		}),
		[
			query.serviceTotalPriceFrom,
			query.serviceTotalPriceTo,
			query.hasAvailableReservationSystem,
			query.openingHoursStatus,
			query.exactRating,
			query.cosmeticIDs,
			query.languageIDs,
			query.orderBy,
			query.avResTimeSlotDate,
			query.avResTimeSlotDateFrom,
			query.avResTimeSlotDateTo,
			defaultFilterValues
		]
	)

	return (
		<>
			<SalonsChips
				filterItemsConfig={filterItemsConfig}
				submittedFilterValues={submittedFilterValues}
				defaultFilterValues={defaultFilterValues}
				setFilterSidebarOpen={setFilterSidebarOpen}
				setFilterSidebarDetailOpen={setFilterSidebarDetailOpen}
				setFilterSidebarDetailType={setFilterSidebarDetailType}
				setFilterDetailOpenedDirectly={setFilterDetailOpenedDirectly}
				currencySymbol={currencySymbol}
				cosmetics={cosmetics}
				languages={languages}
				loadSalonsFromFilters={loadSalonsFromFilters}
				sortingOptions={sortingOptions}
				initialClientDataLoading={initialClientDataLoading}
				filterCounts={filterCounts}
			/>
			<FilterSidebar
				filterItemsConfig={filterItemsConfig}
				submittedFilterValues={submittedFilterValues}
				defaultFilterValues={defaultFilterValues}
				filterSidebarOpen={filterSidebarOpen}
				setFilterSidebarOpen={setFilterSidebarOpen}
				filterSidebarDetailOpen={filterSidebarDetailOpen}
				setFilterSidebarDetailOpen={setFilterSidebarDetailOpen}
				filterSidebarDetailType={filterSidebarDetailType}
				setFilterSidebarDetailType={setFilterSidebarDetailType}
				filterDetailOpenedDirectly={filterDetailOpenedDirectly}
				setFilterDetailOpenedDirectly={setFilterDetailOpenedDirectly}
				sortingOptions={sortingOptions}
				initialCosmetics={cosmetics}
				initialLanguages={languages}
				currencySymbol={currencySymbol}
				currencyCode={currencyCode}
				loadSalonsFromFilters={loadSalonsFromFilters}
				filterCounts={filterCounts}
				query={query}
				initialClientDataLoading={initialClientDataLoading}
				topLevelCategoryID={topLevelCategoryID}
				assetsPath={assetsPath}
				maxAvResTimeSlotDateRange={maxAvResTimeSlotDateRange}
			/>
		</>
	)
}

export default Filters
