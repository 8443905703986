import React from 'react'

// components
import Switch from '../../atoms/Switch/Switch'

// styles
import * as SC from './SwitchFormFieldStyles'

// types
import { SwitchFormFieldProps } from './types'

// component
const SwitchFormField = (props: SwitchFormFieldProps) => {
	const {
		label,
		input: { value, name, onChange },
		...switchProps
	} = props

	return (
		<SC.SwitchFieldLabel htmlFor={name}>
			<SC.SwitchFieldDescription>{label}</SC.SwitchFieldDescription>
			<Switch id={name} {...switchProps} checked={value} onChange={onChange} />
		</SC.SwitchFieldLabel>
	)
}

export default React.memo(SwitchFormField)
