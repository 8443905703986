/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import RcSwitch from 'rc-switch'

export const Switch = styled(RcSwitch)`
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	width: 34px;
	height: 14px;
	line-height: 14px;
	padding: 0;
	vertical-align: middle;
	border-radius: 999px;
	background-color: #e1e1e1;
	cursor: pointer;
	transition: all 0.3s ease;
	border: none;
	position: relative;
	outline: 2px solid transparent;
	transition: outline 0.3 ease;

	&::after {
		position: absolute;
		width: 20px;
		height: 20px;
		left: -3px;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 50%;
		background-color: #fff;
		content: '';
		cursor: pointer;
		box-shadow:
			0px 1px 1px rgba(0, 0, 0, 0.16),
			0px 3px 8px rgba(0, 0, 0, 0.15);
		transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
	}

	&:not(:disabled) {
		&:focus-visible {
			outline-color: #000;
		}
	}

	.rc-switch-label {
		display: inline-block;
		line-height: 20px;
		font-size: 14px;
		padding-left: 10px;
		vertical-align: middle;
		white-space: normal;
		pointer-events: none;
		user-select: text;
	}

	// checked
	&.rc-switch-checked {
		&::after {
			background-color: #000;
			box-shadow:
				0px 1px 1px 0px rgba(0, 0, 0, 0.16),
				0px 3px 8px 0px rgba(0, 0, 0, 0.15);
			left: 17px;
		}
	}

	// disabled
	&.rc-switch-disabled {
		cursor: default;
		background: #f1f1f1;

		&::after {
			background: #ababab;
			cursor: default;
		}
	}
`
