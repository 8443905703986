import styled from 'styled-components'
import DefaultContainer from '../../components/DefaultContainer/DefaultContainer'
import { MAX_WIDTHS, PAGE_TOP_PADDING } from '../../styles/constants'

export const Container = styled(DefaultContainer)`
	max-width: ${MAX_WIDTHS.salonsPage};
	margin-bottom: 80px;
`

export const SalonsPageWrapper = styled.div`
	padding-top: ${PAGE_TOP_PADDING}px;
	display: flex;
	gap: 32px;
`
