/* eslint-disable import/prefer-default-export */
import React from 'react'
import { Colors, Ratings } from '@notinosro/react-styleguide'
import { CheckboxGroupOption } from '../formFields/CheckboxGroupFormField/types'
import { GetSalonsFilterCountResponse } from '../types/types'
import { RATINGS } from './enums'
import { formatNumber } from './helper'

const RATING_VALUES = Object.values(RATINGS).filter((value) => typeof value === 'number') as number[]

export const EXACT_RATING_OPTIONS = (
	ratingCounts: GetSalonsFilterCountResponse['exactRating'] | undefined,
	selectedRating: number[]
): CheckboxGroupOption<number>[] => {
	return RATING_VALUES.reduce((acc, ratingValue) => {
		// hide ratings with 0 count (except selected)
		// if ratingCounts is undefined => show all ratings, but without counts
		if (ratingCounts && !ratingCounts[ratingValue] && !selectedRating.includes(ratingValue)) {
			return acc
		}

		const value = Number(ratingValue)

		return [
			...acc,
			{
				value,
				label: <Ratings foreground={Colors.basic} ratingId={`rating-${value}`} rating={value} />,
				description: ratingCounts ? formatNumber(ratingCounts[value] || 0) : undefined
			}
		]
	}, [] as CheckboxGroupOption<number>[])
}
