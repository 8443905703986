import React from 'react'

// styles
import * as SC from './CheckboxStyles'

// types
import { CheckboxProps } from './types'

const Checkbox = (props: CheckboxProps) => {
	const { checked, onChange, disabled, className, children } = props

	return (
		<SC.CheckboxLabel className={className} $disabled={disabled}>
			{children}
			<SC.CheckboxInput type={'checkbox'} checked={checked} onChange={onChange} disabled={disabled} />
			<SC.Checker />
		</SC.CheckboxLabel>
	)
}

export default Checkbox
