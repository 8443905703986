import React from 'react'

const AlarmCLockIcon = () => {
	return (
		<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M3.33594 2L1.33594 4M14.6693 4L12.6693 2M4.0026 12.6667L2.66927 14M12.0026 12.6667L13.3359 14M8.0026 6V8.66667L9.33594 10M8.0026 14C9.41709 14 10.7736 13.4381 11.7738 12.4379C12.774 11.4377 13.3359 10.0812 13.3359 8.66667C13.3359 7.25218 12.774 5.89562 11.7738 4.89543C10.7736 3.89524 9.41709 3.33333 8.0026 3.33333C6.58812 3.33333 5.23156 3.89524 4.23137 4.89543C3.23117 5.89562 2.66927 7.25218 2.66927 8.66667C2.66927 10.0812 3.23117 11.4377 4.23137 12.4379C5.23156 13.4381 6.58812 14 8.0026 14Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

export default AlarmCLockIcon
