import useWindowSize from './useWindowSize'

// useWindowSize hook
const useIsMobile = (mobileBreakpoint: number): boolean => {
	const windowSize = useWindowSize()
	const isMobile = windowSize?.width && windowSize.width < mobileBreakpoint
	return Boolean(isMobile)
}

export default useIsMobile
