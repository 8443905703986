import React from 'react'

// types
import { DatePickerFormFieldProps } from './types'
import { TimeSlotValueType } from '../../atoms/DatePicker/types'

// styles
import DatePicker from '../../atoms/DatePicker/DatePicker'

const DatePickerFormField = <TimeSlotValue extends string = TimeSlotValueType>(props: DatePickerFormFieldProps<TimeSlotValue>) => {
	const {
		input: { value, onChange },
		...restProps
	} = props
	return <DatePicker value={value} onChange={onChange} {...restProps} />
}

export default DatePickerFormField
