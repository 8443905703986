import React, { useCallback, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'

// utils
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { DEFAULT_DATE_FORMAT, FORM, OPENING_HOURS_STATUS, RESERVATIONS_TIME_SLOTS, SALONS_FILTER_TYPE, YEAR_MONTH_FORMAT } from '../../../../utils/enums'
import { DATE_PICKER_INIT_DATE, getCategoryIDs, isTimeSlotAvailable, SALONS_FILTER_ITEMS_CONFIG, TIME_SLOTS_TRANSLATIONS } from '../../../../utils/helper'
import { ApiContext } from '../../../../utils/apiProvider'

// hooks
import useMessages from '../../../../hooks/useMessages'

// component
import Drawer from '../../../../components/Drawer/Drawer'
import SidebarFooter from '../FilterSidebarFooter/FilterSidebarFooter'
import HookFormField from '../../../../formFields/HookFormField'
import DatePickerFormField from '../../../../formFields/DatePickerFormField/DatePickerFormField'

// types
import {
	FilterDateValuesType,
	FilterValuesType,
	AvailableRsTimeSlotsResponse,
	LoadSalonsFromFiltersParams,
	SalonsPageQueryType,
	ServiceTotalPriceCurrencyCode
} from '../../../../types/types'
import { RadioButtonOption } from '../../../../formFields/RadioButtonsFormField/types'

// assets
import ChevronDownLeft from '../../../../assets/icons/ChevronLeftIcon'

// styles
import * as SC from './FilterSidebarStyles'

// types
type FilterSidebarSortingProps = {
	filterSidebarDetailOpen: boolean
	setFilterSidebarDetailOpen: (open: boolean) => void
	filterDetailOpenedDirectly: boolean
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	submittedFilterValues: FilterValuesType
	setFilterValues: (newFilterValues: FilterDateValuesType) => void
	filterItemsConfig: ReturnType<typeof SALONS_FILTER_ITEMS_CONFIG>
	assetsPath: string
	query: SalonsPageQueryType
	currencyCode: ServiceTotalPriceCurrencyCode | undefined
	topLevelCategoryID: string | undefined
	maxAvResTimeSlotDateRange: number | undefined
}

// component
const FilterSidebarDate = (props: FilterSidebarSortingProps) => {
	const {
		filterSidebarDetailOpen,
		setFilterSidebarDetailOpen,
		filterDetailOpenedDirectly,
		loadSalonsFromFilters,
		submittedFilterValues,
		setFilterValues,
		filterItemsConfig,
		assetsPath,
		query,
		currencyCode,
		topLevelCategoryID,
		maxAvResTimeSlotDateRange
	} = props
	const { messages } = useMessages()
	const { formatMessage } = useIntl()
	const { apiBrowser } = useContext(ApiContext)

	const [availableRsTimeSlotData, setAvailableRsTimeSlotData] = useState<AvailableRsTimeSlotsResponse['dates']>([])
	const [loadingTimeSlotData, setLoadingTimeSlotData] = useState(true)

	// default filter values
	const dateDefaultValue = filterItemsConfig[SALONS_FILTER_TYPE.DATE].defaultValue

	// submitted filter values
	const submittedAvResTimeSlotDate = submittedFilterValues.availableReservations.timeSlot || dateDefaultValue.avResTimeSlotDate
	const submittedAvResTimeSlotDateFrom = submittedFilterValues.availableReservations.dateFrom || dateDefaultValue.avResTimeSlotDateFrom
	const submittedAvResTimeSlotDateTo = submittedFilterValues.availableReservations.dateTo || dateDefaultValue.avResTimeSlotDateTo

	// internal filter values - no submitted - just for components state
	const { control, handleSubmit, reset, getValues, watch, setValue } = useForm<FilterDateValuesType>({
		defaultValues: {
			availableReservations: {
				timeSlot: submittedAvResTimeSlotDate,
				dateFrom: submittedAvResTimeSlotDateFrom,
				dateTo: submittedAvResTimeSlotDateTo
			}
		}
	})

	const { timeSlot: avResTimeSlotDate, dateFrom: avResTimeSlotDateFrom, dateTo: avResTimeSlotDateTo } = watch('availableReservations')

	// check if current internal filter values are equal to default values
	// we will show "reset" buttons based on the check
	let isDateFilterEqualToDefault = true
	if (avResTimeSlotDateFrom || dateDefaultValue.avResTimeSlotDateFrom) {
		isDateFilterEqualToDefault = dateDefaultValue.avResTimeSlotDateFrom
			? !!avResTimeSlotDateFrom?.isSame(dateDefaultValue.avResTimeSlotDateFrom, 'date')
			: false
	}
	if (avResTimeSlotDateTo || dateDefaultValue.avResTimeSlotDateTo) {
		isDateFilterEqualToDefault = dateDefaultValue.avResTimeSlotDateTo ? !!avResTimeSlotDateTo?.isSame(dateDefaultValue.avResTimeSlotDateTo, 'date') : false
	}
	if (avResTimeSlotDate !== dateDefaultValue.avResTimeSlotDate) {
		isDateFilterEqualToDefault = false
	}

	// check if current internal filter values are equal to already submitted values
	// we will "show" footer with submit button based on those checks
	let isDateFilterPristine = true
	if (avResTimeSlotDateFrom || submittedAvResTimeSlotDateFrom) {
		isDateFilterPristine = submittedAvResTimeSlotDateFrom ? !!avResTimeSlotDateFrom?.isSame(submittedAvResTimeSlotDateFrom, 'date') : false
	}
	if (avResTimeSlotDateTo || submittedAvResTimeSlotDateTo) {
		isDateFilterPristine = submittedAvResTimeSlotDateTo ? !!avResTimeSlotDateTo?.isSame(submittedAvResTimeSlotDateTo, 'date') : false
	}
	if (avResTimeSlotDate !== submittedAvResTimeSlotDate) {
		isDateFilterPristine = false
	}

	useEffect(() => {
		// reinitialize internal filter values when submitted values changes to keep them in sync
		reset({
			availableReservations: {
				timeSlot: submittedAvResTimeSlotDate,
				dateFrom: submittedAvResTimeSlotDateFrom,
				dateTo: submittedAvResTimeSlotDateTo
			}
		})
	}, [submittedAvResTimeSlotDate, submittedAvResTimeSlotDateFrom, submittedAvResTimeSlotDateTo, reset])

	const handleSubmitFilterDetail = (formSubmitValues: FilterDateValuesType) => {
		if (filterDetailOpenedDirectly) {
			const params: LoadSalonsFromFiltersParams = {
				avResTimeSlotDate: formSubmitValues.availableReservations.timeSlot,
				avResTimeSlotDateFrom: formSubmitValues.availableReservations.dateFrom
					? formSubmitValues.availableReservations.dateFrom.format(DEFAULT_DATE_FORMAT)
					: null,
				avResTimeSlotDateTo: formSubmitValues.availableReservations.dateTo
					? formSubmitValues.availableReservations.dateTo.format(DEFAULT_DATE_FORMAT)
					: null
			}
			loadSalonsFromFilters(params)
		} else {
			setFilterValues({
				...formSubmitValues
			})
		}

		setFilterSidebarDetailOpen(false)
	}

	const resetDateFilterToDefault = () => {
		// NOTE: This is an exception compared to other sidebars; we will automatically reset values to default and close the sidebar afterwards
		handleSubmitFilterDetail({
			availableReservations: {
				timeSlot: dateDefaultValue.avResTimeSlotDate,
				dateFrom: dateDefaultValue.avResTimeSlotDateFrom,
				dateTo: dateDefaultValue.avResTimeSlotDateTo
			}
		})
		reset()
	}

	const closeSidebarDetail = () => {
		setFilterSidebarDetailOpen(false)
		reset()
	}

	const getFooterExtraContent = () => {
		const selectedDateTitle = filterItemsConfig[SALONS_FILTER_TYPE.DATE].getSelectedTitle(avResTimeSlotDate, avResTimeSlotDateFrom, avResTimeSlotDateTo)

		return selectedDateTitle ? <SC.DateFilterFooterLabel>{selectedDateTitle}</SC.DateFilterFooterLabel> : null
	}

	const dayPeriodTranslations = TIME_SLOTS_TRANSLATIONS(formatMessage)

	const getOptions = () => {
		const result: RadioButtonOption<RESERVATIONS_TIME_SLOTS>[] = [
			{
				id: RESERVATIONS_TIME_SLOTS.ANY,
				label: dayPeriodTranslations[RESERVATIONS_TIME_SLOTS.ANY]
			}
		]

		const now = dayjs()
		const isToday = avResTimeSlotDateFrom?.isSame(now, 'date') && avResTimeSlotDateTo === undefined
		const afternoon = dayjs(new Date(now.year(), now.month(), now.date(), 12, 0))
		const evening = dayjs(new Date(now.year(), now.month(), now.date(), 18, 0))

		if ((isToday && now.isBefore(afternoon)) || !isToday) {
			result.push({
				id: RESERVATIONS_TIME_SLOTS.MORNING,
				label: dayPeriodTranslations[RESERVATIONS_TIME_SLOTS.MORNING],
				description: formatMessage({ id: 'Until { time }', defaultMessage: 'Until { time }' }, { time: afternoon.format('LT') })
			})
		}

		if ((isToday && now.isBefore(evening)) || !isToday) {
			result.push({
				id: RESERVATIONS_TIME_SLOTS.AFTERNOON,
				label: dayPeriodTranslations[RESERVATIONS_TIME_SLOTS.AFTERNOON],
				description: formatMessage({ id: 'Until { time }', defaultMessage: 'Until { time }' }, { time: evening.format('LT') })
			})
		}

		result.push({
			id: RESERVATIONS_TIME_SLOTS.EVENING,
			label: dayPeriodTranslations[RESERVATIONS_TIME_SLOTS.EVENING],
			description: formatMessage({ id: 'After { time }', defaultMessage: 'After { time }' }, { time: evening.format('LT') })
		})

		return result
	}

	const timeSlotOptions = getOptions()

	const loadAvailableRsTimeSlotData = useCallback(
		async (selectedYear: number, selectedMonth: number, avResTimeSlotDayPart: RESERVATIONS_TIME_SLOTS): Promise<AvailableRsTimeSlotsResponse['dates']> => {
			setLoadingTimeSlotData(true)

			const categoryIDs = getCategoryIDs(query.categoryIDs, topLevelCategoryID)
			const date = dayjs(new Date(selectedYear, selectedMonth, 1)).format(YEAR_MONTH_FORMAT)
			let newTimeSlotData: AvailableRsTimeSlotsResponse['dates'] = []

			try {
				const response = await apiBrowser.getAvailableReservationTimeSlotsData({
					date,
					avResTimeSlotDayPart,
					categoryIDs,
					serviceTotalPriceFrom: submittedFilterValues.serviceTotalPriceRange.priceFrom,
					serviceTotalPriceTo: submittedFilterValues.serviceTotalPriceRange.priceTo,
					serviceTotalPriceCurrencyCode: currencyCode,
					openingHoursStatus: submittedFilterValues.openingHours ? OPENING_HOURS_STATUS.OPEN : OPENING_HOURS_STATUS.ALL,
					exactRating: submittedFilterValues.exactRating,
					languageIDs: submittedFilterValues.languageIDs,
					cosmeticIDs: submittedFilterValues.cosmeticIDs,
					latMy: query.latMy,
					lonMy: query.lonMy
				})
				if (response?.dates) {
					newTimeSlotData = response.dates
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
			} finally {
				setLoadingTimeSlotData(false)
			}
			return newTimeSlotData
		},
		[apiBrowser, currencyCode, submittedFilterValues, query.categoryIDs, query.latMy, query.lonMy, topLevelCategoryID]
	)

	// fetch data on filter open
	// pre-select today as selected date, but only if reservations are available
	useEffect(() => {
		;(async () => {
			if (filterSidebarDetailOpen) {
				const initStartDate = submittedAvResTimeSlotDateFrom || DATE_PICKER_INIT_DATE
				const timeSlotData = await loadAvailableRsTimeSlotData(initStartDate.year(), initStartDate.month(), submittedAvResTimeSlotDate)
				setAvailableRsTimeSlotData(timeSlotData)

				const isInitStartDateAvailable = isTimeSlotAvailable(timeSlotData, initStartDate)

				if (!submittedAvResTimeSlotDateFrom && isInitStartDateAvailable) {
					setValue('availableReservations', {
						timeSlot: submittedAvResTimeSlotDate,
						dateFrom: DATE_PICKER_INIT_DATE,
						dateTo: undefined
					})
				}
			}
		})()
	}, [filterSidebarDetailOpen, submittedAvResTimeSlotDateFrom, submittedAvResTimeSlotDate, setValue, loadAvailableRsTimeSlotData])

	useEffect(() => {
		// This useEffect handles the case when the user had selected a period, such as morning, which was available for the given date/range.
		// Then it switches to the current day, where this period is no longer available because the current time is past the period's time.
		if (!timeSlotOptions.find((option) => option.id === avResTimeSlotDate)) {
			setValue('availableReservations', {
				...getValues().availableReservations,
				timeSlot: dateDefaultValue.avResTimeSlotDate
			})
		}

		// It's sufficient to monitor changes in the array length; there's no need to trigger the useEffect on every reference change.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeSlotOptions.length, dateDefaultValue.avResTimeSlotDate, getValues, avResTimeSlotDate])

	const onChangeView = async (newYear: number, newMonth: number) => {
		const newData = await loadAvailableRsTimeSlotData(newYear, newMonth, avResTimeSlotDate)
		setAvailableRsTimeSlotData(newData)
	}

	const onChangeDayPeriod = async (newPeriod: RESERVATIONS_TIME_SLOTS, selectedYear: number, selectedMonth: number) => {
		// get new time slot data for current calendar view
		const timeSlotDataCurrentView = await loadAvailableRsTimeSlotData(selectedYear, selectedMonth, newPeriod)
		setAvailableRsTimeSlotData(timeSlotDataCurrentView)

		// but we also need to check current selected days if they are still available with the new selected period
		let timeSlotDataStartDate: AvailableRsTimeSlotsResponse['dates'] | undefined
		let timeSlotDataEndDate: AvailableRsTimeSlotsResponse['dates'] | undefined
		const currentViewDate = dayjs(new Date(selectedYear, selectedMonth, 1))
		if (avResTimeSlotDateFrom) {
			if (avResTimeSlotDateFrom.isSame(currentViewDate, 'month')) {
				timeSlotDataStartDate = timeSlotDataCurrentView
			} else {
				timeSlotDataStartDate = await loadAvailableRsTimeSlotData(avResTimeSlotDateFrom.year(), avResTimeSlotDateFrom.month(), newPeriod)
			}
		}
		if (avResTimeSlotDateTo) {
			if (avResTimeSlotDateTo.isSame(currentViewDate, 'month')) {
				timeSlotDataEndDate = timeSlotDataCurrentView
			} else if (avResTimeSlotDateFrom && avResTimeSlotDateTo.isSame(avResTimeSlotDateFrom, 'month') && timeSlotDataStartDate) {
				timeSlotDataEndDate = timeSlotDataStartDate
			} else {
				timeSlotDataEndDate = await loadAvailableRsTimeSlotData(avResTimeSlotDateTo.year(), avResTimeSlotDateTo.month(), newPeriod)
			}
		}

		const isStartDateStillAvailable =
			timeSlotDataStartDate && avResTimeSlotDateFrom ? isTimeSlotAvailable(timeSlotDataStartDate, avResTimeSlotDateFrom) : true
		const isEndDateStillAvailable = timeSlotDataEndDate && avResTimeSlotDateTo ? isTimeSlotAvailable(timeSlotDataEndDate, avResTimeSlotDateTo) : true

		if (!isStartDateStillAvailable || !isEndDateStillAvailable) {
			setValue('availableReservations', {
				...getValues().availableReservations,
				dateFrom: isStartDateStillAvailable ? avResTimeSlotDateFrom : undefined,
				dateTo: isEndDateStillAvailable ? avResTimeSlotDateTo : undefined
			})
		}
	}

	return (
		<Drawer
			open={filterSidebarDetailOpen}
			mask
			maskClosable
			// if the detail is open from the main filter sidebar, it already has a mask, so we need to set opacity of detail sidebar mask to zero, so they don't overlap visually
			maskStyle={!filterDetailOpenedDirectly ? { opacity: 0 } : undefined}
			closeIcon={!filterDetailOpenedDirectly ? <ChevronDownLeft /> : undefined}
			title={filterItemsConfig[SALONS_FILTER_TYPE.DATE].title}
			onClose={closeSidebarDetail}
			headerExtra={
				!isDateFilterEqualToDefault ? <SC.HeaderResetButton onClick={resetDateFilterToDefault}>{messages?.Reset}</SC.HeaderResetButton> : undefined
			}
			footer={
				<SidebarFooter
					formId={FORM.FILTER_SIDEBAR_DATE}
					isVisible={!(isDateFilterPristine || !avResTimeSlotDateFrom)}
					extraContent={getFooterExtraContent()}
					isSlider={false}
				/>
			}
			destroyOnClose
		>
			<SC.DateFilterFetchResult isRefetching={loadingTimeSlotData}>
				<SC.SidebarForm id={FORM.FILTER_SIDEBAR_DATE} onSubmit={handleSubmit(handleSubmitFilterDetail)}>
					<HookFormField
						control={control}
						name={'availableReservations'}
						assetsPath={assetsPath}
						component={DatePickerFormField<RESERVATIONS_TIME_SLOTS>}
						availableDates={availableRsTimeSlotData}
						disabled={loadingTimeSlotData}
						onChangeDayPeriod={onChangeDayPeriod}
						onChangeView={onChangeView}
						timeSlotOptions={timeSlotOptions}
						maxRange={maxAvResTimeSlotDateRange}
					/>
				</SC.SidebarForm>
			</SC.DateFilterFetchResult>
		</Drawer>
	)
}

export default FilterSidebarDate
