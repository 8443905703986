import React from 'react'
import useMessages from '../../../../hooks/useMessages'
import { SalonDetail } from '../../../../types/types'
import * as SC from './SalonAboutUsStyles'
import { MobileOnly } from '../../../../styles/helpers'
import { SALON_PAGE_MOBILE_BREAKPOINT_KEY } from '../../../../styles/constants'

type Props = {
	salonData?: SalonDetail
	assetsPath: string
}

const SalonAboutUsDesktop = (props: Props) => {
	const { salonData } = props
	const { messages } = useMessages()

	return (
		<SC.Container>
			{/* about us */}
			{salonData?.aboutUsFirst && (
				<MobileOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
					<SC.AboutUsText>{salonData?.aboutUsFirst}</SC.AboutUsText>
				</MobileOnly>
			)}

			{/* employees avatars */}
			{salonData?.employees && salonData?.employees.length > 0 && (
				<SC.EnumsContainer>
					<SC.EnumsTitleText>{messages?.Team}</SC.EnumsTitleText>
					<SC.EnumsContent>
						{salonData?.employees?.map((employee) => (
							<SC.EnumsItem key={employee?.id}>
								<SC.EnumsItemImage loading='lazy' alt={employee?.firstName} src={employee?.image?.resizedImages?.thumbnail} $isRounded={true} />
								{employee?.firstName}
							</SC.EnumsItem>
						))}
					</SC.EnumsContent>
				</SC.EnumsContainer>
			)}

			{/* cosmetics */}
			{salonData?.cosmetics && salonData?.cosmetics.length > 0 && (
				<SC.EnumsContainer>
					<SC.EnumsTitleText>{messages?.['We use cosmetics']}</SC.EnumsTitleText>
					<SC.EnumsContent>
						{salonData?.cosmetics?.map((cosmetic) => (
							<SC.EnumsItem key={cosmetic?.id}>
								{cosmetic?.image?.resizedImages?.thumbnail ? (
									<SC.EnumsItemImage loading='lazy' alt={cosmetic?.name} src={cosmetic?.image?.resizedImages?.thumbnail} />
								) : (
									<span>{cosmetic?.name}</span>
								)}
							</SC.EnumsItem>
						))}
					</SC.EnumsContent>
				</SC.EnumsContainer>
			)}

			{/* languages */}
			{salonData?.languages && salonData?.languages.length > 0 && (
				<SC.EnumsContainer>
					<SC.EnumsTitleText>{messages?.['We speak']}</SC.EnumsTitleText>
					<SC.EnumsContent>
						{salonData?.languages?.map((language) => (
							<SC.LanguageContainer key={language?.id}>
								{language?.flag?.resizedImages?.thumbnail && (
									<SC.LanguageFlag>
										<img loading='lazy' alt={language?.name} src={language?.flag?.resizedImages?.thumbnail} />
									</SC.LanguageFlag>
								)}
								<SC.LanguageTitle>{language?.name}</SC.LanguageTitle>
							</SC.LanguageContainer>
						))}
					</SC.EnumsContent>
				</SC.EnumsContainer>
			)}
		</SC.Container>
	)
}

export default SalonAboutUsDesktop
