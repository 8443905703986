import { Spinner as NotinoSpinner } from '@notinosro/react-styleguide'
import styled, { css } from 'styled-components'

export const FetchResultWrapper = styled.div``

export const StateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 400px;
	color: #000;
	gap: 16px;
`

export const EmptyIconWrapper = styled.div`
	display: inline-block;
	width: 90px;
	height: 90px;
	color: #000;
`

export const StateLabel = styled.p`
	text-align: center;
	max-width: 280px;
`

export const SpinWrapper = styled.div`
	position: relative;
`

export const SpinnerWrapper = styled.div`
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	z-index: 4;
	display: block;
	width: 100%;
	height: 100%;
	max-height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const SpinContent = styled.div<{ $isRefetching?: boolean }>`
	position: relative;
	z-index: 3;

	${({ $isRefetching }) =>
		$isRefetching &&
		css`
			user-select: none;
			&::after {
				position: absolute;
				top: 0;
				inset-inline-end: 0;
				bottom: 0;
				inset-inline-start: 0;
				z-index: 10;
				width: 100%;
				height: 100%;
				background: #fff;
				opacity: 0.5;
				transition: all 0.3s;
				content: '';
			}
		`}
`

export const Spinner = styled(NotinoSpinner)``
