import React from 'react'

// styles
import * as SC from './TagStyles'

type Props = {
	label: React.ReactNode
	disabled?: boolean
	count?: number
	onCloseBtnClick?: () => void
}

const Tag = (props: Props) => {
	const { label, onCloseBtnClick, count, disabled } = props

	return (
		<SC.Tag>
			<SC.Label>{count !== undefined ? `${label} (${count})` : label}</SC.Label>

			<SC.CloseButton
				onClick={(e) => {
					e.stopPropagation()
					if (onCloseBtnClick) {
						onCloseBtnClick()
					}
				}}
				disabled={disabled}
			>
				<SC.CloseIcon />
			</SC.CloseButton>
		</SC.Tag>
	)
}

export default Tag
