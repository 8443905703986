import React from 'react'

// styles
import * as SC from './ChipStyles'

// types
import { ChipProps } from './types'

const Chip = (props: ChipProps) => {
	const { label, isMainButtonChip, isSelected, count, onClick, onCloseBtnClick, disabled } = props

	return (
		<SC.Chip
			$isSelected={isSelected}
			$isMainButtonChip={isMainButtonChip}
			onClick={(e) => {
				e.stopPropagation()
				onClick()
			}}
			disabled={disabled}
		>
			<SC.Label>{label}</SC.Label>
			{isSelected && count && count > 1 ? <SC.Count $disabled={disabled}>{`+${count}`}</SC.Count> : null}
			{!isMainButtonChip && isSelected && (
				<SC.CloseButton
					onClick={(e) => {
						if (onCloseBtnClick) {
							e.stopPropagation()
							onCloseBtnClick()
						}
					}}
					$disabled={disabled}
				>
					<SC.CloseIcon />
				</SC.CloseButton>
			)}
			{isMainButtonChip && <SC.SelectedBadge $isSelected={isSelected} $disabled={disabled} />}
		</SC.Chip>
	)
}

export default Chip
