import React, { useEffect, useMemo } from 'react'

// utils
import { useForm } from 'react-hook-form'
import { FORM, SALONS_FILTER_TYPE } from '../../../../utils/enums'
import { areValuesInArrayEqual, SALONS_FILTER_ITEMS_CONFIG } from '../../../../utils/helper'

// hooks
import useMessages from '../../../../hooks/useMessages'

// components
import Drawer from '../../../../components/Drawer/Drawer'
import SidebarFooter from '../FilterSidebarFooter/FilterSidebarFooter'
import Tag from '../../../../atoms/Tag/Tag'
import HookFormField from '../../../../formFields/HookFormField'
import CheckboxGroupFormField from '../../../../formFields/CheckboxGroupFormField/CheckboxGroupFormField'

// types
import { FilterExactRatingValuesType, FilterValuesType, GetSalonsFilterCountResponse, LoadSalonsFromFiltersParams } from '../../../../types/types'

// assets
import ChevronDownLeft from '../../../../assets/icons/ChevronLeftIcon'

// utils
import { EXACT_RATING_OPTIONS } from '../../../../utils/tsxHelper'

// styles
import * as SC from './FilterSidebarStyles'

// types

type FilterSidebarExactRatingProps = {
	filterSidebarDetailOpen: boolean
	setFilterSidebarDetailOpen: (open: boolean) => void
	filterDetailOpenedDirectly: boolean
	loadSalonsFromFilters: (params: LoadSalonsFromFiltersParams) => void
	submittedFilterValues: FilterValuesType
	setFilterValues: (newFilterValues: FilterExactRatingValuesType) => void
	filterItemsConfig: ReturnType<typeof SALONS_FILTER_ITEMS_CONFIG>
	ratingCounts: GetSalonsFilterCountResponse['exactRating'] | undefined
}

// component
const FilterSidebarExactRating = (props: FilterSidebarExactRatingProps) => {
	const {
		filterSidebarDetailOpen,
		setFilterSidebarDetailOpen,
		filterDetailOpenedDirectly,
		loadSalonsFromFilters,
		submittedFilterValues,
		setFilterValues,
		filterItemsConfig,
		ratingCounts
	} = props
	const { messages } = useMessages()

	const exactRatingDefaultValue = filterItemsConfig[SALONS_FILTER_TYPE.RATING].defaultValue

	// submitted filter values
	const submittedExactRating = useMemo(
		() => submittedFilterValues.exactRating || exactRatingDefaultValue,
		[submittedFilterValues.exactRating, exactRatingDefaultValue]
	)

	// internal filter values - no submitted - just for components state
	const {
		control,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isDirty }
	} = useForm<FilterExactRatingValuesType>({
		defaultValues: {
			exactRating: submittedFilterValues.exactRating
		}
	})

	const exactRating = watch('exactRating')

	// check if current internal filter values are equal to default values
	// we will show "reset" buttons based on the check
	const isExactRatingFilterEqualToDefault = areValuesInArrayEqual(exactRating, exactRatingDefaultValue)

	useEffect(() => {
		reset({ exactRating: submittedExactRating })
	}, [submittedExactRating, reset])

	const handleSubmitFilterDetail = (formSubmitValues: FilterExactRatingValuesType) => {
		if (filterDetailOpenedDirectly) {
			loadSalonsFromFilters({
				exactRating: formSubmitValues.exactRating
			})
		} else {
			setFilterValues({ exactRating: formSubmitValues.exactRating })
		}

		setFilterSidebarDetailOpen(false)
	}

	const resetExactRatingFilterToDefault = () => {
		setValue('exactRating', exactRatingDefaultValue, { shouldDirty: true })
	}

	const closeSidebarDetail = () => {
		setFilterSidebarDetailOpen(false)
		reset()
	}

	const getFooterSliderItems = () => {
		let ratingSliderContent = null
		const selectedRatingTitle = filterItemsConfig[SALONS_FILTER_TYPE.RATING].getSelectedTitle(exactRating)
		if (selectedRatingTitle) {
			ratingSliderContent = <Tag label={selectedRatingTitle} onCloseBtnClick={resetExactRatingFilterToDefault} />
		}
		return ratingSliderContent
	}

	return (
		<Drawer
			open={filterSidebarDetailOpen}
			mask
			maskClosable
			// if the detail is open from the main filter sidebar, it already has a mask, so we need to set opacity of detail sidebar mask to zero, so they don't overlap visually
			maskStyle={!filterDetailOpenedDirectly ? { opacity: 0 } : undefined}
			closeIcon={!filterDetailOpenedDirectly ? <ChevronDownLeft /> : undefined}
			title={filterItemsConfig[SALONS_FILTER_TYPE.RATING].title}
			onClose={closeSidebarDetail}
			headerExtra={
				!isExactRatingFilterEqualToDefault ? (
					<SC.HeaderResetButton onClick={resetExactRatingFilterToDefault}>{messages?.Reset}</SC.HeaderResetButton>
				) : undefined
			}
			footer={<SidebarFooter formId={FORM.FILTER_SIDEBAR_EXACT_RATING} isVisible={isDirty} extraContent={getFooterSliderItems()} />}
			destroyOnClose
		>
			<SC.SidebarDetailContent>
				<SC.SidebarForm id={FORM.FILTER_SIDEBAR_EXACT_RATING} onSubmit={handleSubmit(handleSubmitFilterDetail)}>
					<HookFormField
						control={control}
						name={'exactRating'}
						component={CheckboxGroupFormField}
						options={EXACT_RATING_OPTIONS(ratingCounts, exactRating)}
					/>
				</SC.SidebarForm>
			</SC.SidebarDetailContent>
		</Drawer>
	)
}

export default FilterSidebarExactRating
