/* eslint-disable import/prefer-default-export */
import { styled, theme } from '@notinosro/react-styleguide'
import { NOTINO_MAIN_APP_PADDING } from '../../../../styles/constants'

export const GalleryMobileContainer = styled.div`
	margin-left: -${NOTINO_MAIN_APP_PADDING}px;
	margin-right: -${NOTINO_MAIN_APP_PADDING}px;

	/*
    NOTE: following classes come from react-image-gallery library
    */

	.image-gallery-content .image-gallery-slide .image-gallery-image {
		width: 100%;
		aspect-ratio: 1.25;
		object-fit: cover;
		max-height: 400px;
	}

	.image-gallery-bullets {
		.image-gallery-bullet {
			padding: 3px;
			&:hover {
				background: ${theme.palette.basic};
				border-color: ${theme.palette.basicInverse};

				.active {
					background: ${theme.palette.basic};
					border-color: ${theme.palette.basicInverse};
				}
			}
		}
	}
`
