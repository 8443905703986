/* eslint-disable global-require */
import styled, { css } from 'styled-components'

// utils
import { NotinoBodyLarge, NotinoBodySmall, NotinoTitleSmall } from '../../styles/helpers'

export const DialogMessage = styled.p`
	margin-bottom: 0 !important; // NOTE: !important overrides Notino main app heading global styles
`

export const DatePickerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	transform: translateY(-10px);
	background-color: #fff;
	margin-bottom: 20px;
	margin: 0 auto;
`

export const Year = styled.span`
	${NotinoBodySmall};
	color: #808080;
	margin: 0 auto;
	display: inline-block;
`

export const SliderWrapper = styled.div`
	height: 40px;
	padding: 10px 0;
	background: #fff;
	position: relative;
	width: 100%;
	margin-bottom: 10px;

	&::before {
		position: absolute;
		content: '';
		width: 60px;
		height: 100%;
		left: 0;
		top: 0;
		background: linear-gradient(to right, #fff, transparent);
		z-index: 2;
	}

	&::after {
		position: absolute;
		content: '';
		width: 60px;
		height: 100%;
		right: 0;
		top: 0;
		background: linear-gradient(to left, #fff, transparent);
		z-index: 2;
	}

	/* Arrows */
	.slick-prev,
	.slick-next {
		display: none !important;
	}

	/* Slider */
	.slick-slider {
		position: relative;
		display: block;
		box-sizing: border-box;
		user-select: none;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
		transform: translate3d(0, 0, 0);

		&:focus {
			outline: none;
		}

		&.dragging {
			cursor: pointer;
			cursor: hand;
		}
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: flex;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		transform: translate3d(0, 0, 0);

		&::before,
		&::after {
			display: table;
			content: '';
		}

		&::after {
			clear: both;
		}
	}

	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;
		min-height: 1px;
		cursor: pointer;

		img {
			display: block;
		}

		&.slick-loading {
			img {
				display: none;
			}
		}

		&.dragging {
			img {
				pointer-events: none;
			}
		}
	}

	.slick-arrow.slick-hidden {
		display: none;
	}

	.slick-loading {
		.slick-list {
			background: #fff center center no-repeat;
		}

		.slick-slide {
			visibility: hidden;
		}
	}

	.slick-initialized {
		.slick-slide {
			display: block;
		}
	}

	.slick-vertical {
		.slick-slide {
			display: block;
			height: auto;
			border: 1px solid transparent;
		}
	}
`

export const SliderButton = styled.button<{ $isNext?: boolean; $assetsPath: string }>`
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: 2px solid transparent;
	background: transparent;
	z-index: 3;
	transition: outline 0.3s ease;

	&::before {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
	}

	&:not(:disabled) {
		&:focus-visible {
			outline-color: #000;
		}
	}

	&:disabled {
		cursor: default;
		&::before {
			opacity: 0.3;
		}
	}

	${({ $isNext, $assetsPath }) =>
		$isNext
			? css`
					right: -10px;
					&::before {
						content: url('${`${$assetsPath}/${require('../../assets/icons/chevron-right.svg')}`}');
					}
				`
			: css`
					left: -10px;
					&::before {
						content: url('${`${$assetsPath}/${require('../../assets/icons/chevron-left.svg')}`}');
					}
				`}
`

export const SliderItem = styled.div<{ $isActive?: boolean; $isDisabled?: boolean }>`
	color: #808080;
	${NotinoBodyLarge};
	padding: 0 15px;
	text-transform: capitalize;

	${({ $isActive }) =>
		$isActive &&
		css`
			color: #000;
			font-weight: 500;
		`}

	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			color: #bfbfbf;
		`}
`

export const CalendarWrapper = styled.div`
	overflow: auto;
	width: 100%;
	min-width: 280px;
`
export const CalendarGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-row-gap: 6px;
	width: 100%;
	justify-content: space-between;
	overflow: hidden;
`

export const HeaderCell = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	${NotinoTitleSmall};
	color: #9f9f9f;
	text-transform: capitalize;
`

export const DayCell = styled.div<{ $gridShift: number | undefined; $isStartOfTheRange: boolean; $isEndOfTheRange: boolean; $isBetweenRanges: boolean }>`
	${({ $gridShift, $isBetweenRanges, $isStartOfTheRange, $isEndOfTheRange }) => css`
		text-align: center;
		height: 40px;
		width: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		background-color: transparent;

		${$gridShift &&
		css`
			grid-column-start: ${$gridShift};
		`}

		${$isBetweenRanges &&
		css`
			background-color: #e6e6e6;
		`}

	${$isStartOfTheRange &&
		css`
			&::after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				right: -20px;
				background-color: #e6e6e6;
				z-index: 1;
			}
		`}

		${$isEndOfTheRange &&
		css`
			&::after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 50%;
				background: #e6e6e6;
				z-index: 1;
			}
		`}
	`}
`

export const DayCellButton = styled.div<{
	$isAvailable: boolean
	$isSelected: boolean
	$isToday: boolean
}>`
	${({ $isAvailable, $isSelected, $isToday }) => css`
		width: 40px;
		height: 40px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		cursor: pointer;
		font-size: 1.25rem;
		line-height: 1.5rem;
		font-weight: 300;
		color: #404040;
		position: relative;
		z-index: 2;
		background-color: transparent;
		border: 1px solid transparent;

		${$isSelected &&
		css`
			background: #000;
			border-color: #000;
			color: #fff;
		`}

		${$isToday &&
		css`
			border-color: #000;
		`}

		${!$isAvailable &&
		css`
			color: #9f9f9f;
			cursor: default;

			&::after {
				position: absolute;
				content: '';
				height: 1px;
				width: 24px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: #9f9f9f;
			}
		`}
	`}
`
