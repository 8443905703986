import { SkeletonElement } from '@notinosro/react-styleguide'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import styled from 'styled-components'

// utils
import { SALONS_PAGE_MOBILE_BREAKPOINT } from '../../../../styles/constants'

export const Divider = styled.hr`
	margin: 8px 0;
	border: 0;
	height: 1px;
	background: #e6e6e6;

	@media (min-width: ${SALONS_PAGE_MOBILE_BREAKPOINT}) {
		margin: 16px 0;
	}
`

export const ChipsSlider = styled(OverlayScrollbarsComponent)`
	overflow: auto;
	scroll-behavior: smooth;
	touch-action: pan-y;
`

export const ChipsContent = styled.div`
	display: flex;
	gap: 8px;
	white-space: nowrap;
	padding: 4px 0;
`

export const Skeleton = styled(SkeletonElement)`
	border-radius: 999px;
	flex: 0 0 auto;
`
