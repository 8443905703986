import React from 'react'

const NavigationIcon = () => {
	return (
		<svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M2.75 8.25L17 1.5L10.25 15.75L8.75 9.75L2.75 8.25Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	)
}

export default NavigationIcon
