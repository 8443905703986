import React from 'react'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import * as SC from './GalleryMobileStyles'

// styles for image-gallery
import 'react-image-gallery/styles/css/image-gallery.css'

// types
import { Image } from '../../../../types/types'

type Props = {
	images: Image[]
	assetsPath: string
}

const GalleryMobile = (props: Props) => {
	const { images, assetsPath } = props

	const sliderImages: ReactImageGalleryItem[] = images?.map((image) => {
		return {
			original: image.resizedImages.medium,
			srcSet: `
                ${image.resizedImages.small} 480w,
                ${image.resizedImages.medium} 1080w,
                ${image.resizedImages.large} 1500w
            `,
			sizes: `
                (max-width: 480px) 480px,
                (max-width: 1080px) 1080px,
                1500px
            `
		}
	})
	// add placeholder if salon dont have any images
	if (sliderImages.length <= 0) {
		sliderImages.push({
			// eslint-disable-next-line global-require
			original: `${assetsPath}/${require('../../../../assets/images/basic-salon-placeholder.png')}`
		})
	}

	return (
		<SC.GalleryMobileContainer>
			<ImageGallery
				lazyLoad={true}
				items={sliderImages}
				showNav={false}
				showPlayButton={false}
				showThumbnails={false}
				showFullscreenButton={false}
				showBullets
			/>
		</SC.GalleryMobileContainer>
	)
}

export default GalleryMobile
