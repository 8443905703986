import React from 'react'
import { Button, ButtonModel } from '@notinosro/react-styleguide'
import useMessages from '../../../../hooks/useMessages'
import { SALON_PAGE_MOBILE_BREAKPOINT_KEY } from '../../../../styles/constants'
import { MobileOnly } from '../../../../styles/helpers'
import { ConfigResponse, SalonDetail } from '../../../../types/types'
import { getPhoneNumber } from '../../../../utils/helper'
import * as SC from './CtaButtonsStyles'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import { getAddToCartEvent } from '../../../../utils/dataLayerEvents'

type Props = {
	salonData: SalonDetail
	countriesData: ConfigResponse['rolloutCountries']
	openReservationModal: () => void
	googleMapLink: string | undefined
}

const CtaButtons = (props: Props) => {
	const { messages } = useMessages()

	const { salonData, countriesData, openReservationModal, googleMapLink } = props

	const phoneHref =
		salonData?.phones && salonData?.phones.length > 0 && countriesData && countriesData.length > 0
			? `tel:${getPhoneNumber(salonData.phones[0], countriesData)}`
			: undefined

	return (
		<SC.CtaButtons>
			<Button
				buttonStyle={ButtonModel.Styles.primary}
				onClick={() => {
					openReservationModal()
					// push to dataLayer
					const event = getAddToCartEvent(salonData)
					pushToDataLayer(event)
				}}
			>
				{salonData?.availableReservationSystem ? messages?.Reserve : messages?.Services}
			</Button>
			{phoneHref && (
				<MobileOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
					<SC.Button href={phoneHref} buttonStyle={ButtonModel.Styles.secondary}>
						{messages?.Call}
					</SC.Button>
				</MobileOnly>
			)}
			{googleMapLink && (
				<SC.Button href={googleMapLink} target='_blank' buttonStyle={ButtonModel.Styles.secondary}>
					{messages?.['Show on map']}
				</SC.Button>
			)}
		</SC.CtaButtons>
	)
}

export default CtaButtons
