import styled, { css } from 'styled-components'
import { theme, Button } from '@notinosro/react-styleguide'
import { NotinoBodyLarge, NotinoBodyMedium, NotinoBodyRegular, NotinoBodySmall, NotinoLabelLarge, resetButtonStyles } from '../../../../styles/helpers'

export const Section = styled.div<{ $paddingTop?: number }>`
	padding-bottom: 24px;
	padding-top: ${(p) => p.$paddingTop ?? 24}px;

	&:not(:last-child) {
		border-bottom: 1px solid ${theme.palette.neutralLight};
	}
`

export const SectionHeading = styled.div<{ $marginBottom?: number }>`
	${NotinoLabelLarge};
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: ${(p) => p.$marginBottom ?? 16}px;
`

export const AboutUsText = styled.p<{ $isClamped?: boolean }>`
	${NotinoBodyRegular};

	${(p) =>
		p.$isClamped &&
		css`
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
		`}
`

export const AboutUsShowMoreButton = styled.button`
	${resetButtonStyles};
	font-family: inherit;

	${NotinoBodyMedium};
	margin-top: 28px;
`

export const EnumsContent = styled.div`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	gap: 28px;
	margin-top: 20px;
`

export const EnumsItem = styled.div`
	${theme.typography.bodyRegular}

	width: 80px;
	text-align: center;
	flex-shrink: 0;
	padding-bottom: 4px; /* gap to (potential) horizontal scrollbar */
	display: grid;
	place-items: center;

	& > img {
		aspect-ratio: 1;
		object-fit: contain;
	}
`

export const LanguageContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 8px;
	padding-bottom: 4px; /* gap to (potenatial) horizontal scrollbar */
`

export const LanguageFlag = styled.div`
	width: 24px;
	height: 16px;
	display: flex;
	align-items: center;

	img {
		vertical-align: unset !important; // overwriting notino default style
	}
`

export const LanguageTitle = styled.div`
	${theme.typography.bodyLarge}
	white-space: nowrap;
`

export const MapContainer = styled.div`
	position: relative; // needed for GoogleMapComponent
	aspect-ratio: 2;
	margin-bottom: 20px;
`

export const Address = styled.div`
	${NotinoBodyLarge};
	margin-bottom: 16px;
`

export const AddressDescription = styled.p`
	${NotinoBodySmall};
	color: ${theme.palette.neutralDark};
	margin-bottom: 16px;
`

export const LinkButton = styled(Button)`
	// fix for notino button when using href
	display: flex;
	align-items: center;

	justify-content: center;
	gap: 8px;
`

export const ParkingDescription = styled.p`
	${NotinoBodySmall};
	color: ${theme.palette.neutralDark};
`

export const MailLink = styled.a`
	color: inherit;
	text-decoration: none;
`
