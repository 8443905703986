import React, { useState } from 'react'
import Tabs, { TabsProps } from 'rc-tabs'
import useMessages from '../../../../hooks/useMessages'
import { ConfigResponse, SalonDetail, SalonReviewsResponse, SalonServicesResponse } from '../../../../types/types'
import { getFirstLevelCategoriesNames, getGoogleMapsLink } from '../../../../utils/helper'
import GalleryDesktop from '../GalleryDesktop/GalleryDesktop'
import GalleryMobile from '../GalleryMobile/GalleryMobile'
import * as SC from './ProfiSalonStyles'
import SalonAboutUsDesktop from '../SalonAboutUs/SalonAboutUs'
import SalonContacts from '../SalonContacts/SalonContacts'
import SalonDetailCard from '../SalonDetailCard/SalonDetailCard'
import SalonPageReviews from '../SalonPageReviews/SalonPageReviews'
import SalonPricelists from '../SalonPricelists/SalonPricelists'
import SalonServicesCollapse from '../SalonServicesCollapse/SalonServicesCollapse'
import SalonServicesTabs from '../SalonServicesTabs/SalonServicesTabs'
import ProfiSalonHeader from '../ProfiSalonHeader/ProfiSalonHeader'
import { DesktopOnly, MobileOnly } from '../../../../styles/helpers'
import { SALON_PAGE_MOBILE_BREAKPOINT_KEY } from '../../../../styles/constants'
import OpeningHours from '../OpeningHours/OpeningHours'
import SalonAboutUsMobile from '../SalonAboutUsMobile/SalonAboutUsMobile'
import CtaButtons from '../CtaButtons/CtaButtons'

type Props = {
	salonData: SalonDetail
	countriesData: ConfigResponse['rolloutCountries']
	salonServicesData: SalonServicesResponse
	salonReviewsData: SalonReviewsResponse | undefined
	assetsPath: string
	googleMapsApiKey: string
	googleMapsUrl: string
	openReservationModal: () => void
}

const ProfiSalon = (props: Props) => {
	const { messages } = useMessages()

	const { salonData, countriesData, salonServicesData, salonReviewsData, assetsPath, googleMapsApiKey, googleMapsUrl, openReservationModal } = props

	const [tabKey, setTabKey] = useState<string>('overview')

	const renderServices = salonServicesData && salonServicesData?.groupedServicesByCategory?.length > 0
	const renderPricelists = salonData?.pricelists && salonData?.pricelists.length > 0
	const renderReviews = salonData?.ratingsData && salonData?.ratingsData?.rating

	const salonServices = getFirstLevelCategoriesNames(salonServicesData)

	const googleMapLink = getGoogleMapsLink({ googleMapsUrl, lat: salonData?.address?.latitude, lon: salonData?.address?.longitude })

	const getTabsItems = () => {
		const tabsItems: TabsProps['items'] = [
			{
				key: 'overview',
				label: messages?.Overview,
				children: (
					<>
						{salonData?.openingHoursData && (
							<SC.OpeningHoursWrapper>
								<OpeningHours openingHours={salonData?.openingHoursData} />
							</SC.OpeningHoursWrapper>
						)}
						{renderServices && (
							<SC.ServicesWrapper>
								<SalonServicesCollapse services={salonServicesData} />
							</SC.ServicesWrapper>
						)}
						<SC.AboutUsWrapper>
							<SalonAboutUsMobile
								salonData={salonData}
								countriesData={countriesData}
								assetsPath={assetsPath}
								googleMapsApiKey={googleMapsApiKey}
								googleMapsUrl={googleMapsUrl}
								shouldClampDescription={true}
							/>
						</SC.AboutUsWrapper>
					</>
				)
			}
		]

		if (renderReviews) {
			tabsItems.push({
				key: 'reviews',
				label: messages?.Reviews,
				children: <SalonPageReviews salonData={salonData} reviewsData={salonReviewsData} />
			})
		}

		tabsItems.push({
			key: 'about-us',
			label: messages?.['About us'],
			children: (
				<SalonAboutUsMobile
					salonData={salonData}
					countriesData={countriesData}
					assetsPath={assetsPath}
					googleMapsApiKey={googleMapsApiKey}
					googleMapsUrl={googleMapsUrl}
					hideFirstSectionHeading
					hideTeamSection
				/>
			)
		})

		if (renderPricelists) {
			tabsItems.push({
				key: 'pricelists',
				label: messages?.['Price list'],
				children: <SalonPricelists pricelists={salonData?.pricelists} hideHeading />
			})
		}

		return tabsItems
	}

	return (
		<div>
			{/* desktop header */}
			<DesktopOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<ProfiSalonHeader
					name={salonData?.name}
					logoUrl={salonData?.logo?.resizedImages?.small}
					address={salonData?.formattedAddress}
					googleMapLink={googleMapLink}
					ratingsData={salonData?.ratingsData}
					openingHoursData={salonData?.openingHoursData}
					ratingId='header-desktop-rating'
				/>
			</DesktopOnly>

			{/* images */}
			<DesktopOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<GalleryDesktop assetsPath={assetsPath} images={salonData?.images || []} />
			</DesktopOnly>
			<MobileOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<GalleryMobile assetsPath={assetsPath} images={salonData?.images || []} />
			</MobileOnly>

			{/* mobile content */}
			<MobileOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<ProfiSalonHeader
					name={salonData?.name}
					address={salonData?.formattedAddress}
					logoUrl={salonData?.logo?.resizedImages?.small}
					ratingsData={salonData?.ratingsData}
					ratingId='header-mobile-rating'
				/>
				<CtaButtons salonData={salonData} countriesData={countriesData} openReservationModal={openReservationModal} googleMapLink={googleMapLink} />
				<SC.TabsContainer>
					<Tabs activeKey={tabKey} onChange={(activeKey) => setTabKey(activeKey)} items={getTabsItems()} animated={false} />
				</SC.TabsContainer>
			</MobileOnly>

			{/* desktop content */}
			<DesktopOnly $breakpoint={SALON_PAGE_MOBILE_BREAKPOINT_KEY}>
				<SC.Columns>
					<SC.MainColumn>
						{/* about us text */}
						{salonData?.aboutUsFirst && <SC.AboutUsText>{salonData?.aboutUsFirst}</SC.AboutUsText>}
						{/* cta buttons */}
						<CtaButtons
							salonData={salonData}
							countriesData={countriesData}
							openReservationModal={openReservationModal}
							googleMapLink={googleMapLink}
						/>
						{/* services */}
						{renderServices && (
							<>
								<SC.ServicesHeading>{messages?.Services}</SC.ServicesHeading>
								<SalonServicesTabs services={salonServicesData} />
							</>
						)}
						{/* about us section */}
						<SalonAboutUsDesktop assetsPath={assetsPath} salonData={salonData} />
						{/* pricelists */}
						{renderPricelists && <SalonPricelists pricelists={salonData?.pricelists} />}
						{/* ratings */}
						{renderReviews && <SalonPageReviews salonData={salonData} reviewsData={salonReviewsData} />}
					</SC.MainColumn>
					<SC.SideColumn>
						<SalonContacts salonData={salonData} countriesData={countriesData} googleMapsUrl={googleMapsUrl} />
					</SC.SideColumn>
				</SC.Columns>
				<SalonDetailCard
					salonData={salonData}
					assetsPath={assetsPath}
					googleMapsApiKey={googleMapsApiKey}
					countries={countriesData || []}
					salonServices={salonServices}
				/>
			</DesktopOnly>
		</div>
	)
}

export default ProfiSalon
